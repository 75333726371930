import { FC } from 'react';
import { LeaderboardMember } from './LeaderboardService';

interface Props {
  member: LeaderboardMember;
}

const LeaderboardMemberListItem: FC<Props> = ({member}) => {
  return (
    <li className='list-group-item list-item'>
      <div className='d-flex w-100'>
        <span className='incident-summary flex-fill'>{member.name}</span>
        {member.count >= 0 &&
        <span className='incident-location'>{member.count}</span>
        }
        {member.count === -1 &&
        <span className='incident-location'>Invite Sent</span>
        }
      </div>
    </li>
  );
};

export default LeaderboardMemberListItem;
