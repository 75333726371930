import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { isSuccess } from "../common/types";
import { Certification, CertificationService, CertificationsResponse, CMEProgram, CMEProgressReport, NewCMERequest, UploadCertificationRequest } from "./CertificationService";
import { fetchDashboardAsync } from "../dashboard/dashboardSlice";

export interface CertificationState {
  certifications: Certification[];
  cmePrograms: CMEProgram[];
  progressReports: CMEProgressReport[];
  count: number;
}

export const fetchCertificationsAsync = createAsyncThunk(
  'certifications/fetch',
  async(page: number, { dispatch }) => {
    let offset = 0;
    if (page > 1) {
      offset = 25 * (page - 1);
    }

    const service = new CertificationService();
    const res = await service.fetch(offset);

    if (isSuccess(res)) {
      dispatch(loadCertifications(res));
    }

    return res;
  }
);

export const uploadCertificationAsync = createAsyncThunk(
  'certifications/upload',
  async(req: UploadCertificationRequest, { dispatch }) => {
    const service = new CertificationService();
    const res = await service.upload(req);

    return res;
  }
);

export const deleteCertificationAsync = createAsyncThunk(
  'certifications/delete',
  async(certificationId: number,  { dispatch }) => {
    const service = new CertificationService();
    const res = await service.delete(certificationId);

    return res;
  }
);

export const fetchCMEProgramsAsync = createAsyncThunk(
  'cme/programs',
  async (_, { dispatch }) => {
    const service = new CertificationService();
    const res = await service.getCMEPrograms();

    if (isSuccess(res)) {
      dispatch(loadCMEPrograms(res));
    }

    return res;
  }
);

export const fetchCMEProgressReports = createAsyncThunk(
  'cme/progress',
  async (_, { dispatch }) => {
    const service = new CertificationService();
    const res = await service.getCMEProgressReports();

    if (isSuccess(res)) {
      dispatch(loadProgressReports(res));
    }

    return res;
  }
);

export const addCMEAsync = createAsyncThunk(
  'cme/add',
  async (req: NewCMERequest, { dispatch }) => {
    const service = new CertificationService();
    const res = await service.addCME(req);

    return res;
  }
);

export const deleteCMEAsync = createAsyncThunk(
  'cme/delete',
  async (id: number, { dispatch }) => {
    const service = new CertificationService();
    const res = await service.deleteCMECredit(id);

    return res;
  }
);

export const addCMEDashboardCardAsync = createAsyncThunk(
  'cme/dashboard',
  async (cme_participant_id: number, { dispatch }) => {
    const service = new CertificationService();
    const res = await service.addCMEDashboardCard(cme_participant_id);

    if (isSuccess(res)) {
      await dispatch(fetchDashboardAsync());
    }

    return res;
  }
)

let blankState: CertificationState = {
  certifications: [],
  cmePrograms: [],
  progressReports: [],
  count: 0
};

export const certificationSlice = createSlice({
  name: 'certifications',
  initialState: blankState,
  reducers: {
    loadCertifications: (state, action: PayloadAction<CertificationsResponse>) => {
      state = {
        ...state,
        certifications: action.payload.certifications,
        count: action.payload.count
      };

      return state;
    },
    loadCMEPrograms: (state, action: PayloadAction<CMEProgram[]>) => {
      state = {
        ...state,
        cmePrograms: action.payload
      };

      return state;
    },
    loadProgressReports: (state, action: PayloadAction<CMEProgressReport[]>) => {
      state = {
        ...state,
        progressReports: action.payload
      };

      return state;
    }
  }
});

export const { loadCertifications, loadCMEPrograms, loadProgressReports } = certificationSlice.actions;

export const selectCertifications = (state: RootState) => {
  return state.certification.certifications;
}

export const selectCertificationCount = (state: RootState) => {
  return state.certification.count;
};

export const selectCMEPrograms = (state: RootState) => {
  return state.certification.cmePrograms;
};

export const selectCMEProgressReports = (state: RootState) => {
  return state.certification.progressReports;
};

export default certificationSlice.reducer;
