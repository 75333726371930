import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import { isSuccess } from '../common/types';
import { CreateIncidentCustomFieldRequest, CreateIncidentRequest, DeleteIncidentPhotoRequest, Incident, IncidentCustomField, IncidentPhoto, IncidentService, IncidentsResponse, UploadIncidentPhotoRequest } from './IncidentService';

export interface IncidentState {
  customFields: IncidentCustomField[];
  incidents: Incident[];
  incidentCount: number;
  incidentPhotos: IncidentPhoto[];
};

export interface EditIncidentArgs {
  id: number;
  req: CreateIncidentRequest;
}

export const fetchIncidentCustomFieldsAsync = createAsyncThunk(
  'incidents/custom/fetch',
  async (_, { dispatch }) => {
    const service = new IncidentService();
    const res = await service.fetchIncidentCustomFields();

    if (isSuccess(res)) {
      dispatch(persistIncidentCustomFields(res));
    }

    return res;
  }
);

export const fetchIncidentsAsync = createAsyncThunk(
  'incidents/fetch',
  async (page: number, { dispatch }) => {
    let offset = 0;
    if (page > 1) {
      offset = 25 * (page - 1);
    }

    const service = new IncidentService();
    const res = await service.fetchIncidents(offset);

    if (isSuccess(res)) {
      dispatch(persistIncidents(res));
    }

    return res;
  }
);

export const fetchIncidentPhotosAsync = createAsyncThunk(
  'incidents/photos/fetch',
  async (incidentId: number, { dispatch }) => {
    const service = new IncidentService();
    const res = await service.fetchIncidentPhotos(incidentId);

    if (isSuccess(res)) {
      dispatch(updatePhotos(res));
    }
    
    return res;
  }
);

export const createIncidentCustomFieldAsync = createAsyncThunk(
  'incidents/custom/create',
  async (req: CreateIncidentCustomFieldRequest, { dispatch }) => {
    const service = new IncidentService();
    const res = await service.createCustomField(req);

    if (isSuccess(res)) {
      dispatch(fetchIncidentCustomFieldsAsync());
    }

    return res;
  }
);

export const createIncidentAsync = createAsyncThunk(
  'incidents/create',
  async (req: CreateIncidentRequest, { dispatch }) => {
    const service = new IncidentService();
    const res = await service.createIncident(req);

    return res;
  }
);

export const editIncidentAsync = createAsyncThunk(
  'incidents/edit',
  async (args: EditIncidentArgs, { dispatch }) => {
    const service = new IncidentService();
    const res = await service.editIncident(args.id, args.req);

    return res;
  }
);

export const deleteIncidentCustomFieldAsync = createAsyncThunk(
  'incidents/custom/delete',
  async (fieldId: number, { dispatch }) => {
    const service = new IncidentService();
    const res = await service.deleteIncidentCustomField(fieldId);

    if (isSuccess(res)) {
      dispatch(fetchIncidentCustomFieldsAsync());
    }

    return res;
  }
);

export const deleteIncidentAsync = createAsyncThunk(
  'incidents/delete',
  async (incidentId: number, { dispatch }) => {
    const service = new IncidentService();
    const res = await service.deleteIncident(incidentId);

    return res;
  }
);

export const uploadIncidentPhotoAsync = createAsyncThunk(
  'incidents/photos/add',
  async (req: UploadIncidentPhotoRequest, { dispatch }) => {
    const service = new IncidentService();
    const res = await service.uploadPhoto(req);

    if (isSuccess(res)) {
      dispatch(fetchIncidentPhotosAsync(req.incident_id));
    }

    return res;
  }
);

export const deleteIncidentPhotoAsync = createAsyncThunk(
  'incidents/photos/delete',
  async (req: DeleteIncidentPhotoRequest, { dispatch }) => {
    const service = new IncidentService();
    const res = await service.deleteIncidentPhoto(req);

    if (isSuccess(res)) {
      await dispatch(fetchIncidentPhotosAsync(req.incident_id));
    }

    return res;
  }
);

let defaultState: IncidentState = {
  customFields: [],
  incidents: [],
  incidentCount: 0,
  incidentPhotos: []
};

export const incidentSlice = createSlice({
  name: 'incidents',
  initialState: defaultState,
  reducers: {
    loadIncidentState: (state) => {
      const json = localStorage.getItem('incidents');
      if (json != null) {
        state = JSON.parse(json);
        return state;
      } else {}
    },
    persistIncidentCustomFields: (state, action: PayloadAction<IncidentCustomField[]>) => {
      state = {
        ...state,
        customFields: action.payload
      };

      localStorage.setItem('incidents', JSON.stringify(state));
      return state;
    },
    persistIncidents: (state, action: PayloadAction<IncidentsResponse>) => {
      state = {
        ...state,
        incidents: action.payload.incidents,
        incidentCount: action.payload.count
      };

      localStorage.setItem('incidents', JSON.stringify(state));
      return state;
    },
    updatePhotos: (state, action: PayloadAction<IncidentPhoto[]>) => {
      state = {
        ...state,
        incidentPhotos: action.payload
      };
      
      return state;
    }
  }
});

export const { loadIncidentState, persistIncidentCustomFields, persistIncidents, updatePhotos } = incidentSlice.actions;

export const selectIncidentCustomFields = (state: RootState) => {
  return state.incident.customFields;
};

export const selectIncidents = (state: RootState) => {
  return state.incident.incidents;
}

export const selectIncidentCount = (state: RootState) => {
  return state.incident.incidentCount;
};

export const selectIncidentPhotos = (state: RootState) => {
  return state.incident.incidentPhotos;
}

export default incidentSlice.reducer;
