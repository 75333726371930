import { FC } from 'react';
import { CMECategory, CMEParticipantCredit } from '../certifications/CertificationService';

interface Props {
  cme: CMEParticipantCredit;
  categories: CMECategory[];
  onClickCME: (cme: CMEParticipantCredit) => void;
}

const CMEListItem: FC<Props> = ({cme, categories, onClickCME}) => {
  return (
    <li id={'cme-'+cme.id} className='list-group-item list-item click-pointer' onClick={(e) => { onClickCME(cme); }}>
      <div className='d-flex w-100'>
        <span className='certification-name flex-fill'>{cme.title}</span>
        <span className='certification-issued'>{cme.num_minutes / 60} h</span>
      </div>
      <div>
        <span className='certification-issued'>{categories.find((category) => category.id === cme.cme_category_id)?.title}</span>
      </div>
    </li>
  );
};

export default CMEListItem;
