import React, { FC, forwardRef, RefObject, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import moment from 'moment';

import { selectAgencies } from '../accounts/agencySlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { clearButton, clearError, showError } from '../common/alerts';
import { createIncidentAsync, fetchIncidentsAsync, selectIncidentCustomFields } from './incidentsSlice';
import { CreateIncidentRequest, CustomFieldInput, IncidentCustomField } from './IncidentService';
import { isSuccess } from '../common/types';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  currentPage: number;
}

const AddIncidentModal: FC<Props> = ({modalRef, currentPage}: Props) => {
  const dispatch = useAppDispatch();
  const alertRef = useRef<HTMLDivElement>(null);

  const addButtonRef = useRef<HTMLButtonElement>(null);

  const agencies = useAppSelector(selectAgencies);
  const customFields = useAppSelector(selectIncidentCustomFields).filter((field) => field.is_hidden === false);

  let defaultAgency = '';
  if (agencies.length === 1) {
    defaultAgency = agencies[0].id.toString();
  }

  const [selectedAgency, setSelectedAgency] = useState<string>(defaultAgency);
  const handleAgency = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedAgency(event.target.value);

  const [dispatched, setDispatched] = useState<string>(moment().format('YYYY-MM-DDTHH:mm'));
  const handleDispatched = (date: Date | null) => setDispatched(date != null ? moment(date).format('YYYY-MM-DDTHH:mm') : '');

  const [runNumber, setRunNumber] = useState<string>();
  const handleRunNumber = (event: React.ChangeEvent<HTMLInputElement>) => setRunNumber(event.target.value);

  const [location, setLocation] = useState<string>();
  const handleLocation = (event: React.ChangeEvent<HTMLInputElement>) => setLocation(event.target.value);

  const [summary, setSummary] = useState<string>();
  const handleSummary = (event: React.ChangeEvent<HTMLInputElement>) => setSummary(event.target.value);

  const [mileage, setMileage] = useState<string>();
  const handleMileage = (event: React.ChangeEvent<HTMLInputElement>) => setMileage(event.target.value);

  const [role, setRole] = useState<string>();
  const handleRole = (event: React.ChangeEvent<HTMLInputElement>) => setRole(event.target.value);

  const [comments, setComments] = useState<string>();
  const handleComments = (event: React.ChangeEvent<HTMLTextAreaElement>) => setComments(event.target.value);

  const [customFieldValues, setCustomFieldValues] = useState<CustomFieldInput[]>([]);
  const handleCustomField = (field: IncidentCustomField, event: React.ChangeEvent<HTMLInputElement>) => {
    let id = event.target.id.split('-')[1];
    let radioValue: string | undefined = undefined;

    if (event.target.type === 'radio') {
      radioValue = event.target.id.split('-')[2];
    }

    const item = customFieldValues.find((field) => {
      return field.id === parseInt(id);
    });

    if (item != null) {
      setCustomFieldValues(customFieldValues.map((field) => {
        if (field.id !== parseInt(id)) {
          return field;
        } else {
          let value = event.target.value;
          if (event.target.type === 'radio') {
            value = radioValue === 'yes' ? 'true' : 'false';
          }

          const updatedField: CustomFieldInput = {
            id: parseInt(id),
            value: value.trim()
          };
          return updatedField;
        }
      }));
    } else {
      let value = event.target.value;
      if (event.target.type === 'radio') {
        value = radioValue === 'yes' ? 'true' : 'false';
      }

      setCustomFieldValues([...customFieldValues, {
        id: parseInt(id),
        value: value.trim()
      }]);
    }
  };

  const handleCustomFieldDate = (field: IncidentCustomField, date: Date | null) => {
    const item = customFieldValues.find((f) => {
      return f.id === field.id;
    });

    if (item != null && date == null) {
      customFieldValues.splice(customFieldValues.indexOf(item), 1);
      setCustomFieldValues([...customFieldValues]);
      return;
    }

    if (item != null) {
      setCustomFieldValues(customFieldValues.map((f) => {
        if (f.id !== field.id) {
          return f;
        } else {
          const updatedField: CustomFieldInput = {
            id: field.id,
            value: moment(date).format('YYYY-MM-DDTHH:mm')
          };

          return updatedField;
        }
      }));
    } else {
      setCustomFieldValues([...customFieldValues, {
        id: field.id,
        value: moment(date).format('YYYY-MM-DDTHH:mm')
      }]);
    }
  };

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (addButtonRef.current != null) {
      addButtonRef.current.disabled = true;
      addButtonRef.current.innerText = 'Adding...';
    }

    if (selectedAgency == null || selectedAgency === '') {
      showError(alertRef, 'Department is required');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (dispatched == null || dispatched === '') {
      showError(alertRef, 'Dispatched time is required');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (mileage != null && isNaN(parseFloat(mileage))) {
      showError(alertRef, 'Mileage must be a numerical value');
      clearButton(addButtonRef, 'Add');
      return;
    }

    const req: CreateIncidentRequest = {
      agency_id: parseInt(selectedAgency),
      dispatch_time: dispatched,
      run_number: runNumber != null ? runNumber.trim() : '',
      summary: summary != null ? summary.trim().split(' ').map((w) => w[0].toUpperCase() + w.substring(1)).join(' ') : '',
      location: location != null ? location.trim() : '',
      comments: comments != null ? comments.trim() : '',
      role: role != null ? role.trim().split(' ').map((w) => w[0].toUpperCase() + w.substring(1)).join(' ') : '',
      mileage: mileage != null ? parseFloat(mileage) : 0,
      custom_fields: customFieldValues
    };

    const res = await dispatch(createIncidentAsync(req)).unwrap();
    if (isSuccess(res)) {
      await dispatch(fetchIncidentsAsync(currentPage));
      window.history.back();
    } else {
      showError(alertRef, res.message);
      clearButton(addButtonRef, 'Add');
    }
  };

  useEffect(() => {
    if (modalRef.current == null) {
      return;
    }

    modalRef.current.addEventListener('hidden.bs.modal', (event) => {
      const agencyElement = document.getElementById('agency') as HTMLSelectElement;
      if (agencyElement != null) {
        agencyElement.value = '';
      }

      const elements = ['run-number', 'location', 'summary', 'role', 'comments', 'mileage'];
      elements.forEach((id) => {
        const element = document.getElementById(id) as HTMLInputElement;
        element.value = '';
      });

      customFields.forEach((field) => {
        const elements = document.getElementsByName('custom-'+field.id) as NodeListOf<HTMLInputElement>;
        elements.forEach((element) => {
          if (element.type === 'radio') {
            element.checked = false;
          }

          element.value = '';
        });
      });

      setRunNumber(undefined);
      setSummary(undefined);
      setLocation(undefined);
      setComments(undefined);
      setRole(undefined);
      setCustomFieldValues([]);
      setMileage(undefined);
      
      clearError(alertRef);
      clearButton(addButtonRef, 'Add');
    });
  }, [modalRef, customFields]);

  const onClose = () => {
    window.history.back();
  };

  const DateButton = forwardRef(({value, onClick}: any, ref) => (
    <button type='button' className='form-control mt-0 text-start' onClick={onClick}>{value}</button>
  ));

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-lg modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Incident</h5>
              <button id='close-btn' type='button' className='btn-close' onClick={(e) => { onClose(); }} aria-label='Close'></button>
            </div>
            <div className='modal-body modal-mobile-scroll'>
              <div>
                <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
                <div>
                  <div className='row'>
                    <div className='col-lg'>
                      <div>
                        <label className='form-label mb-0' htmlFor='name'>Department</label>
                        <select className='form-select' id='agency' name='agency' value={selectedAgency} onChange={(e) => { handleAgency(e); }}>
                          <option key={-1} value=''>Select department</option>
                          {agencies.map((agency) =>
                          <option key={agency.id} value={agency.id}>{agency.name}</option>
                          )}
                        </select>
                      </div>
                      <div className='mt-2'>
                        <label className='form-label mb-0' htmlFor='dispatched'>Dispatched</label>
                        <DatePicker selected={new Date(dispatched)} dateFormat='MM/dd/yyyy HH:mm' className='form-control mt-0' id='dispatched' customInput={<DateButton />} showPopperArrow={false} showTimeSelect timeFormat="HH:mm" timeIntervals={1} onChange={(date) => { handleDispatched(date); }} />
                      </div>
                      <div className='mt-2'>
                        <label className='form-label mb-0' htmlFor='run-number'>Run Number</label>
                        <input type='text' className='form-control mt-0' id='run-number' onChange={(e) => { handleRunNumber(e); }} />
                      </div>
                      <div className='mt-2'>
                        <label className='form-label mb-0' htmlFor='location'>Location</label>
                        <input type='text' className='form-control mt-0' id='location' onChange={(e) => { handleLocation(e); }} />
                      </div>
                      <div className='mt-2'>
                        <label className='form-label mb-0' htmlFor='summary'>Incident</label>
                        <input type='text' className='form-control mt-0' id='summary' onChange={(e) => { handleSummary(e); }} placeholder='Fire Alarm, Structure Fire, EMS, MVA' />
                      </div>
                      <div className='mt-2'>
                        <label className='form-label mb-0' htmlFor='mileage'>Mileage</label>
                        <input type='text' className='form-control mt-0' id='mileage' onChange={(e) => { handleMileage(e); }} />
                      </div>
                    </div>
                    <div className='col-lg'>
                      <div>
                        <label className='form-label mb-0' htmlFor='role'>Role</label>
                        <input type='text' className='form-control mt-0' id='role' onChange={(e) => { handleRole(e); }} />
                      </div>
                      {customFields.map((field) =>
                      <div key={field.id} className='mt-2'>
                        <div>
                          <label className='form-label mb-0' htmlFor={field.name}>{field.name}</label>
                        </div>
                        {field.input_type === 'text' &&
                        <input type='text' className='form-control mt-0' name={'custom-'+field.id} id={'custom-'+field.id} onChange={(e) => { handleCustomField(field, e); }} />
                        }
                        {field.input_type === 'check' &&
                        <div className='btn-group' role='group'>
                          <input type='radio' className='btn-check' name={'custom-'+field.id} id={'custom-'+field.id+'-yes'} onChange={(e) => { handleCustomField(field, e); }} />
                          <label className='btn btn-outline-primary' htmlFor={'custom-'+field.id+'-yes'}>Yes</label>

                          <input type='radio' className='btn-check' name={'custom-'+field.id} id={'custom-'+field.id+'-no'} onChange={(e) => { handleCustomField(field, e); }} />
                          <label className='btn btn-outline-primary' htmlFor={'custom-'+field.id+'-no'}>No</label>
                        </div>
                        }
                        {field.input_type === 'datetime' &&
                        <DatePicker selected={customFieldValues.find((f) => field.id === f.id) != null && customFieldValues.find((f) => field.id === f.id)?.value !== '' ? new Date(moment(customFieldValues.find((f) => field.id === f.id)?.value).format('YYYY-MM-DDTHH:mm')) : null} isClearable dateFormat='MM/dd/yyyy HH:mm' className='form-control mt-0' id={'custom-'+field.id} customInput={<DateButton />} showPopperArrow={false} showTimeSelect timeFormat="HH:mm" timeIntervals={1} onChange={(date) => { handleCustomFieldDate(field, date); }} />
                        }
                      </div>
                      )}
                      <div className='mt-2'>
                        <label className='form-label mb-0' htmlFor='comments'>Comments</label>
                        <textarea className='form-control mt-0' rows={4} id='comments' onChange={(e) => { handleComments(e); }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button ref={addButtonRef} id='add-incident-btn' type='submit' className='btn btn-primary w-25'>Add</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddIncidentModal;
