import { FC } from 'react';
import moment from 'moment';

import { TrainingSession } from './TrainingService';

interface Props {
  session: TrainingSession | undefined;
}

const TrainingSessionDetailView: FC<Props> = ({session}: Props) => {
  return (
    <div>
      <div>
        <label className='form-label mb-0'>Training Type</label>
        <p className=''>{session?.training_type}</p>
      </div>
      <div className='mt-2'>
        <label className='form-label mb-0'>Occurred On</label>
        <p className=''>{moment(session?.occurred_at).format('MMMM D, YYYY HH:mm')}</p>
      </div>
      <div className='mt-2'>
        <label className='form-label mb-0'>Duration (hours)</label>
        <p className=''>{session?.num_hours}</p>
      </div>
      <div className='mt-2'>
        <label className='form-label mb-0'>Comments</label>
        <p className=''>{session?.comments}</p>
      </div>
    </div>
  );
};

export default TrainingSessionDetailView;
