import { Modal } from 'bootstrap';
import { FC, RefObject, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { clearButton, clearError, showError } from '../common/alerts';
import { isSuccess } from '../common/types';
import { ContactSupportRequest } from './AccountService';
import { contactSupportAsync, selectUser } from './accountSlice';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
}

const ContactSupportModal: FC<Props> = ({modalRef}: Props) => {
  const dispatch = useAppDispatch();

  const alertRef = useRef<HTMLDivElement>(null);
  const sendButtonRef = useRef<HTMLButtonElement>(null);

  const user = useAppSelector(selectUser);

  const [subject, setSubject] = useState<string>();
  const handleSubject = (event: React.ChangeEvent<HTMLInputElement>) => setSubject(event.target.value);

  const [message, setMessage] = useState<string>();
  const handleMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (sendButtonRef.current != null) {
      sendButtonRef.current.disabled = true;
      sendButtonRef.current.innerText = 'Sending...';
    }

    if (subject == null || subject === '') {
      showError(alertRef, 'Subject is a required field');
      clearButton(sendButtonRef, 'Send');

      return;
    }

    if (message == null || message === '') {
      showError(alertRef, 'Message is a required field');
      clearButton(sendButtonRef, 'Send');

      return;
    }

    if (user == null) {
      showError(alertRef, 'Must be signed in to contact support via this form. You can also contact support at help@rescuehq.net');
      clearButton(sendButtonRef, 'Send');
      return;
    }

    const req: ContactSupportRequest = {
      subject: subject,
      message: message,
      user: user
    };

    const res = await dispatch(contactSupportAsync(req)).unwrap();
    if (isSuccess(res)) {
      clearButton(sendButtonRef, 'Send');

      setSubject(undefined);
      setMessage(undefined);

      const subjectElement = document.getElementById('subject') as HTMLInputElement;
      if (subjectElement != null) {
        subjectElement.value = '';
      }

      const messageElement = document.getElementById('message') as HTMLTextAreaElement;
      if (messageElement != null) {
        messageElement.value = '';
      }

      const btn = document.getElementById('close-btn');
      if (btn != null) {
        btn.click();
      }
    } else {
      showError(alertRef, res.message);
      clearButton(sendButtonRef, 'Send');
    }
  };

  const onClose = () => {
    if (modalRef.current != null) {
      const modal = Modal.getOrCreateInstance(modalRef.current);
      modal.hide();
      modal.dispose();
    }
  };

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-3} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-lg modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Contact Support</h5>
              <button id='close-btn' type='button' className='btn-close' onClick={(e) => { onClose(); e.preventDefault(); }} aria-label='Close'></button>
            </div>
            <div className='modal-body modal-mobile-scroll'>
              <div>
                <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
                <div>
                  <div>
                    <label className='form-label mb-0' htmlFor='subject'>Subject</label>
                    <input type='text' className='form-control mt-0' id='subject' name='subject' onChange={(e) => { handleSubject(e); }} />
                  </div>
                  <div>
                    <label className='form-label mb-0' htmlFor='message'>Message</label>
                    <textarea className='form-control mt-0' id='message' name='message' rows={8} onChange={(e) => { handleMessage(e); }} />
                  </div>
                  <div>
                    <span className='small-text text-muted'>You'll receive a confirmation email that your support request was received. Please allow up to 24 hours for a response.</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button ref={sendButtonRef} id='contact-support-btn' type='submit' className='btn btn-primary w-25'>Send</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ContactSupportModal;
