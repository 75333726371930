import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { logoutAsync } from './accountSlice';

const LogoutPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function clearUser() {
      await dispatch(logoutAsync());
      navigate('/', { replace: true });
    };
    
    clearUser();
  }, [dispatch, navigate]);

  return (
    <></>
  );
};

export default LogoutPage;
