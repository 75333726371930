import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

import { RootState } from '../app/store';
import { isSuccess } from '../common/types';
import { SubscriptionService } from '../subscriptions/SubscriptionService';
import { AccountService, AuthenticationRequest, AccountRequest, User, ContactSupportRequest, PasswordResetRequest } from './AccountService';

export interface AccountState {
  user?: User;
}

export const authenticateAsync = createAsyncThunk(
  'users/authenticate',
  async (req: AuthenticationRequest, { dispatch }) => {
    const service = new AccountService();
    const res = await service.authenticate(req);

    if (isSuccess(res)) {
      const subService = new SubscriptionService();
      await subService.getUserSubscription();

      dispatch(persistUser(res));
    }

    return res;
  }
);

export const createPasswordResetAsync = createAsyncThunk(
  'users/password/reset',
  async (email: string, { dispatch }) => {
    const service = new AccountService();
    const res = await service.createPasswordReset(email);

    return res;
  }
);

export const updatePasswordAsync = createAsyncThunk(
  'users/password/new',
  async (req: PasswordResetRequest, { dispatch }) => {
    const service = new AccountService();
    const res = await service.updatePassword(req);

    return res;
  }
);

export const setupDemoAccountAsync = createAsyncThunk(
  'users/demo',
  async (_, { dispatch }) => {
    const service = new AccountService();
    const res = await service.setupDemoAccount();

    if (isSuccess(res)) {
      const subService = new SubscriptionService();
      await subService.getUserSubscription();

      dispatch(persistUser(res));
    }

    return res;
  }
)

export const createAccountAsync = createAsyncThunk(
  'users/create',
  async (req: AccountRequest, { dispatch }) => {
    const service = new AccountService();
    const res = await service.create(req);

    if (isSuccess(res)) {
      dispatch(persistUser(res));
    }

    return res;
  }
);

export const logoutAsync = createAsyncThunk(
  'users/logout',
  async (_, { dispatch }) => {
    const cookies = new Cookies();
    cookies.remove('user_token', { path: '/' });
    cookies.remove('user_subscription', { path: '/' });

    localStorage.removeItem('account_state');
    dispatch(logout);
  }
);

export const contactSupportAsync = createAsyncThunk(
  'users/contactSupport',
  async (req: ContactSupportRequest, { dispatch }) => {
    const service = new AccountService();
    const res = await service.contactSupport(req);

    return res;
  }
);

let blankState: AccountState = {
};

export const accountSlice = createSlice({
  name: 'account',
  initialState: blankState,
  reducers: {
    loadUser: (state) => {
      const json = localStorage.getItem('account_state');
      if (json != null) {
        state = JSON.parse(json);
        return state;
      }
    },
    persistUser: (state, action: PayloadAction<User>) => {
      state = {
        ...state,
        user: action.payload
      };

      localStorage.setItem('account_state', JSON.stringify(state));
      return state;
    },
    logout: (state) => {
      state = {
      };
      return state;
    }
  }
});

export const { loadUser, logout, persistUser } = accountSlice.actions;

export const selectUser = (state: RootState) => {
  return state.account.user;
};

export default accountSlice.reducer;
