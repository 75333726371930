import { FC, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CookiesProvider, useCookies } from 'react-cookie';
import moment from 'moment';
import ReactGA from 'react-ga4';

import LandingPage from '../landing/LandingPage';
import CreateAccountPage from '../accounts/CreateAccountPage';
import NewSubscriptionPage from '../subscriptions/NewSubscriptionPage';
import LoginPage from '../accounts/LoginPage';
import LogoutPage from '../accounts/LogoutPage';
import DashboardPage from '../dashboard/DashboardPage';
import { Subscription } from '../subscriptions/SubscriptionService';
import { useAppDispatch } from './hooks';
import { loadUser } from '../accounts/accountSlice';
import IncidentsPage from '../incidents/IncidentsPage';
import TrainingPage from '../training/TrainingPage';
import CertificationsPage from '../certifications/CertificationsPage';
import SettingsPage from '../settings/SettingsPage';
import { refreshSubscriptionAsync, subscriptionPlansAsync } from '../subscriptions/subscriptionSlice';
import { loadAgencies } from '../accounts/agencySlice';
import { fetchIncidentCustomFieldsAsync, loadIncidentState } from '../incidents/incidentsSlice';
import CreatePasswordResetPage from '../accounts/CreatePasswordResetPage';
import NewPasswordPage from '../accounts/NewPasswordPage';
import LeaderboardInviteAcceptPage from '../leaderboard/LeaderboardInviteAcceptPage';
import { fetchCMEProgressReports } from '../certifications/certificationSlice';
import CMEPage from '../landing/CMEPage';

const stripePromise = loadStripe(process.env["REACT_APP_STRIPE_KEY"]!);

ReactGA.initialize('G-M3YZGVDWTX');

const AuthenticatedRoute: FC = () => {
  const location = useLocation();
  const [cookie] = useCookies(['user_token']);

  const url = '/login?redirect='+location.pathname;
  return cookie.user_token != null ? <Outlet /> : <Navigate to={url} state={{ from: location }} />;
};

/**
 * The homepage route determines where to direct the user based on the authentication state.
 */
const HomePageRoute: FC = () => {
  const location = useLocation();

  const [cookies] = useCookies(['user_token', 'user_subscription']);

  if (cookies.user_token != null) {
    if (cookies.user_subscription != null) {
      const sub = cookies.user_subscription as Subscription;

      if (moment().isAfter(moment(sub.expiration_timestamp))) {
        return <Navigate to='/subscriptions/new' state={{ from: location }} />;
      }

      return <Outlet />;
    } else {
      return <Navigate to='/subscriptions/new' state={{ from: location }} />;
    }
  }

  return <LandingPage />;
};

const App: FC = () => {
  const dispatch = useAppDispatch();

  dispatch(loadUser());
  dispatch(loadAgencies());
  dispatch(loadIncidentState());
  dispatch(fetchIncidentCustomFieldsAsync());
  dispatch(subscriptionPlansAsync());
  dispatch(refreshSubscriptionAsync());
  dispatch(fetchCMEProgressReports());

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search
    });
  }, []);

  return (
    <CookiesProvider>
      <Elements stripe={stripePromise}>
        <Routes>
          <Route path='/accounts/create' element={<CreateAccountPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/password/reset' element={<CreatePasswordResetPage />} />
          <Route path='/password/new' element={<NewPasswordPage />} />
          <Route path='/leaderboards/invite' element={<AuthenticatedRoute />}>
            <Route path='/leaderboards/invite' element={<LeaderboardInviteAcceptPage />} />
          </Route>
          <Route path='/logout' element={<AuthenticatedRoute />}>
            <Route path='/logout' element={<LogoutPage />} />
          </Route>
          <Route path='/subscriptions/new' element={<AuthenticatedRoute />}>
            <Route path='/subscriptions/new' element={<NewSubscriptionPage />} />
          </Route>
          <Route path='/incidents' element={<AuthenticatedRoute />}>
            <Route path='/incidents' element={<IncidentsPage />} />
          </Route>
          <Route path='/training' element={<AuthenticatedRoute />}>
            <Route path='/training' element={<TrainingPage />} />
          </Route>
          <Route path='/certifications' element={<AuthenticatedRoute />}>
            <Route path='/certifications' element={<CertificationsPage />} />
          </Route>
          <Route path='/settings' element={<AuthenticatedRoute />}>
            <Route path='/settings' element={<SettingsPage />} />
          </Route>
          <Route path='/cme' element={<CMEPage />} />
          <Route path='/firstin' element={<HomePageRoute />} />
          <Route path='/' element={<HomePageRoute />}>
            <Route path='/' element={<DashboardPage />} />
          </Route>
        </Routes>
      </Elements>
    </CookiesProvider>
  );
};

export default App;
