import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

import { RootState } from "../app/store";
import { isSuccess } from "../common/types";
import { PromoCode, Subscription, SubscriptionPlan, SubscriptionRequest, SubscriptionService } from "./SubscriptionService";

export interface SubscriptionState {
  plans: SubscriptionPlan[];
  availablePlans: SubscriptionPlan[];
  activeSubscription?: Subscription;
  userPromoCode?: PromoCode;
}

export const subscriptionPlansAsync = createAsyncThunk(
  'subscriptions/plans',
  async (_, { dispatch }) => {
    const service = new SubscriptionService();

    let res = await service.getAvailableSubscriptionPlans();
    if (isSuccess(res)) {
      dispatch(loadAvailableSubscriptionPlans(res));
    }

    res = await service.getSubscriptionPlans();

    if (isSuccess(res)) {
      dispatch(loadSubscriptionPlans(res));
    }
  }
);

export const verifyPromoCodeAsync = createAsyncThunk(
  'subscriptions/verifyPromoCode',
  async (promoCode: string, { dispatch }) => {
    const service = new SubscriptionService();
    const res = await service.verifyPromoCode(promoCode);

    return res;
  }
);

export const getUserPromoCodeAsync = createAsyncThunk(
  'subscriptions/userPromoCode',
  async (_, { dispatch }) => {
    const service = new SubscriptionService();
    const res = await service.getUserPromoCode();

    return res;
  }
);

export const createSubscriptionAsync = createAsyncThunk(
  'subscriptions/create',
  async (req: SubscriptionRequest, { dispatch }) => {
    const service = new SubscriptionService();
    const res = await service.create(req);

    return res;
  }
);

export const cancelSubscriptionAsync = createAsyncThunk(
  'subscriptions/cancel',
  async (_, { dispatch }) => {
    const service = new SubscriptionService();
    const res = await service.cancelSubscription();

    if (isSuccess(res)) {
      await service.getUserSubscription();
      dispatch(loadActiveSubscription());
    }

    return res;
  }
);

export const resumeSubscriptionAsync = createAsyncThunk(
  'subscriptions/resume',
  async (_, { dispatch }) => {
    const service = new SubscriptionService();
    const res = await service.resumeSubscription();

    if (isSuccess(res)) {
      await service.getUserSubscription();
      dispatch(loadActiveSubscription());
    }

    return res;
  }
);

export const refreshSubscriptionAsync = createAsyncThunk(
  'subscriptions/refresh',
  async (_, { dispatch }) => {
    const service = new SubscriptionService();
    const res = await service.getUserSubscription();

    if (isSuccess(res)) {
      dispatch(loadActiveSubscription());
    }
  }
);

let blankState: SubscriptionState = {
  plans: [],
  availablePlans: []
};

export const subscriptionSlice = createSlice({
  'name': 'subscription',
  initialState: blankState,
  reducers: {
    loadSubscriptionPlans: (state, action: PayloadAction<SubscriptionPlan[]>) => {
      state = {
        ...state,
        plans: action.payload
      };
      return state;
    },
    loadActiveSubscription: (state) => {
      const cookies = new Cookies();
      const sub = cookies.get('user_subscription') as Subscription;

      state = {
        ...state,
        activeSubscription: sub
      };

      return state;
    },
    loadAvailableSubscriptionPlans: (state, action: PayloadAction<SubscriptionPlan[]>) => {
      state = {
        ...state,
        availablePlans: action.payload
      }

      return state;
    }
  }
});

export const { loadSubscriptionPlans, loadActiveSubscription, loadAvailableSubscriptionPlans } = subscriptionSlice.actions;

export const selectSubscriptionPlans = (state: RootState) => {
  return state.subscription.plans;
};

export const selectAvailableSubscriptionPlans = (state: RootState) => {
  return state.subscription.availablePlans;
}

export const selectActiveSubscription = (state: RootState) => {
  return state.subscription.activeSubscription;
}

export default subscriptionSlice.reducer;
