import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateLeaderboardRequest, LeaderboardInviteAcceptRequest, LeaderboardInviteRequest, LeaderboardService } from "./LeaderboardService";
import { isSuccess } from "../common/types";
import { fetchDashboardAsync } from "../dashboard/dashboardSlice";

export const createLeadboardAsync = createAsyncThunk(
  'leaderboard/create', 
  async (req: CreateLeaderboardRequest, { dispatch }) => {
    const service = new LeaderboardService();
    const res = await service.create(req);

    if (isSuccess(res)) {
      await dispatch(fetchDashboardAsync());
    }

    return res;
  }
);

export const leaderboardInviteAsync = createAsyncThunk(
  'leaderboard/invite',
  async (req: LeaderboardInviteRequest, { dispatch }) => {
    const service = new LeaderboardService();
    const res = await service.invite(req);

    if (isSuccess(res)) {
      await dispatch(fetchDashboardAsync());
    }

    return res;
  }
);

export const leaderboardInviteAcceptAsync = createAsyncThunk(
  'leaderboard/invite/accept',
  async (req: LeaderboardInviteAcceptRequest, { dispatch }) => {
    const service = new LeaderboardService();
    const res = await service.accept(req);

    if (isSuccess(res)) {
      await dispatch(fetchDashboardAsync());
    }

    return res;
  }
);
