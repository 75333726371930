import { FC } from 'react';
import { labelForDashboardStyle, labelForDashboardTimePeriod } from '../common/types';
import { DashboardTemplate } from './DashboardService';

interface Props {
  template: DashboardTemplate;
  onClickTemplate: (template: DashboardTemplate) => void;
}

const DashboardTemplateListItem: FC<Props> = ({template, onClickTemplate}: Props) => {
  return (
    <li id={'template-'+template.id} className='list-group-item list-item click-pointer' onClick={(e) => { onClickTemplate(template); }}>
      <div className='d-flex w-100'>
        <span className='template-name'>{template.name}</span>
        <span className='ms-auto badge text-bg-secondary p-2'>{labelForDashboardTimePeriod(template.time_period)}</span>
      </div>
      <div>
        <span className='template-style'>{labelForDashboardStyle(template.style)}</span>
      </div>
    </li>
  );
};

export default DashboardTemplateListItem;
