import axios, { AxiosError } from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';

import { ErrorResponse, Result } from '../common/types';

export interface AccountRequest {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  address: string;
  address_two?: string;
  city: string;
  state: string;
  postal_code: string;
}

export interface AuthenticationRequest {
  email: string;
  password: string;
}

export interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  address: string;
  address_two: string;
  city: string;
  state: string;
  postal_code: string;
  is_demo_account: boolean;
}

export interface ContactSupportRequest {
  subject: string;
  message: string;
  user: User;
}

export interface PasswordResetRequest {
  token: string;
  password: string;
}

export class AccountService {
  async create(body: AccountRequest): Promise<Result<User>> {
    try {
      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/accounts`, body);
      if (res.status === 201) {
        const token = res.headers['authorization'];
        
        const cookies = new Cookies();
        cookies.set('user_token', token, { path: '/', expires: moment().add(1, 'month').toDate(), sameSite: 'strict' });

        return res.data as User;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async authenticate(req: AuthenticationRequest): Promise<Result<User>> {
    try {
      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/accounts/authenticate`, req);
      if (res.status === 200) {
        const token = res.headers['authorization'];

        const cookies = new Cookies();
        cookies.set('user_token', token, { path: '/', expires: moment().add(1, 'month').toDate(), sameSite: 'strict' });

        return res.data as User;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async setupDemoAccount(): Promise<Result<User>> {
    try {
      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/accounts/demo_token`);
      if (res.status === 200) {
        const token = res.headers['authorization'];

        const cookies = new Cookies();
        cookies.set('user_token', token, { path: '/', expires: moment().add(1, 'day').toDate(), sameSite: 'strict' });

        localStorage.setItem('is_demo_account', 'true');
        return res.data as User;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async contactSupport(req: ContactSupportRequest): Promise<Result<boolean>> {
    try {
      const body: any = {
        request: {
          requester: {
            name: req.user.first_name + ' ' + req.user.last_name,
            email: req.user.email
          },
          subject: req.subject,
          comment: {
            body: req.message
          },
          custom_fields: [
            {
              id: 14427252000269,
              value: 'responderhq'
            },
            {
              id: 14427894440333,
              value: navigator.userAgent
            }
          ]
        }
      };

      const res = await axios.post(`https://rescuehq.zendesk.com/api/v2/requests`, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (res.status === 201) {
        return true;
      }

      return new Error('Unable to contact support. If the problem persists, email support at help@rescuehq.net');
    } catch (error) {
      return new Error('Unable to contact support. If the problem persists, email support at help@rescuehq.net');
    }
  }

  async createPasswordReset(email: string): Promise<Result<boolean>> {
    try {
      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/accounts/password_reset`, {
        email: email
      });

      if (res.status === 201) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async updatePassword(req: PasswordResetRequest): Promise<Result<boolean>> {
    try {
      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/accounts/password_reset/confirm`, req);
      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }
}
