import { Modal } from 'bootstrap';
import React, { useRef, FC, RefObject, useState } from 'react';
import { useAppDispatch } from '../app/hooks';
import { clearError, showError } from '../common/alerts';
import { isSuccess } from '../common/types';
import { CreateAgencyRequest } from './AgencyService';
import { createAgencyAsync } from './agencySlice';

interface AddAgencyModalProps {
  modalRef: RefObject<HTMLDivElement>;
  supportClose: boolean;
}

const AddAgencyModal: FC<AddAgencyModalProps> = ({modalRef, supportClose}: AddAgencyModalProps) => {
  const dispatch = useAppDispatch();
  const alertRef = useRef<HTMLDivElement>(null);

  const [name, setName] = useState<string>();
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);

  const [role, setRole] = useState<string>();
  const handleRole = (event: React.ChangeEvent<HTMLInputElement>) => setRole(event.target.value);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (name == null || name === '') {
      showError(alertRef, 'Department name is required');
      return;
    }

    if (role == null || role === '') {
      showError(alertRef, 'Your role is required');
      return;
    }

    const req: CreateAgencyRequest = {
      name: name.trim().split(' ').map((w) => w[0].toUpperCase() + w.substring(1)).join(' '),
      role: role.trim().split(' ').map((w) => w[0].toUpperCase() + w.substring(1)).join(' ')
    };

    const res = await dispatch(createAgencyAsync(req)).unwrap();
    if (isSuccess(res)) {
      if (modalRef.current != null) {
        const modal = Modal.getOrCreateInstance(modalRef.current);
        modal.hide();
      }
    } else {
      showError(alertRef, res.message);
    }
  };

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Your Department</h5>
              {supportClose &&
              <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
              }
            </div>
            <div className='modal-body'>
              <p>ResponderLog supports tracking information for multiple departments. If you volunteer and are also career, you can add both departments to ResponderLog.</p>
              <div>
                <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
                <div>
                  <label className='form-label mb-0' htmlFor='name'>Department Name</label>
                  <input type='text' className='form-control mt-0' id='name' onChange={(e) => { handleName(e); }} />
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='role'>Role</label>
                  <input type='text' className='form-control mt-0' id='role' placeholder='e.g. Firefighter' onChange={(e) => { handleRole(e); }} />
                </div>
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button type='submit' className='btn btn-primary'>Add Department</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};

export default AddAgencyModal;
