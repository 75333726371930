import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

interface Props {
  itemCount: number;
  currentPage: number;
  onPageRequested: (page: number) => void;
}

const Pagination: FC<Props> = ({itemCount, currentPage, onPageRequested}: Props) => {
  const numPages = Math.ceil(itemCount / 25);

  const pages: number[] = [];
  if (numPages <= 5) {
    for (let i = 1; i <= numPages; i++) {
      pages.push(i);
    }
  } else {
    let groupStart = Math.floor(currentPage / 5) * 5;
    if (groupStart === 0) {
      groupStart = 1;
    }

    for (let i = groupStart; i < groupStart + 5; i++) {
      if (i <= numPages) {
        pages.push(i);
      }
    }
  }

  return (
    <nav className='w-100 p-3'>
      <ul className='pagination justify-content-center my-auto'>
        <li className='page-item'>
          { currentPage === 1
          ? <span className='page-link disabled'><FontAwesomeIcon icon={faCaretLeft} size='xl' /></span>
          : <button className='page-link' onClick={(e) => { onPageRequested(currentPage - 1); }}><FontAwesomeIcon icon={faCaretLeft} size='xl' /></button>
          }
        </li>
        { pages.map((page) =>
        <li key={page} className={page === currentPage ? 'page-item-active' : 'page-item'}>
          { page === currentPage
          ? <span className='page-link active'>{page}</span>
          : <button className='page-link' onClick={(e) => { onPageRequested(page); }}>{page}</button>
          }
        </li>
        )}
        <li className='page-item'>
          { currentPage < numPages
          ? <button className='page-link' onClick={(e) => { onPageRequested(currentPage + 1); }}><FontAwesomeIcon icon={faCaretRight} size='xl' /></button>
          : <span className='page-link disabled'><FontAwesomeIcon icon={faCaretRight} size='xl' /></span>
          }
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
