import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { CMEProgressReport } from './CertificationService';
import { User } from '../accounts/AccountService';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import logo_inverse from '../svg/logo_inverse.svg';

export const cmeJSX = (report: CMEProgressReport, user: User) => {
  return (
    <>
      <div className='cover-sheet'>
        <div style={{backgroundColor: '#CE2029', height: '100', minHeight: '100', paddingTop: '24px', paddingBottom: '24px', paddingLeft: '16px'}}>
          <img className='navbar-logo' src={logo_inverse} alt='ResponderLog' />  <span style={{color: '#FFFFFF', marginLeft: '3px'}}>ResponderLog CME Summary</span>
        </div>
        <div className='p-3'>
          <div>
            <span>{user.first_name} {user.last_name}</span>
            <br />
            <span>{user.address}</span>
            <br />
            {user.address_two != null && user.address_two !== '' &&
            <div>
              <span>{user.address_two}</span>
              <br />
            </div>
            }
            <span>{user.city}, {user.state} {user.postal_code}</span>
          </div>
          <div className='mt-3'>
            <span>{report.program.certification_level.title}</span>
            <br />
            <span>{report.program.certification_level.state}</span>
          </div>
          <div className='mt-3'>
            <div className='d-flex w-100'>
              <h5 className='flex-fill'>CME Credits Completed</h5>
              <span className='certification-issued'>{report.completed_hours} hours</span>
            </div>
            {report.credits.map((credit) =>
            <div className='pt-3 break-avoid'>
              <div className='d-flex w-100'>
                <span className='certification-name flex-fill'>{credit.title}</span>
                <span className='certification-issued'>{credit.num_minutes / 60} hours</span>
              </div>
              <div>
                <span className=''>{report.categories.find((category) => category.id === credit.cme_category_id)?.title}</span>
                <br />
                <span className='certification-issued'>{credit.cme_provider}</span>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
      {report.credits.map((credit) =>
      <div className='full-page certificate-page'>
        {credit.preview_url != null &&
          <div>
            <img src={process.env['REACT_APP_API_HOST']+'/cme/document?url='+encodeURIComponent(credit.preview_url)} alt={credit.title} className='img-rotate' crossOrigin='use-credentials' />
          </div>
        }
      </div>
      )}
    </>
  );
};

export function buildPDFReport(report: CMEProgressReport, user: User) {
  const div = document.createElement('div');
  const root = createRoot(div);
  
  flushSync(() => {
    root.render(cmeJSX(report, user));
  });

  html2pdf().from(div).set({filename: `${user.last_name.toLowerCase()}_recert.pdf`, jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait', compress: true }, image: {type: 'jpeg', quality: 0.99}, html2canvas: {dpi: 192, letterRendering: true, useCORS: true}, pagebreak: { mode: 'css' }}).save();
}
