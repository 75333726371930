import { FC } from 'react';
import { Count } from './DashboardService';

interface Props {
  count: Count;
}

const DashboardCountListItem: FC<Props> = ({count}) => {
  return (
    <li className='list-group-item list-item'>
      <div className='d-flex w-100'>
        <span className='incident-summary flex-fill'>{count.name}</span>
        <span className='incident-location'>{count.count}</span>
      </div>
    </li>
  );
};

export default DashboardCountListItem;
