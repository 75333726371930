import { FC, RefObject } from 'react';
import CertificationDetailView from './CertificationDetailView';
import { Certification } from './CertificationService';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  certification: Certification | undefined;
  showFooter: boolean;
  onClickDelete: () => void;
}

const CertificationDetailModal: FC<Props> = ({modalRef, certification, showFooter, onClickDelete}: Props) => {
  return (
    <div ref={modalRef} className='modal' tabIndex={-3} data-bs-backdrop='static' data-bs-keyboard='false'>
      <div className='modal-dialog modal-lg modal-fullscreen-lg-down'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{certification?.name}</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='close'></button>
          </div>
          <div className='modal-body'>
            <CertificationDetailView certification={certification} />
          </div>
          {showFooter &&
          <div className='modal-footer modal-bottom'>
            <a href={certification?.url} className='btn btn-primary' download>Download</a>
            <button id='delete-certification-btn' type='button' className='btn btn-primary w-25' onClick={(e) => { onClickDelete(); }}>Delete</button>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default CertificationDetailModal;
