import { FC } from 'react';
import { CMEParticipantCredit } from './CertificationService';

import './certifications.scss';

interface Props {
  credit: CMEParticipantCredit;
}

const CMEListItem: FC<Props> = ({credit}) => {
  return (
    <li className='list-group-item list-item'>
      <div className='d-flex w-100'>
        <span className='certification-name flex-fill'>{credit.title}</span>
        <span className='certification-issued'>{credit.num_minutes / 60} {credit.num_minutes / 60 <= 1 ? 'hour' : 'hours'}</span>
      </div>
      <div>
        <span className='certification-issued'>{credit.cme_provider}</span>
      </div>
    </li>
  );
};

export default CMEListItem;
