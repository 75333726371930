import { FC, useEffect, useRef, useState } from 'react';
import Navbar from '../nav/Navbar';
import Footer from '../nav/Footer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LeaderboardInviteAcceptRequest } from './LeaderboardService';
import { useAppDispatch } from '../app/hooks';
import { leaderboardInviteAcceptAsync } from './leaderboardSlice';
import { isSuccess } from '../common/types';
import { showError } from '../common/alerts';

const LeaderboardInviteAcceptPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const alertRef = useRef<HTMLDivElement>(null);

  let [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const [inviteCode, setInviteCode] = useState<string>();
  const handleInviteCode = (event: React.ChangeEvent<HTMLInputElement>) => setInviteCode(event.target.value);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    if (inviteCode == null) {
      showError(alertRef, 'Invite code is required');
      return;
    }

    const req: LeaderboardInviteAcceptRequest = {
      invite_code: inviteCode
    };

    const res = await dispatch(leaderboardInviteAcceptAsync(req)).unwrap();
    if (isSuccess(res)) {
      navigate('/', { replace: true });
    } else {
      showError(alertRef, res.message);
    }
  };

  useEffect(() => {
    document.title = 'ResponderLog - Leaderboard Invite';

    if (code != null) {
      setInviteCode(code);
    }
  }, [code]);

  return (
    <div>
      <Navbar name='leaderboard-invite-accept' showNav={false} />
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <form onSubmit={formHandler}>
              <div className='card mt-4 text-start'>
                <div className='card-header'>Leaderboard Invite</div>
                <div className='card-body'>
                  <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='invite-code'>Invite Code</label>
                    <input type='text' className='form-control mt-0' id='invite-code' value={inviteCode} onChange={(e) => { handleInviteCode(e); }} />
                  </div>
                  <div className='mt-4 justify-content-center text-center'>
                    <button type='submit' id='btn-submit' className='btn btn-primary w-50'>Accept Invite</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LeaderboardInviteAcceptPage;
