import { FC, RefObject } from 'react';
import { Modal } from 'bootstrap';
import { useAppSelector } from '../app/hooks';
import { selectAgencies } from '../accounts/agencySlice';
import AgencyListItem from './AgencyListItem';

interface Props {
  agencyModalRef: RefObject<HTMLDivElement>;
}

const AgenciesCard: FC<Props> = ({agencyModalRef}: Props) => {
  const agencies = useAppSelector(selectAgencies);

  const onClickAdd = () => {
    if (agencyModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(agencyModalRef.current);
      modal.show();
    }
  };

  return (
    <div className='card'>
      <div className='card-header d-flex w-100'>
        <span className='align-self-center flex-fill'>Departments</span>
        <button type='button' className='btn btn-sm btn-outline-primary' onClick={(e) => { onClickAdd(); }}>Add Department</button>
      </div>
      <div className='card-body m-0 p-0'>
        <div className='container-fluid m-0 p-0'>
          <div className='list-group list-group-flush m-0 p-0'>
            {agencies.map((agency) =>
            <AgencyListItem key={agency.id} agency={agency} />
            )}
            {agencies.length === 0 &&
            <li className='list-group-item list-item flex-column mx-auto empty-list text-center'>
              <p className='text-muted mt-3'>No departments</p>
            </li>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgenciesCard;
