import { FC, useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import Footer from '../nav/Footer';
import Navbar from '../nav/Navbar';
import Tabbar from '../nav/Tabbar';
import TrainingList from './TrainingList';
import { TrainingSession } from './TrainingService';
import TrainingSessionDetailView from './TrainingSessionDetailView';
import TrainingSessionDetailModal from './TrainingSessionDetailModal';

import { deleteTrainingSessionAsync, fetchTrainingSessionsAsync, selectTrainingSessionCount, selectTrainingSessions } from './trainingSlice';
import { Modal } from 'bootstrap';
import AddTrainingSessionModal from './AddTrainingSessionModal';
import ConfirmationModal from '../common/ConfirmationModal';
import { isSuccess } from '../common/types';
import { useSearchParams } from 'react-router-dom';
import Pagination from '../nav/Pagination';
import EditTrainingSessionModal from './EditTrainingSessionModal';
import { deleteCMEAsync, fetchCMEProgressReports, selectCMEProgressReports } from '../certifications/certificationSlice';
import AddCMESessionModal from './AddCMESessionModal';
import CMEList from './CMEList';
import { CMEParticipantCredit } from '../certifications/CertificationService';
import CMEDetailView from './CMEDetailView';
import CMEDetailModal from './CMEDetailModal';

const TrainingPage: FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showAddCME, setShowAddCME] = useState<boolean>(false);

  const detailModalRef = useRef<HTMLDivElement>(null);
  const addModalRef = useRef<HTMLDivElement>(null);
  const editModalRef = useRef<HTMLDivElement>(null);
  const deleteModalRef = useRef<HTMLDivElement>(null);

  const addCMEModalRef = useRef<HTMLDivElement>(null);
  const deleteCMEModalRef = useRef<HTMLDivElement>(null);
  const cmeDetailModalRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const sessions = useAppSelector(selectTrainingSessions);
  const sessionCount = useAppSelector(selectTrainingSessionCount);

  const progressReports = useAppSelector(selectCMEProgressReports);

  const [selectedTab, setSelectedTab] = useState<string>((sessionCount === 0 && progressReports.length > 0) ? 'training-tab-cme' : 'training-tab-general');
  const handleSelectedTab = (event: React.ChangeEvent<HTMLInputElement>) => setSelectedTab(event.target.id);

  let [searchParams, setSearchParams] = useSearchParams();

  let currentPage = 1;
  if (searchParams.get('page') != null) {
    currentPage = parseInt(searchParams.get('page')!);
  }

  const [selectedSession, setSelectedSession] = useState<TrainingSession>();
  const [selectedCME, setSelectedCME] = useState<CMEParticipantCredit>();

  useEffect(() => {
    dispatch(fetchTrainingSessionsAsync(currentPage));
  }, [dispatch, currentPage]);

  const addModalListener = (e: PopStateEvent) => {
    if (addModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(addModalRef.current);
      modal.hide();
    }
  };

  const addModalShowListener = (e: any) => {
    window.history.pushState('#add', '', document.location+'#add');
    window.addEventListener('popstate', addModalListener, false);

    if (addModalRef.current != null) {
      addModalRef.current.removeEventListener('shown.bs.modal', addModalShowListener);
    }
  };

  const addModalHideListener = (e: any) => {
    if (addModalRef.current != null) {
      addModalRef.current.removeEventListener('hidden.bs.modal', addModalHideListener);

      const modal = Modal.getOrCreateInstance(addModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#add') {
      window.history.back();
    }

    setShowAdd(false);
    window.removeEventListener('popstate', addModalListener);
  };
  
  const onClickAdd = () => {
    setShowAdd(true);
  };

  useEffect(() => {
    if (addModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(addModalRef.current);
      addModalRef.current.addEventListener('hidden.bs.modal', addModalHideListener);
      addModalRef.current.addEventListener('shown.bs.modal', addModalShowListener);

      modal.show();
    }
  });

  const addCMEModalListener = (e: PopStateEvent) => {
    if (addCMEModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(addCMEModalRef.current);
      modal.hide();
    }
  };

  const addCMEModalShowListener = (e: any) => {
    window.history.pushState('#add-cme', '', document.location+'#add-cme');
    window.addEventListener('popstate', addCMEModalListener, false);

    if (addCMEModalRef.current != null) {
      addCMEModalRef.current.removeEventListener('shown.bs.modal', addCMEModalShowListener);
    }
  };

  const addCMEModalHideListener = (e: any) => {
    if (addCMEModalRef.current != null) {
      addCMEModalRef.current.removeEventListener('hidden.bs.modal', addCMEModalHideListener);

      const modal = Modal.getOrCreateInstance(addCMEModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#add-cme') {
      window.history.back();
    }

    setShowAddCME(false);
    window.removeEventListener('popstate', addCMEModalListener);
  };

  const onClickAddCME = () => {
    setShowAddCME(true);
  };

  useEffect(() => {
    if (showAddCME && addCMEModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(addCMEModalRef.current);
      addCMEModalRef.current.addEventListener('hidden.bs.modal', addCMEModalHideListener);
      addCMEModalRef.current.addEventListener('shown.bs.modal', addCMEModalShowListener);

      modal.show();
    }
  });

  const editModalListener = (e: PopStateEvent) => {
    console.log(JSON.stringify(window.history));

    if (editModalRef.current != null && window.history.state !== '#edit') {
      const modal = Modal.getOrCreateInstance(editModalRef.current);
      modal.hide();
    }
  }

  const editModalShowListener = (e: any) => {
    window.history.pushState('#edit', '', '/training#edit');
    window.addEventListener('popstate', editModalListener, false);

    if (editModalRef.current != null) {
      editModalRef.current.removeEventListener('shown.bs.modal', editModalShowListener);
    }
  };

  const editModalHideListener = (e: any) => {
    if (editModalRef.current != null) {
      editModalRef.current.removeEventListener('hidden.bs.modal', editModalHideListener);

      const modal = Modal.getOrCreateInstance(editModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#edit') {
      window.history.back();
    }

    setShowEdit(false);
    window.removeEventListener('popstate', editModalListener);
  };

  const onClickEdit = () => {
    setShowEdit(true);
  };

  useEffect(() => {
    if (showEdit && editModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(editModalRef.current);
      editModalRef.current.addEventListener('shown.bs.modal', editModalShowListener);
      editModalRef.current.addEventListener('hidden.bs.modal', editModalHideListener);

      modal.show();
    }
  });

  const onClickDelete = () => {
    if (deleteModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteModalRef.current);
      modal.show();
    }
  };

  const onClickDeleteCME = () => {
    if (deleteCMEModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteCMEModalRef.current);
      modal.show();
    }
  };

  const onDeleteTrainingSession = async (result: boolean) => {
    if (deleteModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteModalRef.current);
      modal.hide();
    }

    if (result && selectedSession != null) {
      const res = await dispatch(deleteTrainingSessionAsync(selectedSession.id)).unwrap();
      if (isSuccess(res)) {
        await dispatch(fetchTrainingSessionsAsync(currentPage));
        setSelectedSession(undefined);

        if (sessions.length > 1) {
          setSelectedSession(sessions[0]);

          if (!isMobile) {
            const newElement = document.getElementById('training-'+sessions[0].id);
            if (newElement != null) {
              newElement.classList.add('list-item-selected');
            }
          }
        }

        if (detailModalRef.current != null) {
          const modal = Modal.getOrCreateInstance(detailModalRef.current);
          modal.hide();
        }
      }
    }
  };

  const onDeleteCME = async (result: boolean) => {
    if (deleteCMEModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteCMEModalRef.current);
      modal.hide();
    }

    if (result && selectedCME != null) {
      const res = await dispatch(deleteCMEAsync(selectedCME.id)).unwrap();
      if (isSuccess(res)) {
        await dispatch(fetchCMEProgressReports());
        setSelectedCME(undefined);

        if (progressReports[0].credits.length > 1) {
          setSelectedCME(progressReports[0].credits[0]);

          if (!isMobile) {
            const newElement = document.getElementById('cme-'+progressReports[0].credits[0].id);
            if (newElement != null) {
              newElement.classList.add('list-item-selected');
            }
          }
        }

        if (cmeDetailModalRef.current != null) {
          const modal = Modal.getOrCreateInstance(cmeDetailModalRef.current);
          modal.hide();
        }
      }
    }
  };

  const detailModalListener = (e: PopStateEvent) => {
    if (detailModalRef.current != null && window.history.state !== '#detail') {
      const modal = Modal.getOrCreateInstance(detailModalRef.current);
      modal.hide();
    }
  };

  const detailModalShowListener = (e: any) => {
    window.history.pushState('#detail', '', document.location+'#detail');
    window.addEventListener('popstate', detailModalListener, false);

    if (detailModalRef.current != null) {
      detailModalRef.current.removeEventListener('shown.bs.modal', detailModalShowListener);
    }
  };

  const detailModalHideListener = (e: any) => {
    if (detailModalRef.current != null) {
      detailModalRef.current.removeEventListener('hidden.bs.modal', detailModalHideListener);

      const modal = Modal.getOrCreateInstance(detailModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#detail') {
      window.history.back();
    }

    setSelectedSession(undefined);
    window.removeEventListener('popstate', detailModalListener);
  };

  const onClickSession = (session: TrainingSession) => {
    if (!isMobile) {
      const selectedElement = document.getElementById('training-'+selectedSession?.id);
      if (selectedElement != null) {
        selectedElement.classList.remove('list-item-selected');
      }

      const newElement = document.getElementById('training-'+session.id);
      if (newElement != null) {
        newElement.classList.add('list-item-selected');
      }
    }

    setSelectedSession(session);

    if (isMobile && detailModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(detailModalRef.current);
      detailModalRef.current.addEventListener('shown.bs.modal', detailModalShowListener);
      detailModalRef.current.addEventListener('hidden.bs.modal', detailModalHideListener);

      modal.show();
    }

    if (!isMobile) {
      window.scrollTo(0, 0);
    }
  };

  const cmeDetailModalListener = (e: PopStateEvent) => {
    if (cmeDetailModalRef.current != null && window.history.state !== '#detail-cme') {
      const modal = Modal.getOrCreateInstance(cmeDetailModalRef.current);
      modal.hide();
    }
  };

  const cmeDetailModalShowListener = (e: any) => {
    window.history.pushState('#detail-cme', '', document.location+'#detail-cme');
    window.addEventListener('popstate', cmeDetailModalListener, false);

    if (cmeDetailModalRef.current != null) {
      cmeDetailModalRef.current.removeEventListener('shown.bs.modal', cmeDetailModalShowListener);
    }
  };

  const cmeDetailModalHideListener = (e: any) => {
    if (cmeDetailModalRef.current != null) {
      cmeDetailModalRef.current.removeEventListener('hidden.bs.modal', cmeDetailModalHideListener);

      const modal = Modal.getOrCreateInstance(cmeDetailModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#detail-cme') {
      window.history.back();
    }

    setSelectedCME(undefined);
    window.removeEventListener('popstate', cmeDetailModalListener);
  };

  const onClickCME = (cme: CMEParticipantCredit) => {
    if (!isMobile) {
      const selectedElement = document.getElementById('cme-'+selectedCME?.id);
      if (selectedElement != null) {
        selectedElement.classList.remove('list-item-selected');
      }

      const newElement = document.getElementById('cme-'+cme.id);
      if (newElement != null) {
        newElement.classList.add('list-item-selected');
      }
    }

    setSelectedCME(cme);

    if (isMobile && cmeDetailModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(cmeDetailModalRef.current);
      cmeDetailModalRef.current.addEventListener('shown.bs.modal', cmeDetailModalShowListener);
      cmeDetailModalRef.current.addEventListener('hidden.bs.modal', cmeDetailModalHideListener);

      modal.show();
    }

    if (!isMobile) {
      window.scrollTo(0, 0);
    }
  };

  const onPageRequested = (page: number) => {
    let params = searchParams;
    params.set('page', page.toString());

    setSearchParams(params);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (selectedSession != null) {
      const session = sessions.find((s) => s.id === selectedSession.id);
      setSelectedSession(session);
    }
  }, [sessions, selectedSession]);

  useEffect(() => {
    if (selectedSession == null && sessions.length > 0) {
      setSelectedSession(sessions[0]);

      if (!isMobile) {
        const newElement = document.getElementById('training-'+sessions[0].id);
        if (newElement != null) {
          newElement.classList.add('list-item-selected');
        }
      }
    }
  }, [sessions, selectedSession, isMobile]);

  useEffect(() => {
    document.title = 'ResponderLog - Training';
  }, []);

  return (
    <div>
      <Navbar name='training' showNav={true} />
      <Tabbar name='training' />
      {isMobile &&
      <div className='container-fluid m-0 p-0 standalone-list'>
        {progressReports.length > 0 &&
        <div className='text-center btn-container-sm d-flex w-100'>
          <div className='btn-group w-100' role='group'>
            <input type='radio' className='btn-check' name='training-tab' id='training-tab-general' autoComplete='false' checked={selectedTab === 'training-tab-general'} onChange={(e) => { handleSelectedTab(e); }} />
            <label className='btn btn-outline-primary full-tab' htmlFor='training-tab-general'>Training</label>

            <input type='radio' className='btn-check' name='training-tab' id='training-tab-cme' autoComplete='false' checked={selectedTab === 'training-tab-cme'} onChange={(e) => { handleSelectedTab(e); }} />
            <label className='btn btn-outline-primary full-tab' htmlFor='training-tab-cme'>CMEs</label>
          </div>
        </div>
        }
        <div className='p-3 text-center btn-container-sm d-flex w-100'>
          {selectedTab === 'training-tab-cme' &&
          <button type='button' className='btn btn-outline-primary flex-fill me-2' onClick={(e) => { onClickAddCME(); }}>Add CME</button>
          }
          {selectedTab === 'training-tab-general' &&
          <button type='button' className='btn btn-outline-primary flex-fill ps-2 pe-2' onClick={(e) => { onClickAdd(); }}>Add Training</button>
          }
        </div>
        {selectedTab === 'training-tab-general' &&
        <div>
          <TrainingList sessions={sessions} onClickSession={onClickSession} />
          {(sessionCount / 25) > 1 &&
            <Pagination itemCount={sessionCount} currentPage={currentPage} onPageRequested={onPageRequested} />
          }
        </div>
        }
        {selectedTab === 'training-tab-cme' &&
          <CMEList cmes={progressReports[0].credits} categories={progressReports[0].categories} onClickCME={onClickCME} />
        }
      </div>
      }
      {!isMobile &&
      <div className='container mt-4 pb-4'>
        <div className='row'>
          <div className='col-5'>
            <div className='card'>
              <div className='card-header d-flex w-100'>
                <span className='align-self-center flex-fill'>Training</span>
                {progressReports.length > 0 &&
                <button type='button' className='btn btn-sm btn-outline-primary me-2' onClick={(e) => { onClickAddCME(); }}>Add CME</button>
                }
                <button type='button' className='btn btn-sm btn-outline-primary' onClick={(e) => { onClickAdd(); }}>Add Training</button>
              </div>
              <div className='card-body m-0 p-0'>
                {progressReports.length > 0 &&
                <div className='p-2 text-center btn-container-sm d-flex w-100'>
                  <div className='btn-group w-100' role='group'>
                    <input type='radio' className='btn-check' name='training-tab' id='training-tab-general' autoComplete='false' checked={selectedTab === 'training-tab-general'} onChange={(e) => { handleSelectedTab(e); }} />
                    <label className='btn btn-outline-primary' htmlFor='training-tab-general'>Training</label>

                    <input type='radio' className='btn-check' name='training-tab' id='training-tab-cme' autoComplete='false' checked={selectedTab === 'training-tab-cme'} onChange={(e) => { handleSelectedTab(e); }} />
                    <label className='btn btn-outline-primary' htmlFor='training-tab-cme'>CMEs</label>
                  </div>
                </div>
                }
                {selectedTab === 'training-tab-general' &&
                <TrainingList sessions={sessions} onClickSession={onClickSession} />
                }
                {selectedTab === 'training-tab-cme' &&
                <CMEList cmes={progressReports[0].credits} categories={progressReports[0].categories} onClickCME={onClickCME} />
                }
              </div>
            </div>
            {selectedTab === 'training-tab-general' &&
            <div>
            { (sessionCount / 25) > 1 &&
            <Pagination itemCount={sessionCount} currentPage={currentPage} onPageRequested={onPageRequested} />
            }
            </div>
            }
          </div>
          {selectedTab === 'training-tab-general' && selectedSession != null &&
          <div className='col-7'>
            <div className='card'>
              <div className='card-header d-flex w-100'>
                <span className='align-self-center flex-fill'>{selectedSession.name}</span>
                <button type='button' className='btn btn-sm btn-outline-primary' onClick={(e) => { onClickEdit(); }}>Edit</button>
                <button type='button' className='btn btn-sm btn-outline-primary ms-3' onClick={(e) => { onClickDelete(); }}>Delete</button>
              </div>
              <div className='card-body'>
                <TrainingSessionDetailView session={selectedSession} />
              </div>
            </div>
          </div>
          }
          {selectedTab === 'training-tab-cme' && selectedCME != null &&
          <div className='col-7'>
            <div className='card'>
              <div className='card-header d-flex w-100'>
                <span className='align-self-center flex-fill'>{selectedCME.title}</span>
                <button type='button' className='btn btn-sm btn-outline-primary ms-3' onClick={(e) => { onClickDeleteCME(); }}>Delete</button>
              </div>
              <div className='card-body'>
                <CMEDetailView cme={selectedCME} categories={progressReports[0].categories} />
              </div>
            </div>
          </div>
          }
        </div>
      </div>
      }
      <TrainingSessionDetailModal session={selectedSession} modalRef={detailModalRef} onClickDelete={onClickDelete} onClickEdit={onClickEdit} />
      {showAdd &&
      <AddTrainingSessionModal modalRef={addModalRef} currentPage={currentPage} />
      }
      {(showEdit && selectedSession != null) &&
      <EditTrainingSessionModal modalRef={editModalRef} session={selectedSession} currentPage={currentPage} />
      }
      {showAddCME &&
      <AddCMESessionModal modalRef={addCMEModalRef} />
      }
      <CMEDetailModal cme={selectedCME} modalRef={cmeDetailModalRef} categories={progressReports[0] === undefined ? undefined : progressReports[0].categories} onClickDelete={onClickDeleteCME} />
      <ConfirmationModal modalRef={deleteModalRef} message='Are you sure you want to delete the training session?' onActionTaken={onDeleteTrainingSession} />
      <ConfirmationModal modalRef={deleteCMEModalRef} message='Are you sure you want to delete the CME?' onActionTaken={onDeleteCME} />
      <Footer />
    </div>
  );
};

export default TrainingPage;
