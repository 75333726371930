import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';

import { ErrorResponse, Result } from '../common/types';

export interface IncidentCustomField {
  id: number;
  user_id: number;
  name: string;
  input_type: string;
  is_hidden: boolean;
}

export interface CustomFieldInput {
  id: number;
  value: string;
}

export interface CreateIncidentCustomFieldRequest {
  name: string;
  input_type: string;
}

export interface CreateIncidentRequest {
  agency_id: number;
  dispatch_time: string;
  run_number: string;
  summary: string;
  comments: string;
  location: string;
  role: string;
  mileage: number;
  custom_fields: CustomFieldInput[];
}

export interface CustomField {
  id: number;
  field_type: string;
  incident_id: string;
  name: string;
  value: string;
}

export interface Incident {
  id: number;
  agency_id: number;
  dispatched_at: string;
  run_number: string;
  summary: string;
  comments: string;
  location: string;
  role: string;
  mileage: string;
  custom_fields: CustomField[];
}

export interface IncidentsResponse {
  offset: number;
  count: number;
  incidents: Incident[];
}

export interface IncidentPhoto {
  id: number;
  incident_id: number;
  url: string;
  caption: string;
}

export interface UploadIncidentPhotoRequest {
  incident_id: number;
  file: File;
  file_type: string;
  caption?: string;
}

export interface DeleteIncidentPhotoRequest {
  incident_id: number;
  photo_id: number;
}

export class IncidentService {
  async fetchIncidentCustomFields(): Promise<Result<IncidentCustomField[]>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      if (token == null) {
        return new Error('You are not authenticated');
      }

      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/incidents/fields`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        const fields = res.data as IncidentCustomField[];
        return fields;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async fetchIncidents(offset: number): Promise<Result<IncidentsResponse>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      if (token == null) {
        return new Error('You are not authenticated');
      }

      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/incidents?offset=${offset}`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        const incidentResponse = res.data as IncidentsResponse;
        return incidentResponse;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async fetchIncidentPhotos(incidentId: number): Promise<Result<IncidentPhoto[]>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      if (token == null) {
        return new Error('You are not authenticated');
      }

      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/incidents/${incidentId}/photos`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        const photosResponse = res.data as IncidentPhoto[];
        return photosResponse;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async createCustomField(req: CreateIncidentCustomFieldRequest): Promise<Result<IncidentCustomField>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/incidents/fields`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        const field = res.data as IncidentCustomField;
        return field;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async createIncident(req: CreateIncidentRequest): Promise<Result<Incident>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/incidents`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        const field = res.data as Incident;
        return field;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async deleteIncidentCustomField(fieldId: number): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/incidents/fields/delete`, {
        field_id: fieldId
      }, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async deleteIncident(id: number): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/incidents/delete`, {
        incident_id: id
      }, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async editIncident(id: number, req: CreateIncidentRequest): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/incidents/${id}/edit`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async uploadPhoto(req: UploadIncidentPhotoRequest): Promise<Result<IncidentPhoto>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const data = new FormData();
      data.append('photo', req.file);
      data.append('file_type', req.file_type);

      if (req.caption != null) {
        data.append('caption', req.caption);
      }

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/incidents/${req.incident_id}/photos`, data, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        const photo = res.data as IncidentPhoto;
        return photo;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async deleteIncidentPhoto(req: DeleteIncidentPhotoRequest): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/incidents/${req.incident_id}/photos/${req.photo_id}/delete`, null, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }
}
