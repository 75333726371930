import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { clearError, showError } from '../common/alerts';
import { isSuccess } from '../common/types';

import Footer from '../nav/Footer';
import Navbar from '../nav/Navbar';

import { PasswordResetRequest } from './AccountService';
import { updatePasswordAsync } from './accountSlice';

const NewPasswordPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const alertRef = useRef<HTMLDivElement>(null);

  const [searchParams,] = useSearchParams();
  const token = searchParams.get('t');
  
  const [password, setPassword] = useState<string>();
  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  const [confirmPassword, setConfirmPassword] = useState<string>();
  const handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (password == null || password === '') {
      showError(alertRef, 'Password is required');
      return;
    }

    if (confirmPassword == null || confirmPassword === '') {
      showError(alertRef, 'Please confirm your password');
      return;
    }

    if (password !== confirmPassword) {
      showError(alertRef, 'The passwords entered do not match');
      return;
    }

    if (token == null || token === '') {
      showError(alertRef, 'Password reset request URL is not valid');
      return;
    }

    const req: PasswordResetRequest = {
      token: token,
      password: password
    };

    const res = await dispatch(updatePasswordAsync(req)).unwrap();
    if (isSuccess(res)) {
      navigate('/login', { replace: true });
    } else {
      showError(alertRef, res.message);
    }
  };

  useEffect(() => {
    document.title = 'ResponderLog - New Password';
  }, []);

  return (
    <div>
      <Navbar name='new-password' showNav={false} />
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <form onSubmit={formHandler}>
              <div className='card mt-4 text-start'>
                <div className='card-header'>Create New Password</div>
                <div className='card-body'>
                  <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='password'>Password</label>
                    <input type='password' className='form-control mt-0' id='password' onChange={(e) => { handlePassword(e); }} />
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='confirm-password'>Confirm Password</label>
                    <input type='password' className='form-control mt-0' id='confirm-password' onChange={(e) => { handleConfirmPassword(e); }} />
                  </div>
                  <div className='mt-4 justify-content-center text-center'>
                    <button type='submit' id='btn-submit' className='btn btn-primary w-50'>Update Password</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewPasswordPage;
