import { FC, RefObject, useRef, useState } from 'react';
import { useAppDispatch } from '../app/hooks';
import { clearError, showError } from '../common/alerts';
import { isSuccess } from '../common/types';
import { UploadIncidentPhotoRequest } from './IncidentService';
import { uploadIncidentPhotoAsync } from './incidentsSlice';

interface Props {
  incidentId: number;
  addModalRef: RefObject<HTMLDivElement>;
}

const AddIncidentPhotoModal: FC<Props> = ({incidentId, addModalRef}: Props) => {
  const dispatch = useAppDispatch();

  const alertRef = useRef<HTMLDivElement>(null);

  const [file, setFile] = useState<File>();
  const [fileType, setFileType] = useState<string>();
  const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) {
      const file = event.target.files[0];

      setFile(file);
      setFileType(file.type);
    }
  };

  const [caption, setCaption] = useState<string>();
  const handleCaption = (event: React.ChangeEvent<HTMLInputElement>) => setCaption(event.target.value);

  const onClose = () => {    
    window.history.back();
  };

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    const btn = document.getElementById('add-photo-btn') as HTMLButtonElement;
    if (btn != null) {
      btn.textContent = 'Uploading...';
      btn.disabled = true;
    }

    if (file == null) {
      showError(alertRef, 'Photo is required');
      if (btn != null) {
        btn.textContent = 'Upload';
        btn.disabled = false;
      }

      return;
    }

    if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
      showError(alertRef, 'Invalid file type. Accepted formats are JPG and PNG');
      if (btn != null) {
        btn.textContent = 'Upload';
        btn.disabled = false;
      }

      return;
    }

    const req: UploadIncidentPhotoRequest = {
      incident_id: incidentId,
      file: file,
      file_type: fileType,
      caption: caption
    };

    const res = await dispatch(uploadIncidentPhotoAsync(req)).unwrap();
    if (isSuccess(res)) {
      if (btn != null) {
        btn.textContent = 'Upload';
        btn.disabled = false;
      }

      window.history.back();
    } else {
      showError(alertRef, res.message);
    }
  };

  return (
    <form onSubmit={formHandler}>
      <div ref={addModalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-lg modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Photo</h5>
              <button id='add-photo-close-btn' type='button' className='btn-close' onClick={(e) => { onClose(); e.preventDefault(); }} aria-label='Close'></button>
            </div>
            <div className='modal-body modal-mobile-scroll'>
              <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
              <div>
                <div>
                  <label className='form-label mb-0' htmlFor='file'>Photo (jpg, png)</label>
                  <input id="file" type="file" className="form-control" onChange={onFileChanged} placeholder='' />
                  <label className='form-label mb-0' htmlFor='file'>(10 MB max file size)</label>
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='caption'>Caption</label>
                  <input type='text' className='form-control mt-0' id='caption' onChange={(e) => { handleCaption(e); }} />
                </div>
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button id='add-photo-btn' type='submit' className='btn btn-primary w-50'>Upload</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddIncidentPhotoModal;
