import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import moment from 'moment';

import { cancelSubscriptionAsync, loadActiveSubscription, resumeSubscriptionAsync, selectActiveSubscription, selectSubscriptionPlans } from '../subscriptions/subscriptionSlice';

import './settings.scss';
import { isSuccess } from '../common/types';

const SubscriptionInfoCard: FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadActiveSubscription());
  }, [dispatch]);

  const plans = useAppSelector(selectSubscriptionPlans);
  const subscription = useAppSelector(selectActiveSubscription);

  const activePlan = plans.find((plan) => {
    return plan.id === subscription?.subscription_plan_id;
  });

  const onClickCancelSubscription = async () => {
    const res = await dispatch(cancelSubscriptionAsync()).unwrap();
    if (isSuccess(res)) {
      
    }
  };

  const onClickResumeSubscription = async () => {
    const res = await dispatch(resumeSubscriptionAsync()).unwrap();
    if (isSuccess(res)) {
      
    }
  };

  return (
    <div className='card top-row-card'>
      <div className='card-header'>
        <span>Subscription</span>
      </div>
      <div className='card-body pt-2'>
        {activePlan &&
        <div>
          <p>
            <span className='subscription-name'>{activePlan.name}</span>
            <br/>
            <span className='subscription-expires'>Subscription active until {moment(subscription?.expiration_timestamp).format('MMMM D, yyyy')}</span>
            <br/>
            {subscription?.autopay &&
            <span className='subscription-renew'>Auto-renew is enabled for this subscription.<br /></span>
            }
            {!subscription?.autopay &&
            <span className='subscription-renew'>Auto-renew is not enabled.<br/></span>
            }
          </p>
          {subscription?.autopay &&
          <button type='button' className='btn btn-outline-primary' onClick={(e) => { onClickCancelSubscription(); }}>Cancel Subscription</button>
          }
          {!subscription?.autopay &&
          <button type='button' className='btn btn-outline-primary' onClick={(e) => { onClickResumeSubscription(); }}>Extend Subscription</button>
          }
        </div>
        }
      </div>
    </div>
  );
};

export default SubscriptionInfoCard;
