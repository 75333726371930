import { FC, useEffect } from 'react';

import './nav.scss';
import certificate_icon from '../svg/icon_certificate.svg';
import alarm_icon from '../svg/icon_alarm.svg';
import training_icon from '../svg/icon_tab_training.svg';
import dashboard_icon from '../svg/icon_dashboard.svg';

interface Props {
  name: string;
}

const Tabbar: FC<Props> = ({name}: Props) => {
  useEffect(() => {
    const tag = document.getElementById('tab-' + name);
    if (tag != null) {
      tag.classList.remove('tab-inactive');
    }
  }, [name]);

  return (
    <div className='tab-bar collapse-lg'>
      <div className='container-flex text-center'>
        <div className='d-flex align-items-center tab-container'>
          <div className='col'>
            <a href='/'><img id='tab-dashboard' src={dashboard_icon} className='tab-icon tab-inactive' alt='Dashboard' /></a>
          </div>
          <div className='col'>
            <a href='/incidents'><img id='tab-incidents' src={alarm_icon} className='tab-icon tab-inactive' alt='Incidents' /></a>
          </div>
          <div className='col'>
            <a href='/training'><img id='tab-training' src={training_icon} className='tab-icon tab-inactive' alt='Training' /></a>
          </div>
          <div className='col'>
            <a href='/certifications'><img id='tab-certifications' src={certificate_icon} className='tab-icon tab-inactive' alt='Certifications' /></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabbar;
