import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';

import { ErrorResponse, Result } from '../common/types';

export interface TrainingSession {
  id: number;
  agency_id: number;
  certification_id: number;
  training_type: string;
  name: string;
  occurred_at: string;
  num_hours: number;
  comments: string;
  tags: string[];
  cme_type: string;
}

export interface TrainingSessionsResponse {
  sessions: TrainingSession[];
  sessionCount: number;
}

export interface CreateTrainingSessionRequest {
  agency_id: number;
  name: string;
  training_type: string;
  occurred_on: string;
  num_hours: number;
  comments: string;
}

export class TrainingService {
  async fetch(offset: number): Promise<Result<TrainingSessionsResponse>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/training?offset=${offset}`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        const sessions = res.data as TrainingSessionsResponse;
        return sessions;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async createSession(req: CreateTrainingSessionRequest): Promise<Result<TrainingSession>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/training`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        const session = res.data as TrainingSession;
        return session;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async deleteSession(sessionId: number): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/training/${sessionId}/delete`, null, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async editSession(id: number, req: CreateTrainingSessionRequest): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/training/${id}/edit`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }
}
