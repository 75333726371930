import { FC } from 'react';
import moment from 'moment';

import { Incident } from './IncidentService';

import './incidents.scss';

interface Props {
  incident: Incident;
  onClickIncident: (incident: Incident) => void;
}

const IncidentListItem: FC<Props> = ({incident, onClickIncident}: Props) => {
  const now = moment();
  const dispatched = moment(incident.dispatched_at);

  let formattedTime = dispatched.format('MM/DD/YY HH:mm');

  if (dispatched.isSame(now, 'year')) {
    formattedTime = dispatched.format('MM/DD HH:mm');
  }

  if (dispatched.isSame(now, 'day')) {
    formattedTime = dispatched.format('HH:mm');
  }

  return (
    <li id={'incident-'+incident.id} className='list-group-item list-item click-pointer' onClick={(e) => { onClickIncident(incident); }}>
      <div className='d-flex w-100'>
        <span className='incident-summary flex-fill'>{incident.summary}</span>
        <span className='incident-dispatched'>{formattedTime}</span>
      </div>
      <div className='incident-location'>
        <span className='incident-location'>{incident.location}</span>
      </div>
    </li>
  );
};

export default IncidentListItem;
