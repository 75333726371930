import { FC, RefObject, forwardRef, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { addCMEAsync, fetchCMEProgressReports, selectCMEProgressReports } from '../certifications/certificationSlice';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import { CMECategory, NewCMERequest } from '../certifications/CertificationService';
import { clearButton, clearError, showError } from '../common/alerts';
import { isSuccess } from '../common/types';
import { Modal } from 'bootstrap';
import { fetchDashboardAsync } from '../dashboard/dashboardSlice';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
}

const AddCMESessionModal: FC<Props> = ({modalRef}) => {
  const dispatch = useAppDispatch();

  const reports = useAppSelector(selectCMEProgressReports);
  const categories = useAppSelector(selectCMEProgressReports).flatMap((report) => report.categories);

  const alertRef = useRef<HTMLDivElement>(null);
  const addButtonRef = useRef<HTMLButtonElement>(null);

  let defaultProgram = '';
  if (reports.length === 1) {
    defaultProgram = reports[0].participant_id.toString();
  }

  const [selectedProgram, setSelectedProgram] = useState<string>(defaultProgram);
  const handleProgram = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedProgram(event.target.value);

  const [selectedCategory, setSelectedCategory] = useState<string>();
  const handleCategory = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedCategory(event.target.value);
  
  const [name, setName] = useState<string>();
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);

  const [cmeProvider, setCMEProvider] = useState<string>();
  const handleCMEProvider = (event: React.ChangeEvent<HTMLInputElement>) => setCMEProvider(event.target.value);

  const [numHours, setNumHours] = useState<string>();
  const handleNumHours = (event: React.ChangeEvent<HTMLInputElement>) => setNumHours(event.target.value);

  const [occurredOn, setOccurredOn] = useState<string>(moment().format('YYYY-MM-DDTHH:mm'));
  const handleOccurredOn = (date: Date | null) => setOccurredOn(date != null ? moment(date).format('YYYY-MM-DDTHH:mm') : '');

  const [file, setFile] = useState<File>();
  const [fileType, setFileType] = useState<string>();
  const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) {
      const file = event.target.files[0];

      setFile(file);
      setFileType(file.type);
    }
  };

  const isDisabledCategory = (category: CMECategory): boolean => {
    let isParentCategory: boolean = false;
    categories.map((c) => {
      if (category.id === c.parent_category_id) {
        isParentCategory = true;
      }

      return category;
  });

    return isParentCategory;
  };

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);
    
    if (addButtonRef.current != null) {
      addButtonRef.current.disabled = true;
      addButtonRef.current.innerText = 'Adding...';
    }

    if (selectedProgram == null || selectedProgram === '') {
      showError(alertRef, 'Please select a CME program');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (selectedCategory == null || selectedCategory === '') {
      showError(alertRef, 'Please select a CME category');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (name == null || name === '') {
      showError(alertRef, 'Please enter a name for the CME');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (numHours == null || numHours === '') {
      showError(alertRef, 'Please enter the number of hours for this CME');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (isNaN(parseInt(numHours))) {
      showError(alertRef, 'Please enter a numeric value for number of hours');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (cmeProvider == null || cmeProvider === '') {
      showError(alertRef, 'Please enter the provider of this CME');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (occurredOn == null || occurredOn === '') {
      showError(alertRef, 'Please enter the date the CME was completed');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (file == null) {
      showError(alertRef, 'Completion certificate is required');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (fileType !== 'application/pdf' && fileType !== 'image/jpeg' && fileType !== 'image/png') {
      showError(alertRef, 'Invalid file type. Accepted formats are PDF, JPG and PNG');
      clearButton(addButtonRef, 'Add');
      return;
    }

    const req: NewCMERequest = {
      name: name.trim(),
      file: file,
      file_type: fileType,
      cme_participant_id: selectedProgram,
      cme_category_id: selectedCategory,
      cme_provider: cmeProvider,
      num_hours: numHours,
      occurred_on: occurredOn
    };

    const res = await dispatch(addCMEAsync(req)).unwrap();
    if (isSuccess(res)) {
      clearButton(addButtonRef, 'Add');

      if (modalRef.current != null) {
        const modal = Modal.getOrCreateInstance(modalRef.current);
        modal.hide();
      }

      await dispatch(fetchCMEProgressReports());
      await dispatch(fetchDashboardAsync());
    } else {
      showError(alertRef, res.message);
      clearButton(addButtonRef, 'Add');
    }
  };

  const onClose = () => {
    window.history.back();
  };

  const DateButton = forwardRef(({value, onClick}: any, ref) => (
    <button type='button' className='form-control mt-0 text-start' onClick={onClick}>{value}</button>
  ));

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add CME</h5>
              <button id='close-btn' type='button' className='btn-close' onClick={(e) => { onClose(); }} aria-label='Close'></button>
            </div>
            <div className='modal-body'>
              <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
              <div>
                <div>
                  <label className='form-label mb-0' htmlFor='cme-program'>CME Program</label>
                  <select className='form-select' id='cme-program' name='cme-program' value={selectedProgram} onChange={(e) => { handleProgram(e); }}>
                    <option key={-1} value=''>Select CME Program</option>
                    {reports.map((report) =>
                    <option key={report.participant_id} value={report.participant_id}>{report.program.certification_level.title}</option>
                    )}
                  </select>
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='cme-program'>Category</label>
                  <select className='form-select' id='cme-category' name='cme-category' value={selectedCategory} onChange={(e) => { handleCategory(e); }}>
                    <option key={-1} value=''>Select Category</option>
                    {categories.map((category) =>
                    <option key={category.id} value={category.id} disabled={isDisabledCategory(category)}>{category.title}</option>
                    )}
                  </select>
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='name'>Name</label>
                  <input type='text' className='form-control mt-0' id='name' name='name' onChange={(e) => { handleName(e); }} />
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='num-hours'>Duration (hours)</label>
                  <input type='text' className='form-control mt-0' id='num-hours' name='num-hours' onChange={(e) => { handleNumHours(e); }} />
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='provider'>CME Provider</label>
                  <input type='text' className='form-control mt-0' id='provider' name='provider' onChange={(e) => { handleCMEProvider(e); }} />
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='occurredOn'>Date Completed</label>
                  <DatePicker selected={new Date(occurredOn)} dateFormat='MM/dd/yyyy' className='form-control mt-0' id='occurredOn' customInput={<DateButton />} showPopperArrow={false} onChange={(date) => { handleOccurredOn(date); }} />
                </div>
              </div>
              <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='file'>Completion Certificate (pdf, jpg, png)</label>
                  <input id="file" type="file" className="form-control" onChange={onFileChanged} placeholder='' />
                  <label className='form-label mb-0' htmlFor='file'>(10 MB max file size)</label>
                </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button ref={addButtonRef} id='add-training-btn' type='submit' className='btn btn-primary w-25'>Add</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddCMESessionModal;
