import { FC, useEffect, useRef, useState } from 'react';
import { Modal } from 'bootstrap';
import ReactGA from 'react-ga4';

import Footer from '../nav/Footer';
import Navbar from '../nav/Navbar';
import SubscriptionInfoCard from './SubscriptionInfoCard';
import AccountInfoCard from './AccountInfoCard';
import AgenciesCard from './AgenciesCard';
import AddAgencyModal from '../accounts/AddAgencyModal';
import CustomIncidentFieldsCard from './CustomIncidentFieldsCard';
import AddCustomIncidentFieldModal from '../incidents/AddCustomIncidentFieldModal';
import ConfirmationModal from '../common/ConfirmationModal';
import { useAppDispatch } from '../app/hooks';
import { deleteIncidentCustomFieldAsync } from '../incidents/incidentsSlice';
import { isSuccess } from '../common/types';
import UserPromoCodeCard from './UserPromoCodeCard';


const SettingsPage: FC = () => {
  const dispatch = useAppDispatch();

  const agencyModalRef = useRef<HTMLDivElement>(null);
  const customFieldModalRef = useRef<HTMLDivElement>(null);
  const deleteCustomFieldModalRef = useRef<HTMLDivElement>(null);

  const [fieldIdForDelete, setFieldIdForDelete] = useState<number>();

  const onClickDelete = (fieldId: number) => {
    setFieldIdForDelete(fieldId);

    if (deleteCustomFieldModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteCustomFieldModalRef.current);
      modal.show();
    }
  };

  const onDeleteCustomField = async (result: boolean) => {
    if (deleteCustomFieldModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteCustomFieldModalRef.current);
      modal.hide();
    }

    if (result && fieldIdForDelete != null) {
      const res = await dispatch(deleteIncidentCustomFieldAsync(fieldIdForDelete)).unwrap();
      if (isSuccess(res)) {

      }

      ReactGA.event({
        category: 'Settings',
        action: 'custom-field-delete',
        label: 'Delete Custom Field'
      });
    }

    setFieldIdForDelete(undefined);
  };

  useEffect(() => {
    document.title = 'ResponderLog - Settings';
  }, []);

  return (
    <div>
      <Navbar name='settings' showNav={true} />
      <div className='container mt-4 pb-4 standalone-list'>
        <div className='row top-row-height'>
          <div className='col-lg-4'>
            <AccountInfoCard />
          </div>
          <div className='col-lg-4 margin-top-sm'>
            <SubscriptionInfoCard />
          </div>
          <div className='col-lg-4 margin-top-sm'>
            <UserPromoCodeCard />
          </div>
        </div>
        <div className='row margin-row-lg'>
          <div className='col-lg-4 margin-top-sm'>
            <AgenciesCard agencyModalRef={agencyModalRef} />
          </div>
          <div className='col-lg-8 margin-top-sm'>
            <CustomIncidentFieldsCard modalRef={customFieldModalRef} onClickDelete={onClickDelete} />
          </div>
        </div>
      </div>
      <Footer />
      <AddAgencyModal modalRef={agencyModalRef} supportClose={true} />
      <AddCustomIncidentFieldModal modalRef={customFieldModalRef} />
      <ConfirmationModal modalRef={deleteCustomFieldModalRef} message='Are you sure you want to delete the custom field? If the field has data, the field will be hidden and the data kept.' onActionTaken={onDeleteCustomField} />
    </div>
  );
};

export default SettingsPage;
