import { FC } from 'react';
import { CMECategory, CMEParticipantCredit } from '../certifications/CertificationService';
import CMEListItem from './CMEListItem';

interface Props {
  cmes: CMEParticipantCredit[];
  categories: CMECategory[];
  onClickCME: (cme: CMEParticipantCredit) => void;
}

const CMEList: FC<Props> = ({cmes, categories, onClickCME}) => {
  return (
    <div className='container-fluid m-0 p-0'>
      <div className='m-0 p-0'>
        <div className='list-group list-group-flush m-0 p-0'>
          {cmes.map((cme) =>
          <CMEListItem key={cme.id} cme={cme} categories={categories} onClickCME={onClickCME} />
          )}
          {cmes.length === 0 &&
          <li className='list-group-item list-item flex-column mx-auto empty-list text-center'>
            <p className='text-muted mt-3'>No CMEs</p>
          </li>
          }
        </div>
      </div>
    </div>
  );
};

export default CMEList;
