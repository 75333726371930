import { FC } from 'react';
import { Certification } from './CertificationService';

import './certifications.scss';
import moment from 'moment';

interface Props {
  certification: Certification;
  onClickCertification: (certification: Certification) => void;
}

const CertificationListItem: FC<Props> = ({certification, onClickCertification}: Props) => {
  return (
    <li id={'certification-'+certification.id} className='list-group-item list-item click-pointer' onClick={(e) => { onClickCertification(certification); }}>
      <div className='d-flex w-100'>
        <span className='certification-name'>{certification.name}</span>
        {certification.is_cme_based &&
        <span className='ms-auto badge text-bg-light p-2'>CME Program</span>
        }
      </div>
      <div className='certification-issued'>
        <span className='certification-issued'>Issued on {moment(certification.issued).format('MMMM D, yyyy')}</span>
        {certification.expires != null &&
        <div className='mt-1 p-0'>
          <span className='certification-expires'>Expires on {moment(certification.expires).format('MMMM D, yyyy')}</span>
        </div>
        }
      </div>
    </li>
  );
};

export default CertificationListItem;
