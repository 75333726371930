import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import { isSuccess } from '../common/types';
import { Agency, AgencyService, CreateAgencyRequest } from './AgencyService';

export interface AgencyState {
  agencies: Agency[];
}

export const fetchAgenciesAsync = createAsyncThunk(
  'agencies/fetch',
  async (_, { dispatch }) => {
    const service = new AgencyService();
    const res = await service.fetchAgencies();

    if (isSuccess(res)) {
      dispatch(persistAgencies(res));
    }

    return res;
  }
);

export const createAgencyAsync = createAsyncThunk(
  'agencies/create',
  async (req: CreateAgencyRequest, { dispatch }) => {
    const service = new AgencyService();
    const res = await service.createAgency(req);

    if (isSuccess(res)) {
      dispatch(fetchAgenciesAsync());
    }

    return res;
  }
);

let blankState: AgencyState = {
  agencies: []
};

export const agencySlice = createSlice({
  name: 'agencies',
  initialState: blankState,
  reducers: {
    loadAgencies: (state) => {
      const json = localStorage.getItem('agencies');
      if (json != null) {
        state = JSON.parse(json);
        return state;
      }
    },
    persistAgencies: (state, action: PayloadAction<Agency[]>) => {
      state = {
        ...state,
        agencies: action.payload
      };

      localStorage.setItem('agencies', JSON.stringify(state));
      return state;
    }
  }
});

export const { loadAgencies, persistAgencies } = agencySlice.actions;

export const selectAgencies = (state: RootState) => {
  return state.agency.agencies;
}

export default agencySlice.reducer;
