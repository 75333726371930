import { DashboardStyle, DashboardTimePeriod } from "../dashboard/DashboardService";

export interface ErrorResponse {
  error: string;
}

type RawResult<T> = T | Error;
export type Result<T> = RawResult<T>;

export function isError<T>(result: RawResult<T>): result is Error {
  return result instanceof Error;
}

export function isSuccess<T>(result: RawResult<T>): result is T {
  return !isError(result);
}

export function labelForDashboardStyle(style: DashboardStyle): string {
  switch (style) {
    case DashboardStyle.BarGraph:
      return 'Bar Graph';
    case DashboardStyle.LineGraph:
      return 'Line Graph';
    case DashboardStyle.List:
      return 'List';
    case DashboardStyle.Numeric:
      return 'Counter';
    case DashboardStyle.PieChart:
      return 'Pie Chart';
    case DashboardStyle.Custom:
      return 'Custom';
  }
}

export function labelForDashboardTimePeriod(timePeriod: DashboardTimePeriod): string {
  switch (timePeriod) {
    case DashboardTimePeriod.Daily:
      return 'Today';
    case DashboardTimePeriod.Monthly:
      return 'This Month';
    case DashboardTimePeriod.Quarterly:
      return 'This Quarter';
    case DashboardTimePeriod.Yearly:
      return 'This Year';
    case DashboardTimePeriod.Custom:
      return 'Custom';
  }
}

export interface DashboardSortOrder {
  id: number;
  sort_order: number;
}
