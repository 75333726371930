import { FC } from 'react';
import { TrainingSession } from './TrainingService';
import TrainingSessionListItem from './TrainingSessionListItem';

interface Props {
  sessions: TrainingSession[];
  onClickSession: (session: TrainingSession) => void;
}

const TrainingList: FC<Props> = ({sessions, onClickSession}: Props) => {
  return (
    <div className='container-fluid m-0 p-0'>
      <div className='m-0 p-0'>
        <div className='list-group list-group-flush m-0 p-0'>
          {sessions.map((session) =>
          <TrainingSessionListItem key={session.id} session={session} onClickSession={onClickSession} />
          )}
          {sessions.length === 0 &&
          <li className='list-group-item list-item flex-column mx-auto empty-list text-center'>
            <p className='text-muted mt-3'>No training sessions</p>
          </li>
          }
        </div>
      </div>
    </div>
  );
};

export default TrainingList;
