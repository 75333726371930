/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logoutAsync, selectUser } from '../accounts/accountSlice';

import './nav.scss';
import logo_inverse from '../svg/logo_inverse.svg';
import user_icon from '../svg/user.svg';
import { useNavigate } from 'react-router-dom';
import ContactSupportModal from '../accounts/ContactSupportModal';
import { Modal } from 'bootstrap';

interface Props {
  name: string;
  showNav: boolean;
  showEdit?: boolean;
  editMode?: boolean;
  onClickEdit?: () => void;
  navTitle?: string;
}

const Navbar: FC<Props> = ({name, showNav, showEdit = false, editMode = false, onClickEdit, navTitle}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);

  const contactModalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tag = document.getElementById('nav-' + name);
    if (tag != null) {
      tag.classList.remove('nav-inactive');
    }
  }, [name]);

  const onClickSettings = () => {
    navigate('/settings');
  };
  
  const onClickLogout = () => {
    navigate('/logout', { replace: true });
  };

  const onClickEditButton = () => {
    if (onClickEdit != null) {
      onClickEdit();
    }
  };

  const onClickSignUp = async () => {
    ReactGA.event({
      category: 'Onboarding',
      action: 'demo-signup-click',
      label: 'Click SignUp Demo'
    });

    await dispatch(logoutAsync());
    navigate('/accounts/create', { replace: true });
  };

  const onClickContact = () => {
    if (contactModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(contactModalRef.current);
      modal.show();
    }
  };

  return (
    <nav className='navbar navbar-expand-lg'>
      <div className='container-fluid'>
        <a className='navbar-brand light-color' href='/'>
          <img className='navbar-logo' src={logo_inverse} alt='ResponderLog' /> 
          {navTitle != null &&
          <span className='align-middle ms-1'>{navTitle}</span>
          }
          {navTitle == null &&
          <span className='align-middle ms-1'>ResponderLog</span>
          }
        </a>
        { showNav &&
        <ul className='nav me-auto'>
          <li className='nav-item'>
            <a id='nav-incidents' href='/incidents' className='nav-link nav-inactive'>Incidents</a>
          </li>
          <li className='nav-item'>
            <a id='nav-training' href='/training' className='nav-link nav-inactive'>Training</a>
          </li>
          <li className='nav-item'>
            <a id='nav-certifications' href='/certifications' className='nav-link nav-inactive'>Certifications</a>
          </li>
        </ul>
        }
        { (showNav && user !== undefined) &&
        <div className='d-flex nav-right'>
          { user.is_demo_account &&
          <button type='button' className='btn btn-sm btn-outline-light me-3' onClick={(e) => { onClickSignUp(); }}>Sign Up</button>
          }
          {showEdit &&
          <button id='edit-dashboard-btn' type='button' className='btn btn-sm btn-outline-light me-3' onClick={(e) => { onClickEditButton(); }}>{editMode ? 'Done' : 'Edit'}</button>
          }
          <div className='nav-item dropdown nav-right dropstart'>
            <a className='nav-link nav-inactive' data-bs-toggle='dropdown' aria-expanded='false'>
              <img className='user-menu' src={user_icon} alt='Menu' />
            </a>
            <ul className='dropdown-menu'>
              <li className='dropdown-item' onClick={(e) => { onClickSettings(); }}>Settings</li>
              { (!user.is_demo_account) &&
              <li className='dropdown-item' onClick={(e) => { onClickContact(); }}>Contact Us</li>
              }
              <li className='dropdown-divider'></li>
              { (user.is_demo_account) &&
              <li className='dropdown-item' onClick={(e) => { onClickLogout(); }}>Exit Demo</li>
              }
              { (!user.is_demo_account) &&
              <li className='dropdown-item' onClick={(e) => { onClickLogout(); }}>Logout</li>
              }
            </ul>
          </div>
        </div>
        }
      </div>
      <ContactSupportModal modalRef={contactModalRef} />
    </nav>
  );
};

export default Navbar;
