import { faTrash, faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel, Modal } from 'bootstrap';
import { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import ConfirmationModal from '../common/ConfirmationModal';
import { DeleteIncidentPhotoRequest, IncidentPhoto } from './IncidentService';
import { deleteIncidentPhotoAsync, fetchIncidentPhotosAsync, selectIncidentPhotos } from './incidentsSlice';

interface Props {
  incidentId: number;
  onClickAddPhoto: () => void;
}

const IncidentPhotosCard: FC<Props> = ({incidentId, onClickAddPhoto}: Props) => {
  const dispatch = useAppDispatch();

  const deleteModalRef = useRef<HTMLDivElement>(null);

  const photos = useAppSelector(selectIncidentPhotos);
  const [selectedPhoto, setSelectedPhoto] = useState<IncidentPhoto>();

  const carouselRef = useRef<HTMLDivElement>(null);

  const onClickDelete = (photo: IncidentPhoto) => {
    setSelectedPhoto(photo);

    if (deleteModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteModalRef.current);
      modal.show();
    }
  };

  const onDeletePhoto = async (result: boolean) => {
    if (deleteModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteModalRef.current);
      modal.hide();
    }

    if (selectedPhoto == null) {
      return;
    }

    if (result) {
      const req: DeleteIncidentPhotoRequest = {
        incident_id: selectedPhoto.incident_id,
        photo_id: selectedPhoto.id
      };

      await dispatch(deleteIncidentPhotoAsync(req)).unwrap();
    } else {
      setSelectedPhoto(undefined);
    }
  };

  const onClickOpen = (photo: IncidentPhoto) => {
    window.open(photo.url, '_blank');
  };

  useEffect(() => {
    dispatch(fetchIncidentPhotosAsync(incidentId));

    if (carouselRef.current != null) {
      new Carousel(carouselRef.current, {
        touch: true
      });
    }
  }, [dispatch, incidentId]);

  return (
    <div className='card mt-4'>
      <div className='card-header d-flex w-100'>
        <span className='align-self-center flex-fill'>Photos</span>
        <button type='button' className='btn btn-sm btn-outline-primary' onClick={(e) => { onClickAddPhoto(); }}>Add Photo</button>
      </div>
      <div className='card-body'>
        {photos.length > 0 &&
        <div ref={carouselRef} id='incident-photos-carousel' className='carousel slide'>
          <div className='carousel-inner'>
            {photos.map((photo, index) => 
            <div key={photo.id} className={index === 0 ? 'carousel-item active' : 'carousel-item'}>
              <img src={photo.url} className='d-block w-100' alt={photo.caption} />
              <div className='carousel-caption d-block'>
                <p>{photo.caption}</p>
                <div>
                  <button type='button' className='btn btn-sm btn-light' onClick={(e) => { onClickDelete(photo); }}><FontAwesomeIcon icon={faTrash} /></button>
                  <button type='button' className='btn btn-sm btn-light ms-3' onClick={(e) => { onClickOpen(photo); }}><FontAwesomeIcon icon={faUpRightFromSquare} /></button>
                </div>
              </div>
            </div>
            )}
          </div>
          <button className='carousel-control-prev' type='button' data-bs-target='#incident-photos-carousel' data-bs-slide='prev'>
            <span className='carousel-control-prev-icon' aria-hidden='true'></span>
            <span className='visually-hidden'>Previous</span>
          </button>
          <button className='carousel-control-next' type='button' data-bs-target='#incident-photos-carousel' data-bs-slide='next'>
            <span className='carousel-control-next-icon' aria-hidden='true'></span>
            <span className='visually-hidden'>Next</span>
          </button>
        </div>
        }
        {photos.length === 0 &&
        <li className='list-group-item list-item flex-column mx-auto empty-list text-center'>
          <p className='text-muted mt-3'>No photos uploaded</p>
        </li>
        }
      </div>
      <ConfirmationModal modalRef={deleteModalRef} message='Are you sure you want to delete the photo?' onActionTaken={onDeletePhoto} />
    </div>
  );
};

export default IncidentPhotosCard;
