import { FC, RefObject, useRef, useState } from 'react';
import { useAppDispatch } from '../app/hooks';
import { clearButton, clearError, showError } from '../common/alerts';
import { LeaderboardInviteRequest } from './LeaderboardService';
import { leaderboardInviteAsync } from './leaderboardSlice';
import { isSuccess } from '../common/types';
import { Modal } from 'bootstrap';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  leaderboardId: number;
}

const LeaderboardInviteModal: FC<Props> = ({modalRef, leaderboardId}) => {
  const dispatch = useAppDispatch();
  const alertRef = useRef<HTMLDivElement>(null);

  const [email, setEmail] = useState<string>();
  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

  const inviteButtonRef = useRef<HTMLButtonElement>(null);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (inviteButtonRef.current != null) {
      inviteButtonRef.current.disabled = true;
      inviteButtonRef.current.innerText = 'Sending...';
    }

    if (email == null || email === '') {
      showError(alertRef, 'Name is required');
      clearButton(inviteButtonRef, 'Send Invite');

      return;
    }

    const req: LeaderboardInviteRequest = {
      leaderboard_id: leaderboardId,
      email: email
    };

    const res = await dispatch(leaderboardInviteAsync(req)).unwrap();
    if (isSuccess(res)) {
      clearButton(inviteButtonRef, 'Send Invite');
      clearError(alertRef);

      if (modalRef.current != null) {
        const modal = Modal.getOrCreateInstance(modalRef.current);
        modal.hide();
      }
    } else {
      showError(alertRef, res.message);
      clearButton(inviteButtonRef, 'Send Invite');
    }

  };

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Invite User To Leaderboard</h5>
              <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div className='modal-body'>
              <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
              <div>
                <p>You can invite users by entering their email below. They will receive an email letting them know they were invited with the ability to accept the invite. Users will appear on the leaderboard once they accept your invitation.</p>
              </div>
              <div>
                <label className='form-label mb-0' htmlFor='name'>Email</label>
                <input type='text' className='form-control mt-0' id='email' onChange={(e) => { handleEmail(e); }} />
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button ref={inviteButtonRef} id='create-leaderboard-btn' type='submit' className='btn btn-primary w-25'>Send Invite</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LeaderboardInviteModal;
