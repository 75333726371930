import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import { store } from './app/store';

import 'bootstrap';
import './index.scss';

import App from './app/App';

import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '157f4bab-1e6e-40bf-a707-42aa83633f6d',
    clientToken: 'pub4db602bcae3cac385405adc54e31d5b9',
    site: 'us5.datadoghq.com',
    service:'responderhq-web',
    env:'production',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel:'mask-user-input'
  });
  
  datadogRum.startSessionReplayRecording();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
