import { FC, useEffect } from 'react';
import Footer from '../nav/Footer';
import ReactGA from 'react-ga4';

import './landing.scss';
import logo_inverse from '../svg/logo_inverse.svg';
import icon_dashboard from '../svg/icon_dashboard.svg';
import icon_pdf from '../svg/icon_pdf.svg';
import icon_cme_progress from '../svg/icon_cme_progress.svg';
import icon_audit from '../svg/icon_audit.svg';
import screenshot_group from '../png/screenshot_group_cme.png';
import screenshots from '../png/screenshots_cme.png';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { isSuccess } from '../common/types';
import { setupDemoAccountAsync } from '../accounts/accountSlice';

const CMEPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const openSignUp = () => {
    navigate('/accounts/create');
  };

  const openLogin = () => {
    navigate('/login');
  };

  const activateDemo = async () => {
    ReactGA.event({
      category: 'Landing',
      action: 'demo',
      label: 'Demo Click'
    });

    const res = await dispatch(setupDemoAccountAsync()).unwrap();

    if (isSuccess(res)) {
      navigate('/');
    }
  };

  useEffect(() => {
    document.title = 'ResponderLog - CME Tracking';
  }, []);

  return (
    <div>
      <div className='header-section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 align-self-center mt-4'>
              <div className='d-flex justify-content-center'>
                <img src={logo_inverse} className='header-logo' alt='ResponderLog' />
                <span className='header-brand align-self-center ms-2'>ResponderLog</span>
              </div>
              <div className='header-tagline mt-4 text-center'>
                Upload your CMEs and track your progress throughout the recertification process. ResponderLog makes it easy to stay on top of your CMEs.
              </div>
              <div className='mt-4 d-flex justify-content-center'>
                <button type='button' className='btn btn-secondary w-25' onClick={(e) => { openSignUp(); }}>Sign Up</button>
                <button type='button' className='btn btn-secondary w-25 ms-3' onClick={(e) => { openLogin(); }}>Log In</button>
                <button type='button' className='btn btn-secondary w-25 ms-3' onClick={activateDemo}>Demo</button>
              </div>
            </div>
            <div className='col-lg-4 mt-4 d-flex justify-content-center'>
              <img src={screenshots} className='screenshots' alt='ResponderLog Screenshots' />
            </div>
          </div>
        </div>
      </div>
      <div className='container mt-4 pb-4'>
        <div className='row justify-content-center'>
          <div className='col-lg-6 p-4'>
            <p className='hero-section-header'>
              <img src={icon_cme_progress} alt='CME Progress' className='hero-section-icon' /> 
              <span className='align-middle ms-3'>CME Progress</span>
            </p>
            <p className='mt-4'>
              Keeping track of all the hours required to recertify can be challenging, especially over the course of 2 or 3 years. ResponderLog provides an overview of each category so you know exactly what hours you have and what you still need to complete.
            </p>
          </div>
          <div className='col-lg-6 p-4'>
            <p className='hero-section-header'>
              <img src={icon_audit} alt='Be Prepared for Audits' className='hero-section-icon' />
              <span className='align-middle ms-3'>Be Prepared for Audits</span>
            </p>
            <p className='mt-4'>
              If your state participates in the National Registry CME program you can be audited at any point requiring you to pull your records of training. National Registry recommends keeping records for 5 years. With ResponderLog you can keep all your recertification records in one place. There are no limits to the number of certifications you can save on ResponderLog.
            </p>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-lg-6 p-4'>
            <p className='hero-section-header'>
              <img src={icon_pdf} alt='Export to PDF' className='hero-section-icon' />
              <span className='align-middle ms-3'>Export to PDF</span>
            </p>
            <p className='mt-3'>
              When you are ready to submit for recertification you can download a single PDF with a cover sheet containing all your CMEs and a copy of each CME completion certificate. Print, email or submit the PDF online as part of your application process.
            </p>
          </div>
          <div className='col-lg-6 p-4'>
            <p className='hero-section-header'>
              <img src={icon_dashboard} alt='Personal Dashboard' className='hero-section-icon' />
              <span className='align-middle ms-3'>Monitor Progress on the Dashboard</span>
            </p>
            <p className='mt-3'>
              When you add a CME based certification on ResponderLog, a card is added to the Dashboard giving you a quick view into the number of hours required, hours completed and hours remaining. It even has an add button and detail button to quickly add a new CME or see your detailed progress.
            </p>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-9 mb-4 text-center'>
            <img src={screenshot_group} className='screenshot-group' alt='ResponderLog Dashboard' />
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-9 mb-4 text-center'>
            <h3 className='section-header-large'>Pricing</h3>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-lg-6 mb-4'>
            <div className='price-card rounded text-center pt-4 pb-4'>
              <p className='mb-0 pb-0'>
                <span className='landing-price'>$2</span><span className='landing-price-label'> /monthly</span>
              </p>
              <p className='mt-0 pt-0 landing-price-tagline'>&nbsp;</p>
            </div>
          </div>
          <div className='col-lg-6 mb-4'>
            <div className='price-card rounded text-center pt-4 pb-4'>
              <p className='mb-0 pb-0'>
                <span className='landing-price'>$15</span><span className='landing-price-label'> /yearly</span>
              </p>
              <p className='mt-0 pt-0 landing-price-tagline'>Save $9 a year with a yearly subscription.</p>
            </div>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-9 mb-4 text-center'>
            <h3 className='section-header-large'>Get Started Today</h3>
            <p className='mt-3 text-start'>If you would like to see what ResponderLog is all about, try out the demo. You can browse the software using our demo account to see it in action. You can cancel your subscription at any time, no hoops to jump through, just a simple button on the settings page.</p>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-6 mb-4 text-center'>
            <button type='button' className='btn btn-lg btn-secondary w-75 pt-4 pb-4' onClick={(e) => { openSignUp(); }}>Create an account</button>
          </div>
          <div className='col-md-6 mb-4 text-center'>
            <button type='button' className='btn btn-lg btn-secondary w-75 pt-4 pb-4' onClick={(e) => { activateDemo(); }}>Try out the demo</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CMEPage;
