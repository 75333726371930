import { FC } from 'react';

import CertificationListItem from './CertificationListItem';
import { Certification } from './CertificationService';

interface Props {
  certifications: Certification[];
  onClickCertification: (certification: Certification) => void;
}

const CertificationsList: FC<Props> = ({certifications, onClickCertification}: Props) => {
  return (
    <div className='container-fluid m-0 p-0'>
      <div className='m-0 p-0'>
        <div className='list-group list-group-flush m-0 p-0'>
          {certifications.map((certification) => 
          <CertificationListItem key={certification.id} certification={certification} onClickCertification={onClickCertification} />
          )}
          {certifications.length === 0 &&
          <li className='list-group-item list-item flex-column mx-auto empty-list text-center'>
            <p className='text-muted mt-3'>No certifications</p>
          </li>
          }
        </div>
      </div>
    </div>
  );
};

export default CertificationsList;
