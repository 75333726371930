import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { IncidentCustomField } from '../incidents/IncidentService';

interface Props {
  field: IncidentCustomField;
  onClickDelete: (fieldId: number) => void;
}

const IncidentCustomFieldListItem: FC<Props> = ({field, onClickDelete}: Props) => {
  const fieldTypeLabels: { [key: string]: string } = {
    'check': 'Checkbox',
    'text': 'Text / Numeric',
    'datetime': 'Date / Time'
  };

  return (
    <li className='list-group-item list-item'>
      <p className='pb-0 mb-0 d-flex'>
        <span className={field.is_hidden ? 'agency-name text-decoration-line-through flex-fill' : 'agency-name flex-fill'}>{field.name}</span>
        {!field.is_hidden &&
        <span className='click-pointer'><FontAwesomeIcon className='icon-color' size='lg' icon={regular('trash')} onClick={(e) => onClickDelete(field.id)} /></span>
        }
        </p>
      <p className='agency-role pt-0 mb-0'>Type: {fieldTypeLabels[field.input_type]}</p>
      {field.is_hidden &&
      <p className='mt-2 text-muted'>Field is hidden. You'll see data entered for this field in the past, but you won't see it on new incidents.</p>
      }
    </li>
  );
};

export default IncidentCustomFieldListItem;
