import { Modal } from 'bootstrap';
import React, { FC, forwardRef, RefObject, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { clearError, showError } from '../common/alerts';
import { isSuccess } from '../common/types';
import { CMEProgram, UploadCertificationRequest } from './CertificationService';
import { fetchCertificationsAsync, fetchCMEProgressReports, selectCMEPrograms, uploadCertificationAsync } from './certificationSlice';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { uniques } from '../common/utils';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  currentPage: number;
}

const AddCertificationModal: FC<Props> = ({modalRef, currentPage}: Props) => {
  const dispatch = useAppDispatch();

  const cmePrograms = useAppSelector(selectCMEPrograms);
  const cmeStates = cmePrograms.flatMap((program) => {
    return program.certification_level.state;
  }).filter(uniques).sort();

  let defaultState = '';
  if (cmeStates.length === 1) {
    defaultState = cmeStates[0].toString();
  }

  const alertRef = useRef<HTMLDivElement>(null);

  const [name, setName] = useState<string>();
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);

  const [issued, setIssued] = useState<string>(moment().format('YYYY-MM-DDTHH:mm'));
  const handleIssued = (date: Date | null) => setIssued(date != null ? moment(date).format('YYYY-MM-DDTHH:mm') : '');

  const [expires, setExpires] = useState<string>();
  const handleExpires = (date: Date | null) => setExpires(date != null ? moment(date).format('YYYY-MM-DDTHH:mm') : '');

  const [showCMEForm, setShowCMEForm] = useState<boolean>(false);

  const [selectedStatePrograms, setSelectedStatePrograms] = useState<CMEProgram[]>([]);

  const [cmeState, setCMEState] = useState<string>(defaultState);
  const handleCMEState = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCMEState(event.target.value);
    setSelectedStatePrograms(cmePrograms.filter((program) => program.certification_level.state === event.target.value));
  };

  const [cmeCertification, setCMECertification] = useState<string>();
  const handleCMECertification = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCMECertification(event.target.value);
  };

  const [hasCME, setHasCME] = useState<boolean>();
  const handleCME = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.id.split('-')[2];
    setShowCMEForm(val === 'yes');
    setHasCME(val === 'yes');
  };

  const [file, setFile] = useState<File>();
  const [fileType, setFileType] = useState<string>();
  const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) {
      const file = event.target.files[0];

      setFile(file);
      setFileType(file.type);
    }
  };

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    const btn = document.getElementById('add-certification-btn') as HTMLButtonElement;
    if (btn != null) {
      btn.textContent = 'Uploading...';
      btn.disabled = true;
    }

    if (name == null || name === '') {
      showError(alertRef, 'Certification name is required');
      if (btn != null) {
        btn.textContent = 'Add';
        btn.disabled = false;
      }
      return;
    }

    if (file == null) {
      showError(alertRef, 'File is required');
      if (btn != null) {
        btn.textContent = 'Add';
        btn.disabled = false;
      }
      return;
    }

    if (fileType !== 'application/pdf' && fileType !== 'image/jpeg' && fileType !== 'image/png') {
      showError(alertRef, 'Invalid file type. Accepted formats are PDF, JPG and PNG');
      if (btn != null) {
        btn.textContent = 'Add';
        btn.disabled = false;
      }
      return;
    }

    const req: UploadCertificationRequest = {
      name: name.trim(),
      file: file,
      file_type: fileType,
      issued: issued,
      expires: expires
    };

    if (hasCME && cmeCertification != null) {
      req.cme_program_id = cmeCertification;
    }

    const res = await dispatch(uploadCertificationAsync(req)).unwrap();
    if (isSuccess(res)) {
      if (btn != null) {
        btn.textContent = 'Add';
        btn.disabled = false;
      }

      if (modalRef.current != null) {
        const modal = Modal.getOrCreateInstance(modalRef.current);
        modal.hide();
      }

      await dispatch(fetchCertificationsAsync(currentPage));

      if (hasCME && cmeCertification != null) {
        await dispatch(fetchCMEProgressReports());
      }
    } else {
      showError(alertRef, res.message);
    }
  };

  useEffect(() => {
    if (modalRef.current != null) {
      modalRef.current.addEventListener('hidden.bs.modal', (event) => {        
        const nameElement = document.getElementById('name') as HTMLInputElement;
        if (nameElement != null) {
          nameElement.value = '';
        }

        const fileElement = document.getElementById('file') as HTMLInputElement;
        if (fileElement != null) {
          fileElement.value = '';
          fileElement.files = null;
        }

        const issuedElement = document.getElementById('issued') as HTMLInputElement;
        if (issuedElement != null) {
          issuedElement.value = '';
        }

        const expiresElement = document.getElementById('expires') as HTMLInputElement;
        if (expiresElement != null) {
          expiresElement.value = '';
        }

        setName(undefined);
        setFile(undefined);
        setFileType(undefined);
        setExpires(undefined);

        clearError(alertRef);
      });
    }
  }, [modalRef]);

  const DateButton = forwardRef(({value, onClick}: any, ref) => (
    <button type='button' className='form-control mt-0 text-start' onClick={onClick}>{value}</button>
  ));

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Certification</h5>
              <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div className='modal-body'>
              <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
              <div>
                <div>
                  <label className='form-label mb-0' htmlFor='name'>Name</label>
                  <input type='text' className='form-control mt-0' id='name' onChange={(e) => { handleName(e); }} />
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='file'>File (pdf, jpg, png)</label>
                  <input id="file" type="file" className="form-control" onChange={onFileChanged} placeholder='' />
                  <label className='form-label mb-0' htmlFor='file'>(10 MB max file size)</label>
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='issued'>Date Issued</label>
                  <DatePicker selected={new Date(issued)} dateFormat='MM/dd/yyyy' className='form-control mt-0' id='issued' customInput={<DateButton />} showPopperArrow={false} onChange={(date) => { handleIssued(date); }} />
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='expires'>Date Expires</label>
                  <DatePicker selected={expires != null && expires !== '' ? new Date(moment(expires).format('YYYY-MM-DDTHH:mm')) : null} isClearable dateFormat='MM/dd/yyyy' className='form-control mt-0' id='expires' customInput={<DateButton />} showPopperArrow={false} onChange={(date) => { handleExpires(date); }} />
                </div>
                <div className='mt-2'>
                  <div>
                    <label className='form-label mb-0' htmlFor='has-cme'>Does this certification have a CME program?</label>
                  </div>
                  <div className='btn-group' role='group'>
                    <input type='radio' className='btn-check' name='has-cme' id='has-cme-yes' onChange={(e) => { handleCME(e); }} />
                    <label className='btn btn-outline-primary' htmlFor='has-cme-yes'>Yes</label>

                    <input type='radio' className='btn-check' name='has-cme' id='has-cme-no' onChange={(e) => { handleCME(e); }} />
                    <label className='btn btn-outline-primary' htmlFor='has-cme-no'>No</label>
                  </div>
                </div>
                {showCMEForm &&
                <div className='mt-2'>
                  <div>
                    <label className='form-label mb-0' htmlFor='cme-state'>Select your state</label>
                    <select className='form-select' id='cme-state' name='cme-state' value={cmeState} onChange={(e) => { handleCMEState(e); }}>
                      <option key={-1} value=''>Select your state</option>
                      {cmeStates.map((state) =>
                      <option key={state} value={state}>{state}</option>
                      )}
                      <option key={-2} value='' disabled={true}>more states coming soon...</option>
                    </select>
                  </div>
                  {cmeState != null &&
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='cme-certification'>Select your certification level</label>
                    <select className='form-select' id='cme-certification' name='cme-certification' value={cmeCertification} onChange={(e) => { handleCMECertification(e); }}>
                      <option key={-1} value=''>Select your state</option>
                      {selectedStatePrograms.map((program) =>
                      <option key={program.id} value={program.id}>{program.certification_level.title}</option>
                      )}
                    </select>
                  </div>
                  }
                </div>
                }
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button id='add-certification-btn' type='submit' className='btn btn-primary w-25'>Add</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddCertificationModal;
