import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { clearError, showError } from '../common/alerts';
import { isSuccess } from '../common/types';
import Footer from '../nav/Footer';
import Navbar from '../nav/Navbar';
import { createPasswordResetAsync } from './accountSlice';

const CreatePasswordResetPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const alertRef = useRef<HTMLDivElement>(null);

  const [email, setEmail] = useState<string>();
  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (email == null || email === '') {
      showError(alertRef, 'Email is required');
      return;
    }

    const result = await dispatch(createPasswordResetAsync(email)).unwrap();
    if (isSuccess(result)) {
      navigate('/login', { replace: true });
    } else {
      showError(alertRef, result.message);
    }
  };

  useEffect(() => {
    document.title = 'ResponderLog - Reset Password';
  }, []);

  return (
    <div>
      <Navbar name='password-reset' showNav={false} />
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <form onSubmit={formHandler}>
              <div className='card mt-4 text-start'>
                <div className='card-header'>Password Reset</div>
                <div className='card-body'>
                  <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
                  <div>
                    <p className='text-muted'>Enter your email below to receive a password reset link. The link you receive will remain active for one hour.</p>
                  </div>
                  <div>
                    <label className='form-label mb-0' htmlFor='email'>Email</label>
                    <input type='email' className='form-control mt-0' id='email' onChange={(e) => { handleEmail(e); }} />
                  </div>
                  <div className='mt-4 justify-content-center text-center'>
                    <button type='submit' id='btn-submit' className='btn btn-primary w-50'>Send Password Reset</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CreatePasswordResetPage;
