import moment from 'moment';
import { FC, forwardRef, RefObject, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { selectAgencies } from '../accounts/agencySlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { CreateTrainingSessionRequest, TrainingSession } from './TrainingService';

import 'react-datepicker/dist/react-datepicker.css';
import { clearButton, clearError, showError } from '../common/alerts';
import { EditTrainingSessionArgs, editTrainingSessionAsync, fetchTrainingSessionsAsync } from './trainingSlice';
import { isSuccess } from '../common/types';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  session: TrainingSession;
  currentPage: number;
}

const EditTrainingSessionModal: FC<Props> = ({modalRef, session, currentPage}: Props) => {
  const dispatch = useAppDispatch();
  
  const agencies = useAppSelector(selectAgencies);
  
  const [selectedAgency, setSelectedAgency] = useState<string>(session.agency_id.toString());
  const handleAgency = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedAgency(event.target.value);

  const [name, setName] = useState<string>(session.name);
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);

  const [selectedTrainingType, setSelectedTrainingType] = useState<string>(session.training_type);
  const handleTrainingType = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedTrainingType(event.target.value);

  const [occurredOn, setOccurredOn] = useState<string>(moment(session.occurred_at).format('YYYY-MM-DDTHH:mm'));
  const handleOccurredOn = (date: Date | null) => setOccurredOn(date != null ? moment(date).format('YYYY-MM-DDTHH:mm') : '');

  const [numHours, setNumHours] = useState<string>(session.num_hours.toString());
  const handleNumHours = (event: React.ChangeEvent<HTMLInputElement>) => setNumHours(event.target.value);

  const [comments, setComments] = useState<string>(session.comments);
  const handleComments = (event: React.ChangeEvent<HTMLTextAreaElement>) => setComments(event.target.value);

  const alertRef = useRef<HTMLDivElement>(null);
  const editButtonRef = useRef<HTMLButtonElement>(null);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (editButtonRef.current != null) {
      editButtonRef.current.disabled = true;
      editButtonRef.current.innerText = 'Saving...';
    }

    if (selectedAgency == null || selectedAgency === '') {
      showError(alertRef, 'Department is required');
      clearButton(editButtonRef, 'Save');
      return;
    }

    if (name == null || name === '') {
      showError(alertRef, 'Session name is required');
      clearButton(editButtonRef, 'Save');
    }

    if (numHours != null && isNaN(parseFloat(numHours))) {
      showError(alertRef, 'Invalid valid for duration provided');
      clearButton(editButtonRef, 'Add');
      return;
    }

    const req: CreateTrainingSessionRequest = {
      agency_id: parseInt(selectedAgency),
      training_type: selectedTrainingType != null ? selectedTrainingType : '',
      name: name.trim(),
      occurred_on: occurredOn,
      num_hours: numHours != null ? parseFloat(numHours): 0,
      comments: comments != null ? comments.trim() : ''
    };

    const args: EditTrainingSessionArgs = {
      id: session.id,
      req: req
    }

    const res = await dispatch(editTrainingSessionAsync(args)).unwrap();
    if (isSuccess(res)) {
      clearButton(editButtonRef, 'Save');
      window.history.back();

      dispatch(fetchTrainingSessionsAsync(currentPage));
    } else {
      showError(alertRef, res.message);
      clearButton(editButtonRef, 'Save');
    }

    return false;
  };

  const onClose = () => {
    window.history.back();
  };

  const DateButton = forwardRef(({value, onClick}: any, ref) => (
    <button type='button' className='form-control mt-0 text-start' onClick={onClick}>{value}</button>
  ));

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-lg modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Training Session</h5>
              <button id='close-btn' type='button' className='btn-close' onClick={(e) => { onClose(); }} aria-label='Close'></button>
            </div>
            <div className='modal-body modal-mobile-scroll'>
              <div>
                <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
                <div>
                  <div>
                    <label className='form-label mb-0' htmlFor='agency'>Department</label>
                    <select className='form-select' id='agency' name='agency' value={selectedAgency} onChange={(e) => { handleAgency(e); }}>
                      <option key={-1} value=''>Select department</option>
                      {agencies.map((agency) =>
                      <option key={agency.id} value={agency.id}>{agency.name}</option>
                      )}
                    </select>
                  </div>
                  <div>
                    <label className='form-label mb-0' htmlFor='name'>Name</label>
                    <input type='text' className='form-control mt-0' id='name' name='name' value={name} onChange={(e) => { handleName(e); }} />
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='training-type'>Training Type</label>
                    <select id='training-type' name='training-type' value={selectedTrainingType} className='form-select mt-0' onChange={(e) => { handleTrainingType(e); }}>
                      <option value=''>Select training type</option>
                      <option value='In House'>In House</option>
                      <option value='Department'>Department</option>
                      <option value='County/Regional'>County / Regional</option>
                      <option value='State'>State</option>
                      <option value='Outside Vendor'>Outside Vendor</option>
                    </select>
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='occurred-on'>Occurred On</label>
                    <DatePicker selected={new Date(occurredOn)} dateFormat='MM/dd/yyyy HH:mm' className='form-control mt-0' id='dispatched' customInput={<DateButton />} showPopperArrow={false} showTimeSelect timeFormat="HH:mm" timeIntervals={1} onChange={(date) => { handleOccurredOn(date); }} />
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='num-hours'>Duration (hours)</label>
                    <input type='text' className='form-control mt-0' id='num-hours' name='num-hours' value={numHours} onChange={(e) => { handleNumHours(e); }} />
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='comments'>Comments</label>
                    <textarea className='form-control mt-0' rows={4} id='comments' name='comments' value={comments} onChange={(e) => { handleComments(e); }} />
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button ref={editButtonRef} id='edit-training-btn' type='submit' className='btn btn-primary w-25'>Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditTrainingSessionModal;
