import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../app/hooks';
import { PromoCode } from '../subscriptions/SubscriptionService';
import { getUserPromoCodeAsync } from '../subscriptions/subscriptionSlice';
import { isSuccess } from '../common/types';

const UserPromoCodeCard: FC = () => {
  const [promoCode, setPromoCode] = useState<PromoCode>();

  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchPromoCode = async () => {
      const result = await dispatch(getUserPromoCodeAsync()).unwrap();
      if (isSuccess(result)) {
        setPromoCode(result);
      }
    };

    fetchPromoCode();
  }, [dispatch]);

  return (
    <div className='card top-row-card'>
      <div className='card-header'>
        <span>Promo Code</span>
      </div>
      <div className='card-body pt-2'>
        <p className='subscription-expires'>Share the promo code below with your friends to earn free months on your subscription. For each user that subscribes to the yearly subscription, you'll receive an additional month on your subscription. Your friend will also get 10% when they use your code.</p>
        {promoCode &&
        <p>
          <span className='subscription-name'>Promo code</span>
          <br />
          <span className='badge text-bg-light promo-code-text'>{promoCode.code}</span>
          <br />
          {promoCode.order_count === 0 &&
          <span className='subscription-expires'>No one has signed up with your promo code yet.</span>
          }
          {promoCode.order_count === 1 &&
          <span className='subscription-expires'>1 user has signed up with your promo code.</span>
          }
          {promoCode.order_count > 1 &&
          <span className='subscription-expires'>{promoCode.order_count} users have signed up with your promo code.</span>
          }
        </p>
        }
      </div>
    </div>
  );
};

export default UserPromoCodeCard;
