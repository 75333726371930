import { Modal } from 'bootstrap';
import { FC, RefObject } from 'react';
import { useAppSelector } from '../app/hooks';
import { selectIncidentCustomFields } from '../incidents/incidentsSlice';
import IncidentCustomFieldListItem from './IncidentCustomFieldListItem';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  onClickDelete: (fieldId: number) => void;
}

const CustomIncidentFieldsCard: FC<Props> = ({modalRef, onClickDelete}: Props) => {
  const fields = useAppSelector(selectIncidentCustomFields);

  const onClickAdd = () => {
    if (modalRef.current != null) {
      const modal = Modal.getOrCreateInstance(modalRef.current);
      modal.show();
    }
  };

  return (
    <div className='card'>
      <div className='card-header d-flex w-100'>
        <span className='align-self-center flex-fill'>Custom Incident Fields</span>
        <button type='button' className='btn btn-sm btn-outline-primary' onClick={(e) => { onClickAdd(); }}>Add New Field</button>
      </div>
      <div className='card-body m-0 p-0'>
        <div className='container-fluid m-0 p-0'>
          <div className='list-group list-group-flush m-0 p-0'>
            {fields.map((field) =>
            <IncidentCustomFieldListItem key={field.id} field={field} onClickDelete={onClickDelete} />
            )}
            {fields.length === 0 &&
            <li className='list-group-item list-item flex-column mx-auto empty-list text-center'>
              <p className='text-muted mt-3'>No custom fields</p>
            </li>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomIncidentFieldsCard;
