import { FC, RefObject } from 'react';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  message: string;
  onActionTaken: (result: boolean) => void;
}

const ConfirmationModal: FC<Props> = ({modalRef, message, onActionTaken}: Props) => {
  return (
    <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Delete</h5>
          </div>
          <div className='modal-body'>
            {message}
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-outline-primary w-25' onClick={(e) => { onActionTaken(false); }}>Cancel</button>
            <button type='button' className='btn btn-primary w-25' onClick={(e) => { onActionTaken(true); }}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
