import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga4';

import { logoutAsync, selectUser } from '../accounts/accountSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import usa from '../svg/usa.svg';
import './nav.scss';

const Footer: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cookies = new Cookies();

  const user = useAppSelector(selectUser);

  const onClickSignUp = async () => {
    ReactGA.event({
      category: 'Onboarding',
      action: 'demo-signup-click',
      label: 'Click SignUp Demo'
    });

    await dispatch(logoutAsync());
    navigate('/accounts/create', { replace: true });
  };
  
  return (
    <footer className='d-flex'>
      <div className='ps-3 text-start standalone-bottom flex-fill'>
        <span className='align-middle'>&copy; 2023 RescueHQ, LLC. Proudly made in</span> <img className='flag ms-1 align-middle' src={usa} alt='United States' />
      </div>
      <div className='me-auto pe-4'>
        {(user != null && user.is_demo_account && cookies.get('user_token') != null) &&
        <button type='button' className='btn btn-sm btn-outline-primary' onClick={(e) => { onClickSignUp(); }}>Sign Up</button>
        }
      </div>
    </footer>
  );
};

export default Footer;
