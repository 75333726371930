import { FC } from 'react';
import { LeaderboardMember } from './LeaderboardService';
import LeaderboardMemberListItem from './LeaderboardMemberListItem';

interface Props {
  leaderboardId: number;
  members: LeaderboardMember[];
  isOwner: boolean;
  onClickInvite: (leaderboardId: number) => void;
}

const LeaderboardList: FC<Props> = ({leaderboardId, members, isOwner, onClickInvite}) => {
  let sortedMembers: LeaderboardMember[] = [];
  members.forEach((member) => {
    sortedMembers.push(member);
  });

  sortedMembers.sort((a, b) => {
    if (a.count - b.count > 0) {
      return -1;
    } else {
      return 1;
    }
  });

  return (
    <div className='container-fluid m-0 p-0'>
      <div className='m-0 p-0'>
        <div className='list-group list-group-flush m-0 p-0'>
          {sortedMembers.map((member) =>
          <LeaderboardMemberListItem member={member} />
          )}
          {isOwner &&
          <li className='list-group-item list-item click-pointer' onClick={(e) => { onClickInvite(leaderboardId); }}>
            <span className='incident-location'>Invite user to leaderboard</span>
          </li>
          }
        </div>
      </div>
    </div>
  );
};

export default LeaderboardList;
