import { Modal } from 'bootstrap';
import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';

import { useAppDispatch } from '../app/hooks';
import { clearError, showError } from '../common/alerts';
import { isSuccess } from '../common/types';
import { CreateIncidentCustomFieldRequest } from './IncidentService';
import { createIncidentCustomFieldAsync } from './incidentsSlice';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
}

const AddCustomIncidentFieldModal: FC<Props> = ({modalRef}: Props) => {
  const dispatch = useAppDispatch();
  const alertRef = useRef<HTMLDivElement>(null);

  const [name, setName] = useState<string>();
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);

  const [inputType, setInputType] = useState<string>();
  const handleInputType = (event: React.ChangeEvent<HTMLSelectElement>) => setInputType(event.target.value);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (name == null || name === '') {
      showError(alertRef, 'Field name is required');
      return;
    }

    if (inputType == null) {
      showError(alertRef, 'Field type is required');
      return;
    }

    const req: CreateIncidentCustomFieldRequest = {
      name: name.trim().split(' ').map((w) => w[0].toUpperCase() + w.substring(1)).join(' '),
      input_type: inputType
    };

    const res = await dispatch(createIncidentCustomFieldAsync(req)).unwrap();
    if (isSuccess(res)) {
      if (modalRef.current != null) {
        const modal = Modal.getOrCreateInstance(modalRef.current);
        modal.hide();
      }

      ReactGA.event({
        category: 'Settings',
        action: 'custom-field-create',
        label: 'Create Custom Field'
      });
    } else {
      showError(alertRef, res.message);
    }
  };

  useEffect(() => {
    if (modalRef.current != null) {
      modalRef.current.addEventListener('hidden.bs.modal', (event) => {
        const nameElement = document.getElementById('field-name') as HTMLInputElement;
        if (nameElement != null) {
          nameElement.value = '';
        }

        const typeElement = document.getElementById('field-type') as HTMLSelectElement;
        if (typeElement != null) {
          typeElement.value = '';
        }

        setName(undefined);
        setInputType(undefined);

        clearError(alertRef);
      });
    }
  }, [modalRef]);

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Custom Incident Field</h5>
              <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div className='modal-body'>
              <p>Custom fields allow you to enter information other than the fields provided by ResponderLog when you are logging incidents.</p>
              <div>
                <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
                <div>
                  <label className='form-label mb-0' htmlFor='name'>Name</label>
                  <input type='text' className='form-control mt-0' id='field-name' name='field-name' onChange={(e) => { handleName(e); }} />
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='type'>Type</label>
                  <select className='form-select' id='field-type' name='field-type' onChange={(e) => { handleInputType(e); }}>
                    <option value=''>Select field type</option>
                    <option value='text'>Text / Numeric</option>
                    <option value='datetime'>Date / Time</option>
                    <option value='check'>Checkbox</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button id='add-field-btn' type='submit' className='btn btn-primary w-25'>Add</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddCustomIncidentFieldModal;
