import moment from 'moment';
import { FC } from 'react';
import { CMEProgressReport, Certification } from './CertificationService';
import { useAppSelector } from '../app/hooks';
import { selectCMEProgressReports } from './certificationSlice';
import CMEListItem from './CMEListItem';
import { buildPDFReport } from './printFriendlyCMEReport';
import { selectUser } from '../accounts/accountSlice';

interface Props {
  certification: Certification | undefined;
}

const CertificationDetailView: FC<Props> = ({certification}: Props) => {
  const user = useAppSelector(selectUser);

  const progressReports = useAppSelector(selectCMEProgressReports).filter((report) => report.certification.id === certification?.id);
  let progressReport: CMEProgressReport | null = null;
  if (progressReports.length > 0) {
    progressReport = progressReports[0];
  }

  const parentCategories = progressReport?.categories.filter((category) => category.parent_category_id == null);

  const onClickPDF = () => {
    if (progressReport != null && user != null) {
      buildPDFReport(progressReport, user);
    }
  };

  return (
    <div>
      {certification?.preview_url != null &&
      <div>
        <img src={certification.preview_url} alt={certification.name} className='w-100' />
      </div>
      }
      {certification?.issued != null &&
      <div className='mt-2'>
        <label className='form-label mb-0'>Issued</label>
        <p className=''>{moment(certification.issued).format('MMMM D, YYYY')}</p>
      </div>
      }
      {certification?.expires != null &&
      <div className='mt-2'>
        <label className='form-label mb-0'>Expires</label>
        <p className=''>{moment(certification.expires).format('MMMM D, YYYY')}</p>
      </div>
      }
      {progressReport != null &&
      <div className='mt-4'>
        <hr className='divider-color' />
        <div className='d-flex w-100'>
          <span className='align-self-center flex-fill'>CME Progress</span>
          <button type='button' className='btn btn-sm btn-outline-primary ms-3' onClick={(e) => { onClickPDF(); }}>Download as PDF</button>
        </div>
        <div className='mt-2'>
          <div className='d-flex w-100 ps-3 pe-3 justify-content-between'>
            <div className='p-3 text-center'>
              <span className='cme-summary-count'>{progressReport.total_hours}</span>
              <br />
              <span className='cme-summary-label'>Required Hours</span>
            </div>
            <div className='p-3 text-center'>
              <span className='cme-summary-count'>{progressReport.outstanding_hours}</span>
              <br />
              <span className='cme-summary-label'>Remaining Hours</span>
            </div>
            <div className='p-3 text-center'>
              <span className='cme-summary-count'>{progressReport.completed_hours}</span>
              <br />
              <span className='cme-summary-label'>Completed Hours</span>
            </div>
          </div>
        </div>
        {parentCategories?.map((parent) => 
        <div className='mt-2'>
          <div className='d-flex w-100'>
            <span className='align-self-center flex-fill cme-category-title'>{parent.title}</span>
            <span>{progressReport?.credits.filter((credit) => progressReport?.categories.find((category) => category.id === credit.cme_category_id)?.parent_category_id === parent.id).flatMap((credit) => credit.num_minutes).reduce((prev, current) => prev + current, 0)! / 60} of {parent.num_minutes / 60} {parent.num_minutes / 60 <= 1 ? 'hour' : 'hours'}</span>
          </div>
          {progressReport?.categories.filter((category) => category.parent_category_id != null && category.parent_category_id === parent.id).length === 0 &&
          <div>
            <hr className='divider-color' />
            {progressReport?.credits.filter((credit) => credit.cme_category_id === parent.id).length === 0 &&
            <div className='d-flex w-100 justify-content-center m-4'>
              <p className='cme-none'>No CMEs for this category</p>
            </div>
            }
            {progressReport?.credits.filter((credit) => credit.cme_category_id === parent.id).map((credit) =>
            <CMEListItem key={credit.id} credit={credit} />
            )}
          </div>
          }
          {progressReport?.categories.filter((category) => category.parent_category_id != null && category.parent_category_id === parent.id).map((category) =>
            <div key={category.id}>
              <div className='d-flex w-100'>
                <span className='cme-subcategory-title align-self-center flex-fill'>{category.title}</span>
                <span>{progressReport?.credits.filter((credit) => credit.cme_category_id === category.id).flatMap((credit) => credit.num_minutes).reduce((prev, current) => prev + current, 0)! / 60} of {category.num_minutes / 60} {category.num_minutes / 60 <= 1 ? 'hour' : 'hours'}</span>
              </div>
              <hr className='divider-color' />
              <div>
                {progressReport?.credits.filter((credit) => credit.cme_category_id === category.id).map((credit) =>
                <div className='pt-1 pb-4' key={credit.id}>
                  <CMEListItem credit={credit} />
                </div>
                )}
                {progressReport?.credits.filter((credit) => credit.cme_category_id === category.id).length === 0 &&
                <div className='d-flex w-100 justify-content-center m-4'>
                  <p className='cme-none'>No CMEs for this category</p>
                </div>
                }
              </div>
            </div>
          )}
        </div>
        )}
      </div>
      }
    </div>
  );
};

export default CertificationDetailView;
