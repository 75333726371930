import { FC, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import ReactGA from 'react-ga4';

import { Modal, Toast } from 'bootstrap';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import AddAgencyModal from '../accounts/AddAgencyModal';
import { fetchAgenciesAsync } from '../accounts/agencySlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { isSuccess, labelForDashboardTimePeriod } from '../common/types';

import Footer from '../nav/Footer';
import Navbar from '../nav/Navbar';

import './dashboard.scss';
import Tabbar from '../nav/Tabbar';

import { deleteDashboardCardAsync, fetchDashboardAsync, selectDashboard, updateDashboardCardsSortAsync } from './dashboardSlice';
import { DashboardDataType, DashboardItem, DashboardStyle } from './DashboardService';
import IncidentsList from '../incidents/IncidentsList';
import { Incident } from '../incidents/IncidentService';
import { useMediaQuery } from 'react-responsive';
import IncidentDetailModal from '../incidents/IncidentDetailModal';
import ConfirmationModal from '../common/ConfirmationModal';
import { deleteIncidentAsync, selectIncidents } from '../incidents/incidentsSlice';
import EditIncidentModal from '../incidents/EditIncidentModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDown, faCirclePlus, faCircleUp, faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons';
import DashboardTemplatesModal from './DashboardTemplatesModal';
import DashboardCustomCardModal from './DashboardCustomCardModal';
import MessageToast from '../common/MessageToast';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CreateLeaderboardModal from '../leaderboard/CreateLeaderboardModal';
import LeaderboardList from '../leaderboard/LeaderboardList';
import { DashboardLeadboardData, LeaderboardMember } from '../leaderboard/LeaderboardService';
import LeaderboardInviteModal from '../leaderboard/LeaderboardInviteModal';
import { selectUser } from '../accounts/accountSlice';
import CreateCMEDashboardCardModal from './CreateCMEDashboardCardModal';
import { CMEProgressReport, Certification } from '../certifications/CertificationService';
import AddCMESessionModal from '../training/AddCMESessionModal';
import CertificationDetailModal from '../certifications/CertificationDetailModal';
import DashboardCountList from './DashboardCountList';

const COLORS = ['#CE2029', '#FFEA20', '#E7B10A', '#5BC0F8', '#060047', '#1F8A70', '#6D67E4', '#850000', '#2B3A55', '#413543'];

const DashboardPage: FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const incidents = useAppSelector(selectIncidents);
  let items = useAppSelector(selectDashboard);

  const agencyModalRef = useRef<HTMLDivElement>(null);
  const incidentDetailModalRef = useRef<HTMLDivElement>(null);

  const user = useAppSelector(selectUser);

  const [selectedIncident, setSelectedIncident] = useState<Incident>();
  const deleteModalRef = useRef<HTMLDivElement>(null);
  const incidentEditModalRef = useRef<HTMLDivElement>(null);

  const [cardDelete, setCardDelete] = useState<DashboardItem>();
  const deleteCardModalRef = useRef<HTMLDivElement>(null);

  const templatesModalRef = useRef<HTMLDivElement>(null);
  const customCardModalRef = useRef<HTMLDivElement>(null);

  const leaderboardModalRef = useRef<HTMLDivElement>(null);
  const leaderboardInviteModalRef = useRef<HTMLDivElement>(null);
  const cmeModalRef = useRef<HTMLDivElement>(null);

  const [selectedLeaderboardId, setSelectedLeaderboardId] = useState<number>();

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState<boolean>(false);

  const [selectedCertification, setSelectedCertification] = useState<Certification>();

  useEffect(() => {
    async function fetchAgencies() {
      const res = await dispatch(fetchAgenciesAsync()).unwrap();
      if (isSuccess(res)) {
        if (res.length === 0) {
          if (agencyModalRef.current != null) {
            const modal = Modal.getOrCreateInstance(agencyModalRef.current);
            modal.show();
          }
        }
      }
    };

    fetchAgencies();
  });

  useEffect(() => {
    dispatch(fetchDashboardAsync());
  }, [dispatch]);

  const incidentDetailModalListener = (e: PopStateEvent) => {
    if (incidentDetailModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(incidentDetailModalRef.current);
      modal.hide();
    }
  };

  const incidentDetailModalShowListener = (e: any) => {
    window.history.pushState('#incident-detail', '', document.location+'#incident-detail');
    window.addEventListener('popstate', incidentDetailModalListener, false);

    if (incidentDetailModalRef.current != null) {
      incidentDetailModalRef.current.removeEventListener('shown.bs.modal', incidentDetailModalShowListener);
    }
  };

  const incidentDetailModalHideListener = (e: any) => {
    if (incidentDetailModalRef.current != null) {
      incidentDetailModalRef.current.removeEventListener('hidden.bs.modal', incidentDetailModalHideListener);

      const modal = Modal.getOrCreateInstance(incidentDetailModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#incident-detail') {
      window.history.back();
    }

    window.removeEventListener('popstate', incidentDetailModalListener);
  };

  const onClickIncident = (incident: Incident) => {
    ReactGA.event({
      category: 'Dashboard',
      action: 'dashboard-incident-view',
      label: 'Dashboard View Incident'
    });

    setSelectedIncident(incident);

    if (incidentDetailModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(incidentDetailModalRef.current);
      incidentDetailModalRef.current.addEventListener('shown.bs.modal', incidentDetailModalShowListener);
      incidentDetailModalRef.current.addEventListener('hidden.bs.modal', incidentDetailModalHideListener);

      modal.show();
    }
  };

  const incidentEditModalListener = (e: PopStateEvent) => {
    if (incidentEditModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(incidentEditModalRef.current);
      modal.hide();
    }
  };

  const incidentEditModalShowListener = (e: any) => {
    window.history.pushState('#incident-edit', '', document.location+'#incident-edit');
    window.addEventListener('popstate', incidentEditModalListener, false);

    if (incidentEditModalRef.current != null) {
      incidentEditModalRef.current.removeEventListener('shown.bs.modal', incidentEditModalShowListener);
    }
  };

  const incidentEditModalHideListener = (e: any) => {
    if (incidentEditModalRef.current != null) {
      incidentEditModalRef.current.removeEventListener('hidden.bs.modal', incidentEditModalHideListener);

      const modal = Modal.getOrCreateInstance(incidentEditModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#incident-edit') {
      window.history.back();
    }

    if (incidentDetailModalRef.current != null) {
      const detailModal = Modal.getOrCreateInstance(incidentDetailModalRef.current);
      detailModal.hide();
    }

    setShowEdit(false);
    dispatch(fetchDashboardAsync());

    window.removeEventListener('popstate', incidentEditModalListener);
  };

  const onClickEdit = () => {
    setShowEdit(true);
  };

  useEffect(() => {
    if (showEdit && incidentEditModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(incidentEditModalRef.current);
      incidentEditModalRef.current.addEventListener('shown.bs.modal', incidentEditModalShowListener);
      incidentEditModalRef.current.addEventListener('hidden.bs.modal', incidentEditModalHideListener);

      modal.show();

      ReactGA.event({
        category: 'Dashboard',
        action: 'dashboard-edit',
        label: 'Dashboard Edit'
      });
    }
  });

  const addCMEModalRef = useRef<HTMLDivElement>(null);
  const [showAddCME, setShowAddCME] = useState<boolean>(false);

  const addCMEModalListener = (e: PopStateEvent) => {
    if (addCMEModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(addCMEModalRef.current);
      modal.hide();
    }
  };

  const addCMEModalShowListener = (e: any) => {
    window.history.pushState('#add-cme', '', '#add-cme');
    window.addEventListener('popstate', addCMEModalListener, false);

    if (addCMEModalRef.current != null) {
      addCMEModalRef.current.removeEventListener('shown.bs.modal', addCMEModalShowListener);
    }
  };

  const addCMEModalHideListener = (e: any) => {
    if (addCMEModalRef.current != null) {
      addCMEModalRef.current.removeEventListener('hidden.bs.modal', addCMEModalHideListener);

      const modal = Modal.getOrCreateInstance(addCMEModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#add-cme') {
      window.history.back();
    }

    setShowAddCME(false);
    window.removeEventListener('popstate', addCMEModalListener);
  };

  const onClickAddCME = () => {
    setShowAddCME(true);
  };

  useEffect(() => {
    if (showAddCME && addCMEModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(addCMEModalRef.current);
      addCMEModalRef.current.addEventListener('hidden.bs.modal', addCMEModalHideListener);
      addCMEModalRef.current.addEventListener('shown.bs.modal', addCMEModalShowListener);

      modal.show();
    }
  });

  const certificationDetailModalRef = useRef<HTMLDivElement>(null);
  const [showCertificationModal, setShowCertificationModal] = useState<boolean>(false);

  const certDetailModalListener = (e: PopStateEvent) => {
    if (certificationDetailModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(certificationDetailModalRef.current);
      modal.hide();
    }
  };

  const certDetailModalShowListener = (e: any) => {
    window.history.pushState('#cert-detail', '', '#cert-detail');
    window.addEventListener('popstate', certDetailModalListener, false);

    if (certificationDetailModalRef.current != null) {
      certificationDetailModalRef.current.removeEventListener('shown.bs.modal', certDetailModalShowListener);
    }
  };

  const certDetailModalHideListener = (e: any) => {
    if (certificationDetailModalRef.current != null) {
      certificationDetailModalRef.current.removeEventListener('hidden.bs.modal', certDetailModalHideListener);

      const modal = Modal.getOrCreateInstance(certificationDetailModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#cert-detail') {
      window.history.back();
    }

    setSelectedCertification(undefined);
    setShowCertificationModal(false);
    window.removeEventListener('popstate', certDetailModalListener);
  };

  const onClickCertification = () => {
    setShowCertificationModal(true);
  }

  useEffect(() => {
    if (showCertificationModal && certificationDetailModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(certificationDetailModalRef.current);
      certificationDetailModalRef.current.addEventListener('hidden.bs.modal', certDetailModalHideListener);
      certificationDetailModalRef.current.addEventListener('shown.bs.modal', certDetailModalShowListener);

      modal.show();
    }
  });

  const onClickAddPhoto = () => {

  };

  const onClickDelete = () => {
    if (deleteModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteModalRef.current);
      modal.show();
    }
  };

  const onDeleteIncident = async (result: boolean) => {
    if (deleteModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteModalRef.current);
      modal.hide();
    }

    if (result && selectedIncident != null) {
      const res = await dispatch(deleteIncidentAsync(selectedIncident.id)).unwrap();
      if (isSuccess(res)) {
        setSelectedIncident(undefined);

        if (incidents.length > 0) {
          setSelectedIncident(incidents[0]);
          if (!isMobile) {
            const newElement = document.getElementById('incident-'+incidents[0].id);
            if (newElement != null) {
              newElement.classList.add('list-item-selected');
            }
          }
        }
      }

      if (incidentDetailModalRef.current != null) {
        const modal = Modal.getOrCreateInstance(incidentDetailModalRef.current);
        modal.hide();
      }
    }
  };

  let count = 0;
  if (isEditMode) {
    count = 1;
  }

  let row = 1;

  let rows: Map<number, DashboardItem[]> = new Map();
  items.forEach((item) => {
    if (count >= 3) {
      row++;
      count = 0;
    }

    if (!rows.has(row)) {
      rows.set(row, []);
    }

    let arr = rows.get(row);
    if (arr != null) {
      rows.set(row, [...arr, item]);
    }

    count++;
  });
  
  const didClickEdit = () => {
    setEditMode(!isEditMode);
  };

  const onClickDeleteCard = (item: DashboardItem) => {
    setCardDelete(item);
  };

  const deleteModalHideListener = (e: any) => {
    setCardDelete(undefined);
  }

  useEffect(() => {
    if (cardDelete != null && deleteCardModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteCardModalRef.current);
      deleteCardModalRef.current.addEventListener('hidden.bs.modal', deleteModalHideListener)
      modal.show();
    }
  });

  const getDeleteMessage = (): string => {
    if (cardDelete == null) {
      return '';
    }

    if (cardDelete.card.template.data_type !== DashboardDataType.Leaderboard) {
      return 'Are you sure you want to delete the card?';
    } else {
      const data = cardDelete.card.extra as DashboardLeadboardData;
      if (data.owner_id !== user?.id) {
        return 'Are you sure want to leave this leaderboard?';
      } else {
        return 'Are you sure you want to delete this leaderboard?';
      }
    }
  };

  const onDeleteCard = async (result: boolean) => {
    if (deleteCardModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteCardModalRef.current);
      modal.hide();
    }

    if (cardDelete != null && result) {
      ReactGA.event({
        category: 'Dashboard',
        action: 'dashboard-card-delete',
        label: 'Delete Dashboard Card'
      });

      await dispatch(deleteDashboardCardAsync(cardDelete.card.id));
      setCardDelete(undefined);
    }
  };

  const onClickAdd = () => {
    if (templatesModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(templatesModalRef.current);
      modal.show();
    }
  };

  const onClickSortUp = (item: DashboardItem) => {
    const index = items.indexOf(item);
    const mod = [...items];

    let arrItem = mod.splice(index, 1);    
    mod.splice(index - 1, 0, arrItem[0]);

    let updatedList: DashboardItem[] = [];
    mod.forEach((newItem, index) => {
      let item = _.cloneDeep(newItem);
      item.card.sort_order = index;

      updatedList.push(item);
    });

    ReactGA.event({
      category: 'Dashboard',
      action: 'dashboard-card-sort',
      label: 'Dashboard Card Sort'
    });
    dispatch(updateDashboardCardsSortAsync(updatedList));
  };

  const onClickSortDown = (item: DashboardItem) => {
    const index = items.indexOf(item);
    const mod = [...items];

    let arrItem = mod.splice(index, 1);
    mod.splice(index + 1, 0, arrItem[0]);

    let updatedList: DashboardItem[] = [];
    mod.forEach((newItem, index) => {
      let item = _.cloneDeep(newItem);
      item.card.sort_order = index;

      updatedList.push(item);
    });

    ReactGA.event({
      category: 'Dashboard',
      action: 'dashboard-card-sort',
      label: 'Dashboard Card Sort'
    });
    dispatch(updateDashboardCardsSortAsync(updatedList));
  };

  const onClickPromoToast = () => {
    navigate('/settings', { replace: false });
  };

  const onClickInvite = (leaderboardId: number) => {
    setSelectedLeaderboardId(leaderboardId);
  };

  const leaderboardInviteModalHideListener = (e: any) => {
    setSelectedLeaderboardId(undefined);
  }

  useEffect(() => {
    if (selectedLeaderboardId != null && leaderboardInviteModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(leaderboardInviteModalRef.current);
      leaderboardInviteModalRef.current.addEventListener('hidden.bs.modal', leaderboardInviteModalHideListener);
      modal.show();
    }
  });

  useEffect(() => {
    const cookies = new Cookies();    

    if (cookies.get('saw_promo_toast') !== 'true') {
      const toastElement = document.getElementById('promoCodeToast');
      if (toastElement != null) {
        const toast = new Toast(toastElement);
        toast.show();

        cookies.set('saw_promo_toast', 'true', { path: '/', expires: moment().add(1, 'month').toDate(), sameSite: 'strict' });
      }
    }
  }, []);

  useEffect(() => {
    document.title = 'ResponderLog - Dashboard';
  }, []);

  return (
    <div>
      <Navbar name='dashboard' showNav={true} showEdit={true} editMode={isEditMode} onClickEdit={didClickEdit} />
      <Tabbar name='dashboard' />
      <div className={(isMobile ? 'container mt-4 pb-4' : 'container pb-4')}>
      {[...rows].map(([row, cards]) =>
        <div key={'row-'+row} className={(isMobile ? 'row d-flex' : 'row d-flex mt-4')}>
          {(row === 1 && isEditMode) &&
          <div key='edit' className={isMobile ? 'col-lg-4' : 'col-lg-4 margin-top-sm'}>
            <div className='card h-100'>
              <div className={(isMobile) ? 'card-body m-0 p-4 text-center' : 'card-body m-0 p-0 d-flex align-items-center text-center'}>
                <button className='btn w-100 h-100' onClick={(e) => { onClickAdd(); }}><FontAwesomeIcon icon={faCirclePlus} size='10x' color='#F1F1F1' /></button>
              </div>
            </div>
          </div>
          }
          {cards.map((item) =>
          <div key={item.card.id} className={(item.card.sort_order === 0 && !isEditMode) ? 'col-lg-4' : 'col-lg-4 margin-top-sm'}>
          <div className='card h-100'>
            <div className='card-header d-flex w-100'>
              <span className='align-self-center flex-fill'>{item.card.name}</span>
              {item.card.template.data_type === DashboardDataType.Leaderboard &&
              <span className='ms-auto badge text-bg-light p-2'>Leaderboard</span>
              }
              {item.card.template.data_type !== DashboardDataType.Leaderboard &&
              <span className='ms-auto badge text-bg-light p-2'>{labelForDashboardTimePeriod(item.card.time_period)}</span>
              }
            </div>
            {item.card.template.style === DashboardStyle.Numeric &&
            <div className='card-body d-flex align-items-center'>
              <p className='dashboard-item-value text-center w-100'>{item.value}</p>
            </div>
            }
            {item.card.template.style === DashboardStyle.List &&
            <div className='card-body m-0 p-0'>
              {item.card.template.data_type === DashboardDataType.Incidents &&
              <IncidentsList incidents={item.value} onClickIncident={onClickIncident} />
              }
              {item.card.template.data_type === DashboardDataType.Leaderboard &&
              <LeaderboardList leaderboardId={(item.card.extra as DashboardLeadboardData).leaderboard_id} members={item.value as LeaderboardMember[]} isOwner={(item.card.extra as DashboardLeadboardData).owner_id === user?.id} onClickInvite={onClickInvite}  />
              }
              {item.card.template.data_type === DashboardDataType.Custom &&
              <DashboardCountList counts={item.value} />
              }
            </div>
            }
            {item.card.template.data_type === DashboardDataType.CME &&
            <div className='card-body'>
              <div className='d-flex align-items-center'>
                <div className='p-3 text-center'>
                  <span className='cme-summary-count'>{(item.value as CMEProgressReport).total_hours}</span>
                  <br />
                  <span className='cme-summary-label'>Required Hours</span>
                </div>
                <div className='p-3 text-center'>
                  <span className='cme-summary-count'>{(item.value as CMEProgressReport).outstanding_hours}</span>
                  <br />
                  <span className='cme-summary-label'>Remaining Hours</span>
                </div>
                <div className='p-3 text-center'>
                  <span className='cme-summary-count'>{(item.value as CMEProgressReport).completed_hours}</span>
                  <br />
                  <span className='cme-summary-label'>Completed Hours</span>
                </div>
              </div>
              <p className='certification-expires text-center'>Renew by {moment((item.value as CMEProgressReport).expiration_date).format('MMM DD, yyyy')}</p>
              <div className='d-flex justify-content-center'>
                <button type='button' className='btn btn-sm btn-outline-primary w-50' onClick={(e) => { onClickAddCME(); }}>Add CME</button>
                <button type='button' className='btn btn-sm btn-outline-primary ms-3 w-50' onClick={(e) => { setSelectedCertification((item.value as CMEProgressReport).certification); onClickCertification(); }}>See Details</button>
              </div>
            </div>
            }
            {item.card.template.style === DashboardStyle.BarGraph &&
            <div className='card-body m-0 p-0'>
              {item.value.length === 0 &&
              <li className='list-group-item list-item flex-column mx-auto empty-list text-center'>
                <p className='text-muted mt-3'>No incidents</p>
              </li>
              }
              {item.value.length > 0 &&
              <ResponsiveContainer width='100%' aspect={1}>
                <BarChart
                  width={400}
                  height={300}
                  data={item.value}
                  margin={{
                    top: 16,
                    right: 16,
                    left: -16,
                    bottom: 16,
                  }}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='name' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey='count' fill='#CE2029' isAnimationActive={false} />
                </BarChart>
              </ResponsiveContainer>
              }
            </div>
            }
            {item.card.template.style === DashboardStyle.PieChart &&
            <div className='card-body m-0 p-0'>
              {item.value.length === 0 &&
              <li className='list-group-item list-item flex-column mx-auto empty-list text-center'>
                <p className='text-muted mt-3'>No incidents</p>
              </li>
              }
              {item.value.length > 0 &&
              <ResponsiveContainer width='100%' aspect={1}>
                <PieChart width={400} height={200}>
                  <Pie data={item.value} dataKey='count' nameKey='name' isAnimationActive={false} label>
                    {item.value.map((_: any, index: any) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              }
            </div>
            }
            {item.card.template.style === DashboardStyle.LineGraph &&
            <div className='card-body m-0 p-0'>
              <ResponsiveContainer width='100%' aspect={1}>
                <LineChart width={400} height={300} data={item.value} margin={{
                  top: 16,
                  right: 16,
                  left: -30,
                  bottom: 16,
                }}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='name' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type='monotone' dataKey='count' stroke='#CE2029' />
                </LineChart>
              </ResponsiveContainer>
            </div>
            }
            {isEditMode &&
            <div className='card-footer d-flex w-100'>
              <div className='flex-grow-1'>
                {(item.card.sort_order !== 0) &&
                <button className='btn p-0 me-2 align' onClick={(e) => { onClickSortUp(item); }}><FontAwesomeIcon icon={faCircleUp} size='1x' color='#6C757D' /></button>
                }
                {(item.card.sort_order < items.length - 1) &&
                <button className='btn p-0' onClick={(e) => { onClickSortDown(item); }}><FontAwesomeIcon icon={faCircleDown} size='1x' color='#6C757D' /></button>
                }
              </div>
              <div>
                {!item.card.template.is_system &&
                <button className='btn'><FontAwesomeIcon icon={faEdit} size='1x' color='#6C757D' /></button>
                }
                <button className='btn p-0' onClick={(e) => { onClickDeleteCard(item); }}><FontAwesomeIcon icon={faTrash} size='1x' color='#6C757D' /></button>
              </div>
            </div>
            }
          </div>
          </div>
          )}
        </div>
      )}
      </div>
      <Footer />
      <AddAgencyModal modalRef={agencyModalRef} supportClose={false} />
      <IncidentDetailModal incident={selectedIncident} modalRef={incidentDetailModalRef} onClickDelete={onClickDelete} onClickEdit={onClickEdit} onClickAddPhoto={onClickAddPhoto} />
      {(showEdit && selectedIncident != null) &&
      <EditIncidentModal incident={selectedIncident} modalRef={incidentEditModalRef} currentPage={1} />
      }
      <ConfirmationModal modalRef={deleteModalRef} message='Are you sure you want to delete the incident?' onActionTaken={onDeleteIncident} />
      <DashboardTemplatesModal modalRef={templatesModalRef} customCardModalRef={customCardModalRef} leaderboardModalRef={leaderboardModalRef} cmeModalRef={cmeModalRef} />
      <DashboardCustomCardModal modalRef={customCardModalRef} />
      {cardDelete != null &&
      <ConfirmationModal modalRef={deleteCardModalRef} message={getDeleteMessage()} onActionTaken={onDeleteCard} />
      }
      <CreateCMEDashboardCardModal modalRef={cmeModalRef} />
      <CreateLeaderboardModal modalRef={leaderboardModalRef} />
      {selectedLeaderboardId != null &&
      <LeaderboardInviteModal modalRef={leaderboardInviteModalRef} leaderboardId={selectedLeaderboardId} />
      }
      {showAddCME &&
      <AddCMESessionModal modalRef={addCMEModalRef} />
      }
      {showCertificationModal &&
      <CertificationDetailModal certification={selectedCertification} showFooter={false} modalRef={certificationDetailModalRef} onClickDelete={onClickDelete} />
      }
      <MessageToast id={'promoCodeToast'} message={'Get 1 month free for every friend who signs up for a yearly subscription. Click here to get your promo code under settings.'} onClick={onClickPromoToast} />
    </div>
  );
};

export default DashboardPage;
