import { FC } from 'react';
import moment from 'moment';
import { CMECategory, CMEParticipantCredit } from '../certifications/CertificationService';

interface Props {
  cme: CMEParticipantCredit;
  categories: CMECategory[];
}

const CMEDetailView: FC<Props> = ({cme, categories}) => {
  return (
    <div>
      {cme?.preview_url != null &&
      <div>
        <img src={cme.preview_url} alt={cme.title} className='w-100' />
      </div>
      }
      {cme?.cme_category_id != null &&
      <div className='mt-2'>
        <label className='form-label mb-0'>Category</label>
        <p className=''>{categories.find((category) => category.id === cme.cme_category_id)?.title}</p>
      </div>
      }
      {cme?.num_minutes != null &&
      <div className='mt-2'>
        <label className='form-label mb-0'>Hours</label>
        <p className=''>{cme.num_minutes / 60}</p>
      </div>
      }
      {cme?.cme_provider != null &&
      <div className='mt-2'>
        <label className='form-label mb-0'>Provider</label>
        <p className=''>{cme.cme_provider}</p>
      </div>
      }
      {cme?.completion_date != null &&
      <div className='mt-2'>
        <label className='form-label mb-0'>Completed On</label>
        <p className=''>{moment(cme.completion_date).format('MMMM D, YYYY')}</p>
      </div>
      }
    </div>
  );
};

export default CMEDetailView;
