import React, { FC, forwardRef, RefObject, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { CreateIncidentRequest, CustomFieldInput, Incident, IncidentCustomField } from './IncidentService';
import { selectAgencies } from '../accounts/agencySlice';
import { EditIncidentArgs, editIncidentAsync, fetchIncidentsAsync, selectIncidentCustomFields } from './incidentsSlice';
import { clearButton, clearError, showError } from '../common/alerts';
import { isSuccess } from '../common/types';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  incident: Incident;
  currentPage: number;
}

const EditIncidentModal: FC<Props> = ({modalRef, incident, currentPage}: Props) => {
  const dispatch = useAppDispatch();
  const alertRef = useRef<HTMLDivElement>(null);

  const editButtonRef = useRef<HTMLButtonElement>(null);

  const agencies = useAppSelector(selectAgencies);
  const customFields = useAppSelector(selectIncidentCustomFields).filter((field) => field.is_hidden === false);

  const [selectedAgency, setSelectedAgency] = useState<string>(incident.agency_id.toString());
  const handleAgency = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedAgency(event.target.value);

  const [dispatched, setDispatched] = useState<string>(moment(incident.dispatched_at).format('YYYY-MM-DDTHH:mm'));
  const handleDispatched = (date: Date | null) => setDispatched(date != null ? moment(date).format('YYYY-MM-DDTHH:mm') : '');

  const [runNumber, setRunNumber] = useState<string>(incident.run_number);
  const handleRunNumber = (event: React.ChangeEvent<HTMLInputElement>) => setRunNumber(event.target.value);

  const [location, setLocation] = useState<string>(incident.location);
  const handleLocation = (event: React.ChangeEvent<HTMLInputElement>) => setLocation(event.target.value);

  const [summary, setSummary] = useState<string>(incident.summary);
  const handleSummary = (event: React.ChangeEvent<HTMLInputElement>) => setSummary(event.target.value);

  const [mileage, setMileage] = useState<string>(incident.mileage);
  const handleMileage = (event: React.ChangeEvent<HTMLInputElement>) => setMileage(event.target.value);

  const [role, setRole] = useState<string>(incident.role);
  const handleRole = (event: React.ChangeEvent<HTMLInputElement>) => setRole(event.target.value);

  const [comments, setComments] = useState<string>(incident.comments);
  const handleComments = (event: React.ChangeEvent<HTMLTextAreaElement>) => setComments(event.target.value);

  const incidentValues = incident.custom_fields.map((field) => {
    return {
      id: field.id,
      value: field.value
    } as CustomFieldInput;
  });

  const [customFieldValues, setCustomFieldValues] = useState<CustomFieldInput[]>(incidentValues);
  const handleCustomField = (field: IncidentCustomField, target: HTMLInputElement) => {
    let id = target.id.split('-')[1];
    let radioValue: string | undefined = undefined;

    if (target.type === 'radio') {
      radioValue = target.id.split('-')[2];
    }

    console.log(radioValue);

    const item = customFieldValues.find((field) => {
      return field.id === parseInt(id);
    });

    if (item != null) {
      setCustomFieldValues(customFieldValues.map((field) => {
        if (field.id !== parseInt(id)) {
          return field;
        } else {
          let value = target.value;
          if (target.type === 'radio') {
            value = radioValue === 'yes' ? 'true' : 'false';
          }

          const updatedField: CustomFieldInput = {
            id: parseInt(id),
            value: value.trim()
          };
          return updatedField;
        }
      }));
    } else {
      let value = target.value;
      if (target.type === 'radio') {
        value = radioValue === 'yes' ? 'true' : 'false';
      }

      setCustomFieldValues([...customFieldValues, {
        id: parseInt(id),
        value: value.trim()
      }]);
    }
  };

  const handleCustomFieldDate = (field: IncidentCustomField, date: Date | null) => {
    const item = customFieldValues.find((f) => {
      return f.id === field.id;
    });

    if (item != null && date == null) {
      customFieldValues.splice(customFieldValues.indexOf(item), 1);
      setCustomFieldValues([...customFieldValues]);
      return;
    }

    if (item != null) {
      setCustomFieldValues(customFieldValues.map((f) => {
        if (f.id !== field.id) {
          return f;
        } else {
          const updatedField: CustomFieldInput = {
            id: field.id,
            value: moment(date).format('YYYY-MM-DDTHH:mm')
          };

          return updatedField;
        }
      }));
    } else {
      setCustomFieldValues([...customFieldValues, {
        id: field.id,
        value: moment(date).format('YYYY-MM-DDTHH:mm')
      }]);
    }
  };

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (editButtonRef.current != null) {
      editButtonRef.current.disabled = true;
      editButtonRef.current.innerText = 'Saving...';
    }

    if (selectedAgency == null || selectedAgency === '') {
      showError(alertRef, 'Department is required');
      clearButton(editButtonRef, 'Save');
      return;
    }

    if (dispatched == null || dispatched === '') {
      showError(alertRef, 'Dispatched time is required');
      clearButton(editButtonRef, 'Save');
      return;
    }

    if (mileage != null && isNaN(parseFloat(mileage))) {
      showError(alertRef, 'Mileage must be a numerical value');
      clearButton(editButtonRef, 'Save');
      return;
    }
    
    const req: CreateIncidentRequest = {
      agency_id: parseInt(selectedAgency),
      dispatch_time: dispatched,
      run_number: runNumber != null ? runNumber.trim() : '',
      summary: summary != null ? summary.trim() : '',
      location: location != null ? location.trim() : '',
      comments: comments != null ? comments.trim() : '',
      role: role != null ? role.trim() : '',
      mileage: mileage != null ? parseFloat(mileage) : 0,
      custom_fields: customFieldValues
    };

    const args: EditIncidentArgs = {
      id: incident.id,
      req: req
    };

    const res = await dispatch(editIncidentAsync(args)).unwrap();
    if (isSuccess(res)) {
      clearButton(editButtonRef, 'Save');
      window.history.back();

      dispatch(fetchIncidentsAsync(currentPage));
    } else {
      showError(alertRef, res.message);
      clearButton(editButtonRef, 'Save');
    }
  };

  useEffect(() => {
    setSelectedAgency(incident.agency_id.toString());
    setDispatched(moment(incident.dispatched_at).format('YYYY-MM-DDTHH:mm'));
    setRunNumber(incident.run_number);
    setSummary(incident.summary);
    setLocation(incident.location);
    setComments(incident.comments);
    setRole(incident.role);
    setMileage(incident.mileage);

    incident.custom_fields.forEach((field) => {
      if (field.value === 'true') {
        const element = document.getElementById('custom-'+field.id+'-yes') as HTMLInputElement;
        if (element != null) {
          element.checked = true;
        }
      }

      if (field.value === 'false') {
        const element = document.getElementById('custom-'+field.id+'-no') as HTMLInputElement;
        if (element != null) {
          element.checked = true;
        }
      }
    });
  }, [incident]);

  const DateButton = forwardRef(({value, onClick}: any, ref) => (
    <button type='button' className='form-control mt-0 text-start' onClick={onClick}>{value}</button>
  ));

  const onClose = () => {
    window.history.back();
  };

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-3} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-lg modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Incident</h5>
              <button id='close-btn' type='button' className='btn-close' onClick={(e) => { onClose(); e.preventDefault(); }} aria-label='Close'></button>
            </div>
            <div className='modal-body modal-mobile-scroll'>
              <div>
                <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
                <div>
                  <div className='row'>
                    <div className='col-lg'>
                      <div>
                        <label className='form-label mb-0' htmlFor='name'>Department</label>
                        <select className='form-select' id='agency' name='agency' value={selectedAgency} onChange={(e) => { handleAgency(e); }}>
                          <option key={-1} value=''>Select department</option>
                          {agencies.map((agency) =>
                          <option key={agency.id} value={agency.id}>{agency.name}</option>
                          )}
                        </select>
                      </div>
                      <div className='mt-2'>
                        <label className='form-label mb-0' htmlFor='dispatched'>Dispatched</label>
                        <DatePicker selected={new Date(dispatched)} dateFormat='MM/dd/yyyy HH:mm' className='form-control mt-0' id='dispatched' showTimeSelect timeFormat="HH:mm" timeIntervals={1} customInput={<DateButton />} onChange={(date) => { handleDispatched(date); }} />
                      </div>
                      <div className='mt-2'>
                        <label className='form-label mb-0' htmlFor='run-number'>Run Number</label>
                        <input type='text' className='form-control mt-0' id='run-number' value={runNumber} onChange={(e) => { handleRunNumber(e); }} />
                      </div>
                      <div className='mt-2'>
                        <label className='form-label mb-0' htmlFor='location'>Location</label>
                        <input type='text' className='form-control mt-0' id='location' value={location} onChange={(e) => { handleLocation(e); }} />
                      </div>
                      <div className='mt-2'>
                        <label className='form-label mb-0' htmlFor='summary'>Incident</label>
                        <input type='text' className='form-control mt-0' id='summary' value={summary} onChange={(e) => { handleSummary(e); }} placeholder='Fire Alarm, Structure Fire, EMS, MVA' />
                      </div>
                      <div className='mt-2'>
                        <label className='form-label mb-0' htmlFor='mileage'>Mileage</label>
                        <input type='text' className='form-control mt-0' id='mileage' value={mileage} onChange={(e) => { handleMileage(e); }} />
                      </div>
                    </div>
                    <div className='col-lg'>
                      <div>
                        <label className='form-label mb-0' htmlFor='role'>Role</label>
                        <input type='text' className='form-control mt-0' id='role' value={role} onChange={(e) => { handleRole(e); }} />
                      </div>
                      {customFields.map((field) =>
                      <div key={field.id} className='mt-2'>
                        <div>
                          <label className='form-label mb-0' htmlFor={field.name}>{field.name}</label>
                        </div>
                        {field.input_type === 'text' &&
                        <input type='text' className='form-control mt-0' name={'custom-'+field.id} value={customFieldValues.find((f) => field.id === f.id)?.value} id={'custom-'+field.id} onChange={(e) => { handleCustomField(field, e.target); }} />
                        }
                        {field.input_type === 'check' &&
                        <div className='btn-group' role='group'>
                          <input type='radio' className='btn-check' name={'custom-'+field.id} id={'custom-'+field.id+'-yes'} onChange={(e) => { handleCustomField(field, e.target); }}  />
                          <label className='btn btn-outline-primary' htmlFor={'custom-'+field.id+'-yes'}>Yes</label>

                          <input type='radio' className='btn-check' name={'custom-'+field.id} id={'custom-'+field.id+'-no'} onChange={(e) => { handleCustomField(field, e.target); }} />
                          <label className='btn btn-outline-primary' htmlFor={'custom-'+field.id+'-no'}>No</label>
                        </div>
                        }
                        {field.input_type === 'datetime' &&
                        <DatePicker selected={customFieldValues.find((f) => field.id === f.id) != null && customFieldValues.find((f) => field.id === f.id)?.value !== '' ? new Date(moment(customFieldValues.find((f) => field.id === f.id)?.value).format('YYYY-MM-DDTHH:mm')) : null} isClearable dateFormat='MM/dd/yyyy HH:mm' className='form-control mt-0' id={'custom-'+field.id} customInput={<DateButton />} showPopperArrow={false} showTimeSelect timeFormat="HH:mm" timeIntervals={1} onChange={(date) => { handleCustomFieldDate(field, date); }} />
                        }
                      </div>
                      )}
                      <div className='mt-2'>
                        <label className='form-label mb-0' htmlFor='comments'>Comments</label>
                        <textarea className='form-control mt-0' rows={4} id='comments' value={comments} onChange={(e) => { handleComments(e); }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button ref={editButtonRef} id='edit-incident-btn' type='submit' className='btn btn-primary w-25'>Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditIncidentModal;
