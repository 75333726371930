import { Modal } from 'bootstrap';
import { FC, RefObject } from 'react';
import IncidentDetailView from './IncidentDetailView';
import IncidentPhotosCard from './IncidentPhotosCard';
import { Incident } from './IncidentService';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  incident: Incident | undefined;
  onClickDelete: () => void;
  onClickEdit: () => void;
  onClickAddPhoto: () => void;
}

const IncidentDetailModal: FC<Props> = ({modalRef, incident, onClickDelete, onClickEdit, onClickAddPhoto}: Props) => {
  const onClose = () => {    
    if (modalRef.current != null) {
      const modal = Modal.getOrCreateInstance(modalRef.current);
      modal.hide();
    }
  };

  return (
    <div ref={modalRef} className='modal' tabIndex={-3} data-bs-backdrop='static' data-bs-keyboard='false'>
      <div className='modal-dialog modal-lg modal-fullscreen-lg-down'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Incident Detail</h5>
            <button type='button' className='btn-close' onClick={(e) => { onClose(); e.preventDefault(); }} aria-label='close'></button>
          </div>
          <div className='modal-body'>
            <IncidentDetailView incident={incident} />
            {incident != null &&
            <IncidentPhotosCard incidentId={incident.id} onClickAddPhoto={onClickAddPhoto} />
            }
          </div>
          <div className='modal-footer modal-bottom'>
            <button id='edit-incident-btn' type='button' className='btn btn-primary w-25' onClick={(e) => { onClickEdit(); }}>Edit</button>
            <button id='delete-incident-btn' type='button' className='btn btn-primary w-25' onClick={(e) => { onClickDelete(); }}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentDetailModal;
