import { FC } from 'react';

interface MessageToastProps {
  id: string;
  message: string;
  onClick?: () => void;
}

const MessageToast: FC<MessageToastProps> = ({id, message, onClick}) => {
  return (
    <div className='toast-container position-fixed bottom bottom-0 end-0 p-3'>
      <div id={id} className='toast text-bg-dark border-0 align-items-center' data-bs-autohide='false' role='alert' aria-live='assertive' aria-atomic='true'>
        <div className='d-flex'>
          <div className='toast-body' onClick={onClick}>
            {message}
          </div>
          <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>
  )
};

export default MessageToast;
