import { FC } from 'react';
import { TrainingSession } from './TrainingService';

interface Props {
  session: TrainingSession;
  onClickSession: (session: TrainingSession) => void;
}

const TrainingSessionListItem: FC<Props> = ({session, onClickSession}: Props) => {
  return (
    <li id={'training-'+session.id} className='list-group-item list-item click-pointer' onClick={(e) => { onClickSession(session); }}>
      <span className='certification-name'>{session.name}</span>
      <div className='certification-issued'>
        <span className='certification-issued'>{session.training_type}</span>
      </div>
    </li>
  );
};

export default TrainingSessionListItem;
