import { FC } from 'react';
import IncidentListItem from './IncidentListItem';
import { Incident } from './IncidentService';

interface Props {
  incidents: Incident[];
  onClickIncident: (incident: Incident) => void;
}

const IncidentsList: FC<Props> = ({incidents, onClickIncident}: Props) => {
  return (
    <div className='container-fluid m-0 p-0'>
      <div className='m-0 p-0'>
        <div className='list-group list-group-flush m-0 p-0'>
          {incidents.map((incident) =>
          <IncidentListItem key={incident.id} incident={incident} onClickIncident={onClickIncident} />
          )}
          {incidents.length === 0 &&
          <li className='list-group-item list-item flex-column mx-auto empty-list text-center'>
            <p className='text-muted mt-3'>No incidents</p>
          </li>
          }
        </div>
      </div>
    </div>
  );
};

export default IncidentsList;
