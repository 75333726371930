import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';

import { ErrorResponse, Result } from '../common/types';

export interface Certification {
  id: number;
  user_id: number;
  name: string;
  url: string;
  preview_url: string;
  issued: string;
  expires: string;
  is_cme_based: boolean;
};

export interface UploadCertificationRequest {
  name: string;
  file: File;
  file_type: string;
  issued?: string;
  expires?: string;
  cme_program_id?: string;
}

export interface CertificationsResponse {
  certifications: Certification[];
  count: number;
}

export interface CertificationLevel {
  id: number;
  state: string;
  title: string;
  renewal_interval: number;
}

export interface CMEProgram {
  id: number;
  certification_level: CertificationLevel;
  program_url: string;
}

export interface CMECategory {
  id: number;
  cme_program_id: number;
  parent_category_id?: number;
  title: string;
  num_minutes: number;
}

export interface CMEParticipantCredit {
  id: number;
  cme_participant_id: number;
  cme_category_id: number;
  cme_provider: string;
  title: string;
  document_key: string;
  file_type: string;
  num_minutes: number;
  completion_date: string;
  document_url: string;
  preview_url: string;
}

export interface CMEProgressReport {
  participant_id: number;
  certification: Certification;
  program: CMEProgram;
  categories: CMECategory[];
  credits: CMEParticipantCredit[];
  total_hours: number;
  completed_hours: number;
  outstanding_hours: number;
  expiration_date: string;
}

export interface NewCMERequest {
  name: string;
  file: File;
  file_type: string;
  cme_participant_id: string;
  cme_category_id: string;
  cme_provider: string;
  num_hours: string;
  occurred_on: string;
}

export interface AddCMEDashboardCardRequest {
  cme_participant_id: number;
}

export interface DeleteCMERequest {
  cme_credit_id: number;
}

export class CertificationService {
  async fetch(offset: number): Promise<Result<CertificationsResponse>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/certifications?offset=${offset}`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        const certifications = res.data as CertificationsResponse;
        return certifications;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async upload(req: UploadCertificationRequest): Promise<Result<Certification>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const data = new FormData();
      data.append('certificate', req.file);
      data.append('name', req.name);
      data.append('file_type', req.file_type);

      if (req.issued != null) {
        data.append('issued', req.issued);
      }

      if (req.expires != null) {
        data.append('expires', req.expires);
      }

      if (req.cme_program_id != null) {
        data.append('cme_program_id', req.cme_program_id);
      }

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/certifications`, data, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        const certification = res.data as Certification;
        return certification;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async delete(certificationId: number): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/certifications/${certificationId}/delete`, null, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async getCMEPrograms(): Promise<Result<CMEProgram[]>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/cme/programs`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return res.data as CMEProgram[];
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async getCMEProgressReports(): Promise<Result<CMEProgressReport[]>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/cme/progress`, {
        headers: {
          Authorization: token
        }
      });
      
      if (res.status === 200) {
        return res.data as CMEProgressReport[];
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async addCME(req: NewCMERequest): Promise<Result<CMEParticipantCredit>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const data = new FormData();
      data.append('certificate', req.file);
      data.append('file_type', req.file_type);
      data.append('name', req.name);
      data.append('cme_participant_id', req.cme_participant_id);
      data.append('cme_category_id', req.cme_category_id);
      data.append('cme_provider', req.cme_provider);
      data.append('num_hours', req.num_hours);
      data.append('occurred_on', req.occurred_on);

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/cme/session`, data, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        return res.data as CMEParticipantCredit;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async addCMEDashboardCard(cme_participant_id: number): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const req: AddCMEDashboardCardRequest = {
        cme_participant_id: cme_participant_id
      };

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/cme/dashboard`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async deleteCMECredit(id: number): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const req: DeleteCMERequest = {
        cme_credit_id: id
      };

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/cme/delete`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }
}
