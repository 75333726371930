import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import states from 'states-us';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';

import Footer from '../nav/Footer';
import Navbar from '../nav/Navbar';

import { AccountRequest } from './AccountService';
import { clearButton, clearError, showError } from '../common/alerts';
import { useAppDispatch } from '../app/hooks';
import { createAccountAsync } from './accountSlice';
import { isSuccess } from '../common/types';

const CreateAccountPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const alertRef = useRef<HTMLDivElement>(null);
  const createButtonRef = useRef<HTMLButtonElement>(null);

  const [email, setEmail] = useState<string>();
  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

  const [password, setPassword] = useState<string>();
  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  const [passwordConfirmation, setPasswordConfirmation] = useState<string>();
  const handlePasswordConfirmation = (event: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirmation(event.target.value);

  const [firstName, setFirstName] = useState<string>();
  const handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value);

  const [lastName, setLastName] = useState<string>();
  const handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => setLastName(event.target.value);

  const [address, setAddress] = useState<string>();
  const handleAddress = (event: React.ChangeEvent<HTMLInputElement>) => setAddress(event.target.value);

  const [addressTwo, setAddressTwo] = useState<string>();
  const handleAddressTwo = (event: React.ChangeEvent<HTMLInputElement>) => setAddressTwo(event.target.value);

  const [city, setCity] = useState<string>();
  const handleCity = (event: React.ChangeEvent<HTMLInputElement>) => setCity(event.target.value);

  const [state, setState] = useState<string>();
  const handleState = (event: React.ChangeEvent<HTMLSelectElement>) => setState(event.target.value);

  const [postalCode, setPostalCode] = useState<string>();
  const handlePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => setPostalCode(event.target.value);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    clearError(alertRef);

    if (createButtonRef.current != null) {
      createButtonRef.current.disabled = true;
      createButtonRef.current.innerText = 'Creating...';
    }

    if (email === undefined || email === '') {
      showError(alertRef, 'Email is required');
      clearButton(createButtonRef, 'Create Account');
      return;
    }

    if (password === undefined || password === '') {
      showError(alertRef, 'Password is required');
      clearButton(createButtonRef, 'Create Account');
      return;
    }

    if (passwordConfirmation === undefined || passwordConfirmation === '') {
      showError(alertRef, 'Please confirm your password');
      clearButton(createButtonRef, 'Create Account');
      return;
    }

    if (password !== passwordConfirmation) {
      showError(alertRef, 'Your passwords do not match');
      clearButton(createButtonRef, 'Create Account');
      return;
    }

    if (firstName === undefined || firstName === '') {
      showError(alertRef, 'First name is required');
      clearButton(createButtonRef, 'Create Account');
      return;
    }

    if (lastName === undefined || lastName === '') {
      showError(alertRef, 'Last name is required');
      clearButton(createButtonRef, 'Create Account');
      return;
    }

    if (address === undefined || address === '') {
      showError(alertRef, 'Address is required');
      clearButton(createButtonRef, 'Create Account');
      return;
    }

    if (city === undefined || city === '') {
      showError(alertRef, 'City is required');
      clearButton(createButtonRef, 'Create Account');
      return;
    }

    if (state === undefined || state === '') {
      showError(alertRef, 'State is required');
      clearButton(createButtonRef, 'Create Account');
      return;
    }

    if (postalCode === undefined || postalCode === '') {
      showError(alertRef, 'Postal code is required');
      clearButton(createButtonRef, 'Create Account');
      return;
    }

    const req: AccountRequest = {
      email: email,
      password: password,
      first_name: firstName,
      last_name: lastName,
      address: address,
      address_two: addressTwo,
      city: city,
      state: state,
      postal_code: postalCode
    };

    const result = await dispatch(createAccountAsync(req)).unwrap();
    if (isSuccess(result)) {
      ReactGA.event({
        category: 'Onboarding',
        action: 'account-create',
        label: 'Create Account'
      });

      navigate('/subscriptions/new', { replace: true });
    } else {
      showError(alertRef, result.message);
      clearButton(createButtonRef, 'Create Account');
    }
  };

  useEffect(() => {
    document.title = 'ResponderLog - Create Account';
  }, []);

  return (
    <div>
      <Navbar name='create-account' showNav={false} />
      <form onSubmit={handleSubmit}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 p-3 order-lg-2 text-center'>
              <div className='card mt-4 text-start'>
                <div className='card-header'>ResponderLog Account</div>
                <div className='card-body'>
                  <p className='mt-1 p-0 m-0 benefit'><FontAwesomeIcon className='icon-color' icon={faCaretRight} size='sm' /> Manage your certifications</p>
                  <p className='p-0 m-0'><FontAwesomeIcon className='icon-color' icon={faCaretRight} size='sm' /> Track your training hours</p>
                  <p className='p-0 m-0'><FontAwesomeIcon className='icon-color' icon={faCaretRight} size='sm' /> Keep an incident log</p>
                  <p className='p-0 m-0'><FontAwesomeIcon className='icon-color' icon={faCaretRight} size='sm' /> Create an account and select the 1 month trial</p>
                  <p className='p-0 m-0'><FontAwesomeIcon className='icon-color' icon={faCaretRight} size='sm' /> $2/month or $15/year</p>
                </div>
              </div>
            </div>
            <div className='col-lg-8 p-3 order-lg-1 text-center'>
              <div className='card mt-4 text-start'>
                <div className='card-header'>Account Information</div>
                <div className='card-body'>
                  <div>
                    <label className='form-label mb-0' htmlFor='email'>Email</label>
                    <input type='email' className='form-control mt-0' id='email' placeholder='name@example.com' onChange={(e) => { handleEmail(e); }} />
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='password'>Password</label>
                    <input type='password' className='form-control mt-0' id='password' onChange={(e) => { handlePassword(e); }} />
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='confirm-password'>Confirm Password</label>
                    <input type='password' className='form-control mt-0' id='confirm-password' onChange={(e) => { handlePasswordConfirmation(e); }} />
                  </div>
                </div>
              </div>
              <div className='card mt-4 text-start'>
                <div className='card-header'>Personal Information</div>
                <div className='card-body'>
                  <div>
                    <label className='form-label mb-0' htmlFor='first-name'>First Name</label>
                    <input type='text' className='form-control mt-0' id='first-name' onChange={(e) => { handleFirstName(e); }} />
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='last-name'>Last Name</label>
                    <input type='text' className='form-control mt-0' id='last-name' onChange={(e) => { handleLastName(e); }} />
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='address'>Address</label>
                    <input type='text' className='form-control mt-0' id='address' placeholder='Street address' onChange={(e) => { handleAddress(e); }} />
                    <input type='text' className='form-control mt-2' id='address-two' placeholder='Apt / Suite / Unit' onChange={(e) => { handleAddressTwo(e); }} />
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='city'>City / Town</label>
                    <input type='text' className='form-control mt-0' id='city' onChange={(e) => { handleCity(e); }} />
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='state'>State</label>
                    <select className='form-select mt-0' id='state' onChange={(e) => { handleState(e); }}>
                      <option value='-'>Select state</option>
                      {states.map((state) =>
                        <option value={state.name}>{state.name}</option>
                      )}
                    </select>
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='postal-code'>Postal Code</label>
                    <input type='text' className='form-control mt-0' id='postal-code' placeholder='12345' onChange={(e) => { handlePostalCode(e); }} />
                  </div>
                </div>
              </div>
              <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse text-start mt-4' role='alert'></div>
              <div className='mt-4 mb-4'>
                <button ref={createButtonRef} id='btn-submit' className='btn btn-primary w-50'>Create account</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default CreateAccountPage;
