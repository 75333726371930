import moment from 'moment';
import { FC, forwardRef, RefObject, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { selectAgencies } from '../accounts/agencySlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { clearButton, clearError, showError } from '../common/alerts';
import { isSuccess } from '../common/types';
import { CreateTrainingSessionRequest } from './TrainingService';
import { createTrainingSessionAsync, fetchTrainingSessionsAsync } from './trainingSlice';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  currentPage: number;
}

const AddTrainingSessionModal: FC<Props> = ({modalRef, currentPage}: Props) => {
  const dispatch = useAppDispatch();

  const alertRef = useRef<HTMLDivElement>(null);
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const agencies = useAppSelector(selectAgencies);

  let defaultAgency = '';
  if (agencies.length === 1) {
    defaultAgency = agencies[0].id.toString();
  }

  const [selectedAgency, setSelectedAgency] = useState<string>(defaultAgency);
  const handleAgency = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedAgency(event.target.value);

  const [name, setName] = useState<string>();
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);

  const [selectedTrainingType, setSelectedTrainingType] = useState<string>();
  const handleTrainingType = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedTrainingType(event.target.value);

  const [occurredOn, setOccurredOn] = useState<string>(moment().format('YYYY-MM-DDTHH:mm'));
  const handleOccurredOn = (date: Date | null) => setOccurredOn(date != null ? moment(date).format('YYYY-MM-DDTHH:mm') : '');
  
  const [numHours, setNumHours] = useState<string>();
  const handleNumHours = (event: React.ChangeEvent<HTMLInputElement>) => setNumHours(event.target.value);

  const [comments, setComments] = useState<string>();
  const handleComments = (event: React.ChangeEvent<HTMLTextAreaElement>) => setComments(event.target.value);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (addButtonRef.current != null) {
      addButtonRef.current.disabled = true;
      addButtonRef.current.innerText = 'Adding...';
    }

    if (selectedAgency == null || selectedAgency === '') {
      showError(alertRef, 'Department is required');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (name == null || name === '') {
      showError(alertRef, 'Session name is required');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (numHours != null && isNaN(parseFloat(numHours))) {
      showError(alertRef, 'Invalid valid for duration provided');
      clearButton(addButtonRef, 'Add');
      return;
    }

    const req: CreateTrainingSessionRequest = {
      agency_id: parseInt(selectedAgency),
      training_type: selectedTrainingType != null ? selectedTrainingType : '',
      name: name.trim(),
      occurred_on: occurredOn,
      num_hours: numHours != null ? parseFloat(numHours) : 0,
      comments: comments != null ? comments.trim() : ''
    };

    const res = await dispatch(createTrainingSessionAsync(req)).unwrap();
    if (isSuccess(res)) {
      window.history.back();

      await dispatch(fetchTrainingSessionsAsync(currentPage));
    } else {
      showError(alertRef, res.message);
      clearButton(addButtonRef, 'Add');
    }
  };

  useEffect(() => {
    if (modalRef.current == null) {
      return;
    }

    modalRef.current.addEventListener('hidden.bs.modal', (event) => {
      const agencyElement = document.getElementById('agency') as HTMLSelectElement;
      if (agencyElement != null) {
        agencyElement.value = '';
      }

      const trainingTypeElement = document.getElementById('training-type') as HTMLSelectElement;
      if (trainingTypeElement != null) {
        trainingTypeElement.value = '';
      }

      const elements = ['name', 'occurred-on', 'num-hours', 'comments'];
      elements.forEach((id) => {
        const element = document.getElementById(id) as HTMLInputElement;
        if (element != null) {
          element.value = '';
        }
      });

      setName(undefined);
      setNumHours(undefined);
      setComments(undefined);
      setSelectedAgency(defaultAgency);

      clearError(alertRef);
      clearButton(addButtonRef, 'Add');
    });
  }, [modalRef, defaultAgency]);

  const onClose = () => {
    window.history.back();
  };

  const DateButton = forwardRef(({value, onClick}: any, ref) => (
    <button type='button' className='form-control mt-0 text-start' onClick={onClick}>{value}</button>
  ));

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Training Session</h5>
              <button id='close-btn' type='button' className='btn-close' onClick={(e) => { onClose(); }} aria-label='Close'></button>
            </div>
            <div className='modal-body'>
              <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
              <div>
                <div>
                  <label className='form-label mb-0' htmlFor='agency'>Department</label>
                  <select className='form-select' id='agency' name='agency' value={selectedAgency} onChange={(e) => { handleAgency(e); }}>
                    <option key={-1} value=''>Select department</option>
                    {agencies.map((agency) =>
                    <option key={agency.id} value={agency.id}>{agency.name}</option>
                    )}
                  </select>
                </div>
                <div>
                  <label className='form-label mb-0' htmlFor='name'>Name</label>
                  <input type='text' className='form-control mt-0' id='name' name='name' onChange={(e) => { handleName(e); }} />
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='training-type'>Training Type</label>
                  <select id='training-type' name='training-type' className='form-select mt-0' onChange={(e) => { handleTrainingType(e); }}>
                    <option value=''>Select training type</option>
                    <option value='In House'>In House</option>
                    <option value='Department'>Department</option>
                    <option value='County/Regional'>County / Regional</option>
                    <option value='State'>State</option>
                    <option value='Outside Vendor'>Outside Vendor</option>
                  </select>
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='occurred-on'>Occurred On</label>
                  <DatePicker selected={new Date(occurredOn)} dateFormat='MM/dd/yyyy HH:mm' className='form-control mt-0' id='occurred-on' customInput={<DateButton />} showPopperArrow={false} showTimeSelect timeFormat="HH:mm" timeIntervals={1} onChange={(date) => { handleOccurredOn(date); }} />
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='num-hours'>Duration (hours)</label>
                  <input type='text' className='form-control mt-0' id='num-hours' name='num-hours' onChange={(e) => { handleNumHours(e); }} />
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='comments'>Comments</label>
                  <textarea className='form-control mt-0' rows={4} id='comments' name='comments' onChange={(e) => { handleComments(e); }} />
                </div>
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button ref={addButtonRef} id='add-training-btn' type='submit' className='btn btn-primary w-25'>Add</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddTrainingSessionModal;
