import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';

import { ErrorResponse, Result } from '../common/types';

export interface CreateLeaderboardRequest {
  name: string;
}

export interface LeaderboardInviteRequest {
  leaderboard_id: number;
  email: string;
}

export interface LeaderboardInviteAcceptRequest {
  invite_code: string;
}

export interface Leaderboard {
  id: number;
  name: string;
  owner_id: number;
}

export interface DashboardLeadboardData {
  leaderboard_id: number;
  owner_id: number;
}

export interface LeaderboardMember {
  name: string;
  count: number;
}

export class LeaderboardService {
  async create(req: CreateLeaderboardRequest): Promise<Result<Leaderboard>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/leaderboards`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        const leaderboard = res.data as Leaderboard;
        return leaderboard;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again.');
      }
    }
  }

  async invite(req: LeaderboardInviteRequest): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/leaderboards/invite`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again.');
      }
    }
  }

  async accept(req: LeaderboardInviteAcceptRequest): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/leaderboards/invites/accept`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again.');
      }
    }
  }
}
