import { FC, RefObject } from 'react';

import { TrainingSession } from './TrainingService';
import TrainingSessionDetailView from './TrainingSessionDetailView';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  session: TrainingSession | undefined;
  onClickDelete: () => void;
  onClickEdit: () => void;
}

const TrainingSessionDetailModal: FC<Props> = ({modalRef, session, onClickDelete, onClickEdit}: Props) => {
  return (
    <div ref={modalRef} className='modal' tabIndex={-3} data-bs-backdrop='static' data-bs-keyboard='false'>
      <div className='modal-dialog modal-fullscreen-lg-down'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{session?.name}</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='close'></button>
          </div>
          <div className='modal-body'>
            <TrainingSessionDetailView session={session} />
          </div>
          <div className='modal-footer modal-bottom'>
            <button id='edit-training-btn' type='button' className='btn btn-primary w-25' onClick={(e) => { onClickEdit(); }}>Edit</button>
            <button id='delete-training-btn' type='button' className='btn btn-primary w-25' onClick={(e) => { onClickDelete(); }}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingSessionDetailModal;
