import { FC } from 'react';
import { Count } from './DashboardService';
import DashboardCountListItem from './DashboardCountListItem';

interface Props {
  counts: Count[];
}

const DashboardCountList: FC<Props> = ({counts}) => {
  return (
    <div className='container-fluid m-0 p-0'>
      <div className='list-group list-group-flush m-0 p-0'>
        {counts.map((count) =>
        <DashboardCountListItem count={count} />
        )}
      </div>
    </div>
  );
};

export default DashboardCountList;
