import axios, { AxiosError } from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { ErrorResponse, Result } from '../common/types';

export interface PromoCode {
  id: number;
  code: string;
  discount: number;
  subscription_plan_id: number;
  user_id: number;
  order_count: number;
}

export interface SubscriptionPlan {
  id: number;
  name: string;
  duration: number;
  cost: number;
  is_active: boolean;
  is_trial: boolean;
  trial_cost: number;
  trial_plan: number;
}

export interface SubscriptionRequest {
  payment_method_id: string;
  subscription_plan: number;
  card_type: string;
  last_four: string;
  exp_month: number;
  exp_year: number;
  promo_code_id?: number;
}

export interface Subscription {
  id: number;
  subscription_plan_id: number;
  user_id: number;
  start_timestamp: string;
  expiration_timestamp: string;
  autopay: boolean;
  promo_code_id?: number;
}

export class SubscriptionService {
  async verifyPromoCode(code: string): Promise<Result<PromoCode>> {
    try {
      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/promocodes/verify`, {
        'code': code
      });

      if (res.status === 200) {
        return res.data as PromoCode;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async getUserPromoCode(): Promise<Result<PromoCode>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');
      
      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/promocodes/user`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return res.data as PromoCode;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async getSubscriptionPlans(): Promise<Result<SubscriptionPlan[]>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');
      
      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/subscriptions/plans`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return res.data as SubscriptionPlan[];
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async getAvailableSubscriptionPlans(): Promise<Result<SubscriptionPlan[]>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');
      
      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/subscriptions/plans/available`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return res.data as SubscriptionPlan[];
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async create(req: SubscriptionRequest): Promise<Result<Subscription>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/subscriptions`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        const sub = res.data as Subscription;
        cookies.set('user_subscription', res.data, { path: '/', expires: moment(sub.expiration_timestamp).toDate(), sameSite: 'strict' });

        return sub;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);
      
      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async getUserSubscription(): Promise<Result<Subscription>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/subscriptions/active`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        const sub = res.data as Subscription;
        cookies.set('user_subscription', res.data, { path: '/', expires: moment(sub.expiration_timestamp).toDate(), sameSite: 'strict' });

        return sub;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async cancelSubscription(): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/subscriptions/cancel`, null,  {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async resumeSubscription(): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/subscriptions/resume`, null, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }
}
