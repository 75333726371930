import { Modal } from 'bootstrap';
import { FC, RefObject, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { DashboardDataType, DashboardTemplate } from './DashboardService';
import { createDashboardCardFromTemplateAsync, fetchDashboardTemplatesAsync, selectDashboardTemplates } from './dashboardSlice';
import DashboardTemplateListItem from './DashboardTemplateListItem';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  customCardModalRef: RefObject<HTMLDivElement>;
  leaderboardModalRef: RefObject<HTMLDivElement>;
  cmeModalRef: RefObject<HTMLDivElement>;
}

const DashboardTemplatesModal: FC<Props> = ({modalRef, leaderboardModalRef, cmeModalRef, customCardModalRef}: Props) => {
  const dispatch = useAppDispatch();
  const templates = useAppSelector(selectDashboardTemplates);

  const onClickTemplate = async (template: DashboardTemplate) => {
    if (template.data_type === DashboardDataType.Leaderboard) {
      if (leaderboardModalRef.current != null) {
        const modal = Modal.getOrCreateInstance(leaderboardModalRef.current);
        modal.show();
      }

      if (modalRef.current != null) {
        const modal = Modal.getOrCreateInstance(modalRef.current);
        modal.hide();
      }
      return;
    }

    if (template.data_type === DashboardDataType.CME) {
      if (cmeModalRef.current != null) {
        const modal = Modal.getOrCreateInstance(cmeModalRef.current);
        modal.show();
      }

      if (modalRef.current != null) {
        const modal = Modal.getOrCreateInstance(modalRef.current);
        modal.hide();
      }
      return;
    }

    await dispatch(createDashboardCardFromTemplateAsync(template.id));
    if (modalRef.current != null) {
      const modal = Modal.getOrCreateInstance(modalRef.current);
      modal.hide();
    }
  };

  const customCardModalHideListener = (e: any) => {
    if (customCardModalRef.current != null) {
      customCardModalRef.current.removeEventListener('hidden.bs.modal', customCardModalHideListener);

      const modal = Modal.getOrCreateInstance(customCardModalRef.current);
      modal.dispose();
    }
  }

  const onClickCustomTemplate = () => {
    if (customCardModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(customCardModalRef.current);
      customCardModalRef.current.addEventListener('hidden.bs.modal', customCardModalHideListener);
      modal.show();
    }

    if (modalRef.current != null) {
      const modal = Modal.getOrCreateInstance(modalRef.current);
      modal.hide();
    }
  };

  useEffect(() => {
    dispatch(fetchDashboardTemplatesAsync());
  }, [dispatch]);

  return (
    <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
      <div className='modal-dialog modal-fullscreen-lg-down'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Add Card To Dashboard</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>
          <div className='modal-body m-0 p-0'>
            <div className='list-group list-group-flush m-0 p-0'>
            {templates.map((template) =>
              <DashboardTemplateListItem key={template.id} template={template} onClickTemplate={onClickTemplate} />
            )}
            <li id={'template-custom'} className='list-group-item list-item click-pointer' onClick={(e) => { onClickCustomTemplate(); }}>
              <div className='d-flex w-100'>
                <span className='template-name'>Custom Incident Field</span>
                <span className='ms-auto badge text-bg-secondary p-2'>Custom</span>
              </div>
              <div>
                <span className='template-style'>Create your own card from your custom fields</span>
              </div>
            </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTemplatesModal;
