import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectIncidentCustomFields } from '../incidents/incidentsSlice';
import { CustomDashboardCardRequest, DashboardOperation, DashboardStyle } from './DashboardService';
import { clearButton, clearError, showError } from '../common/alerts';
import { createCustomDashboardCardAsync } from './dashboardSlice';
import { isSuccess } from '../common/types';
import { Modal } from 'bootstrap';
import ReactGA from 'react-ga4';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
}

const DashboardCustomCardModal: FC<Props> = ({modalRef}) => {
  const dispatch = useAppDispatch();

  const alertRef = useRef<HTMLDivElement>(null);
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const fields = useAppSelector(selectIncidentCustomFields);
  console.log(fields);

  const [name, setName] = useState<string>();
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);

  const [selectedField, setSelectedField] = useState<string>();
  const handleField = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedField(event.target.value);

  const [selectedOperation, setSelectedOperation] = useState<string>();
  const handleOperation = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedOperation(event.target.value);

  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>();
  const handleTimePeriod = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedTimePeriod(event.target.value);

  const [selectedCardStyle, setSelectedCardStyle] = useState<string>();
  const handleCardStyle = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedCardStyle(event.target.value);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (addButtonRef.current != null) {
      addButtonRef.current.disabled = true;
      addButtonRef.current.innerText = 'Adding...';
    }

    if (name == null || name === '') {
      showError(alertRef, 'Card name is required');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (selectedField == null || parseInt(selectedField) < 0) {
      showError(alertRef, 'Custom field is required');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (selectedOperation == null || parseInt(selectedOperation) < 0) {
      showError(alertRef, 'Operation is required');
      clearButton(addButtonRef, 'Add');
      return;
    }

    if (selectedTimePeriod == null || parseInt(selectedTimePeriod) < 0) {
      showError(alertRef, 'Time period is required');
      clearButton(addButtonRef, 'Add');
      return;
    }

    let style = DashboardStyle.Numeric;

    if (parseInt(selectedOperation) === DashboardOperation.DistinctCount) {
      if (selectedCardStyle == null || parseInt(selectedCardStyle) < 0) {
        showError(alertRef, 'Card style is required');
        clearButton(addButtonRef, 'Add');
        return;
      }

      style = parseInt(selectedCardStyle);
    }

    const req: CustomDashboardCardRequest = {
      name: name,
      custom_field: selectedField,
      operation: parseInt(selectedOperation),
      time_period: parseInt(selectedTimePeriod),
      style: style
    };

    const res = await dispatch(createCustomDashboardCardAsync(req)).unwrap();
    if (isSuccess(res)) {
      ReactGA.event({
        category: 'Dashboard',
        action: 'custom-card-create',
        label: 'Create Custom Dashboard Card'
      });

      setSelectedCardStyle(undefined);
      setSelectedField(undefined);
      setSelectedTimePeriod(undefined);
      setName(undefined);

      clearButton(addButtonRef, 'Add');

      if (modalRef.current != null) {
        const modal = Modal.getOrCreateInstance(modalRef.current);
        modal.hide();
      }
    } else {
      showError(alertRef, res.message);
      clearButton(addButtonRef, 'Add');
    }
  };

  useEffect(() => {
    if (modalRef.current == null) {
      return;
    }

    modalRef.current.addEventListener('hidden.bs.modal', (event) => {
      setSelectedCardStyle('');
      setSelectedField('');
      setSelectedTimePeriod('');
      setName('');

      clearButton(addButtonRef, 'Add');
    });
  }, [modalRef]);

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Create Custom Card</h5>
              <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div className='modal-body modal-mobile-scroll'>
              <div>
              <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
                <div>
                  <label className='form-label mb-0' htmlFor='name'>Card Name</label>
                  <input type='text' className='form-control mt-0' id='field-name' name='field-name' value={name} onChange={(e) => { handleName(e); }} />
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='name'>Custom Field</label>
                  <select className='form-select' id='custom-field' name='custom-field' value={selectedField} onChange={(e) => { handleField(e); }}>
                    <option key={-1} value=''>Select custom field</option>
                    {fields.map((field) =>
                    <option key={field.id} value={field.id}>{field.name}</option>
                    )}
                  </select>
                </div>
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='operation'>Operation</label>
                  <select className='form-select' id='field-operation' name='field-operation' value={selectedOperation} onChange={(e) => { handleOperation(e); }}>
                    <option key={-1} value=''>Select operation</option>
                    <option key={0} value='0'>Count (number of incidents with field set)</option>
                    <option key={1} value='1'>Distinct count (count of each distinct value of the field)</option>
                    <option key={2} value='2'>Sum</option>
                  </select>
                </div>
                {selectedOperation != null && parseInt(selectedOperation) === DashboardOperation.DistinctCount &&
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='card-style'>Card Style</label>
                  <select className='form-select' id='card-style' name='card-style' value={selectedCardStyle} onChange={(e) => { handleCardStyle(e); }}>
                    <option key={-1} value=''>Select style</option>
                    <option key={0} value='1'>List</option>
                    <option key={1} value='2'>Bar Graph</option>
                    <option key={2} value='3'>Pie Chart</option>
                  </select>
                </div>
                }
                <div className='mt-2'>
                  <label className='form-label mb-0' htmlFor='time-period'>Time Period</label>
                  <select className='form-select' id='time-period' name='time-period' value={selectedTimePeriod} onChange={(e) => { handleTimePeriod(e); }}>
                    <option key={-1} value=''>Select time period</option>
                    <option key={0} value='0'>Daily</option>
                    <option key={1} value='1'>Monthly</option>
                    <option key={2} value='2'>Quarterly</option>
                    <option key={3} value='3'>Yearly</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button id='add-field-btn' ref={addButtonRef} type='submit' className='btn btn-primary w-25'>Add</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DashboardCustomCardModal;
