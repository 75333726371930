import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';

import { ErrorResponse, Result } from '../common/types';

export interface Agency {
  id: number;
  user_id: number;
  name: string;
  role: string;
}

export interface CreateAgencyRequest {
  name: string;
  role: string;
}

export class AgencyService {
  async fetchAgencies(): Promise<Result<Agency[]>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/agencies`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        const agencies = res.data as Agency[];
        return agencies;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async createAgency(req: CreateAgencyRequest): Promise<Result<Agency>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/agencies`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        const agency = res.data as Agency;
        return agency;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }
}
