import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

import { isSuccess } from "../common/types";
import { CreateTrainingSessionRequest, TrainingService, TrainingSession, TrainingSessionsResponse } from "./TrainingService";

export interface TrainingState {
  sessions: TrainingSession[];
  sessionCount: number;
}

export interface EditTrainingSessionArgs {
  id: number;
  req: CreateTrainingSessionRequest;
}

export const fetchTrainingSessionsAsync = createAsyncThunk(
  'training/fetch',
  async(page: number, { dispatch }) => {
    let offset = 0;
    if (page > 1) {
      offset = 25 * (page - 1);
    }

    const service = new TrainingService();
    const res = await service.fetch(offset);

    if (isSuccess(res)) {
      dispatch(loadTrainingSessions(res));
    }

    return res;
  }
);

export const createTrainingSessionAsync = createAsyncThunk(
  'training/create',
  async(req: CreateTrainingSessionRequest, { dispatch }) => {
    const service = new TrainingService();
    const res = await service.createSession(req);
    
    return res;
  }
);

export const editTrainingSessionAsync = createAsyncThunk(
  'training/edit',
  async (args: EditTrainingSessionArgs, { dispatch }) => {
    const service = new TrainingService();
    const res = await service.editSession(args.id, args.req);

    return res;
  }
)

export const deleteTrainingSessionAsync = createAsyncThunk(
  'training/delete',
  async(sessionId: number, { dispatch }) => {
    const service = new TrainingService();
    const res = await service.deleteSession(sessionId);

    return res;
  }
);

let blankState: TrainingState = {
  sessions: [],
  sessionCount: 0
};

export const trainingSlice = createSlice({
  name: 'training',
  initialState: blankState,
  reducers: {
    loadTrainingSessions: (state, action: PayloadAction<TrainingSessionsResponse>) => {
      state = {
        ...state,
        sessions: action.payload.sessions,
        sessionCount: action.payload.sessionCount
      };

      return state;
    }
  }
});

export const { loadTrainingSessions } = trainingSlice.actions;

export const selectTrainingSessions = (state: RootState) => {
  return state.training.sessions;
}

export const selectTrainingSessionCount = (state: RootState) => {
  return state.training.sessionCount;
}

export default trainingSlice.reducer;
