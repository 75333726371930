import { FC } from 'react';
import { Agency } from '../accounts/AgencyService';

interface Props {
  agency: Agency;
}

const AgencyListItem: FC<Props> = ({agency}: Props) => {
  return (
    <li className='list-group-item list-item'>
      <span className='agency-name'>{agency.name}</span>
      <p className='agency-role'>{agency.role}</p>
    </li>
  );
};

export default AgencyListItem;
