import { Modal } from 'bootstrap';
import { FC, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import ConfirmationModal from '../common/ConfirmationModal';
import { isSuccess } from '../common/types';

import Footer from '../nav/Footer';
import Navbar from '../nav/Navbar';
import Pagination from '../nav/Pagination';
import Tabbar from '../nav/Tabbar';
import AddIncidentModal from './AddIncidentModal';
import AddIncidentPhotoModal from './AddIncidentPhotoModal';
import EditIncidentModal from './EditIncidentModal';
import IncidentDetailModal from './IncidentDetailModal';
import IncidentDetailView from './IncidentDetailView';
import IncidentPhotosCard from './IncidentPhotosCard';
import { Incident } from './IncidentService';
import IncidentsList from './IncidentsList';
import { deleteIncidentAsync, fetchIncidentsAsync, selectIncidentCount, selectIncidents } from './incidentsSlice';

const IncidentsPage: FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

  const dispatch = useAppDispatch();
  const incidents = useAppSelector(selectIncidents);
  const incidentCount = useAppSelector(selectIncidentCount);

  let [searchParams, setSearchParams] = useSearchParams();

  let currentPage = 1;
  if (searchParams.get('page') != null) {
    currentPage = parseInt(searchParams.get('page')!);
  }

  const [selectedIncident, setSelectedIncident] = useState<Incident>();

  const detailModalRef = useRef<HTMLDivElement>(null);
  const deleteModalRef = useRef<HTMLDivElement>(null);
  const editModalRef = useRef<HTMLDivElement>(null);

  const addPhotoModalRef = useRef<HTMLDivElement>(null);
  
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showAddPhoto, setShowAddPhoto] = useState<boolean>(false);

  const addModalRef = useRef<HTMLDivElement>(null);

  const addModalListener = (e: PopStateEvent) => {
    if (addModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(addModalRef.current);
      modal.hide();
    }
  };

  const addModalShowListener = (e: any) => {
    window.history.pushState('#add', '', '/incidents#add');
    window.addEventListener('popstate', addModalListener, false);

    if (addModalRef.current != null) {
      addModalRef.current.removeEventListener('shown.bs.modal', addModalShowListener);
    }
  };

  const addModalHideListener = (e: any) => {
    if (addModalRef.current != null) {
      addModalRef.current.removeEventListener('hidden.bs.modal', addModalHideListener);

      const modal = Modal.getOrCreateInstance(addModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#add') {
      window.history.back();
    }

    setShowAdd(false);
    window.removeEventListener('popstate', addModalListener);
  };

  const onClickAdd = () => {
    setShowAdd(true);
  };

  useEffect(() => {
    if (showAdd && addModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(addModalRef.current);
      addModalRef.current.addEventListener('shown.bs.modal', addModalShowListener);
      addModalRef.current.addEventListener('hidden.bs.modal', addModalHideListener);

      modal.show();
    }
  });

  useEffect(() => {
    if (selectedIncident != null) {
      const incident = incidents.find((i) => i.id === selectedIncident.id);
      setSelectedIncident(incident);
    }
  }, [incidents, selectedIncident]);

  const editModalListener = (e: PopStateEvent) => {
    if (editModalRef.current != null && window.history.state !== '#edit') {
      const modal = Modal.getOrCreateInstance(editModalRef.current);
      modal.hide();
    }
  };

  const editModalShowListener = (e: any) => {
    window.history.pushState('#edit', '', '/incidents#edit');
    window.addEventListener('popstate', editModalListener, false);

    if (editModalRef.current != null) {
      editModalRef.current.removeEventListener('shown.bs.modal', editModalShowListener);
    }
  };

  const editModalHideListener = (e: any) => {
    if (editModalRef.current != null) {
      editModalRef.current.removeEventListener('hidden.bs.modal', editModalHideListener);

      const modal = Modal.getOrCreateInstance(editModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#edit') {
      window.history.back();
    }

    setShowEdit(false);
    window.removeEventListener('popstate', editModalListener);
  }

  const onClickEdit = () => {
    setShowEdit(true);
  };

  useEffect(() => {
    if (showEdit && editModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(editModalRef.current);
      editModalRef.current.addEventListener('shown.bs.modal', editModalShowListener);
      editModalRef.current.addEventListener('hidden.bs.modal', editModalHideListener);

      modal.show();
    }
  });

  const addPhotoModalListener = (e: PopStateEvent) => {
    if (addPhotoModalRef.current != null && window.history.state !== '#photo') {
      const modal = Modal.getOrCreateInstance(addPhotoModalRef.current);
      modal.hide();
    }
  };

  const addPhotoModalShowListener = (e: any) => {
    window.history.pushState('#photo', '', '/incidents#photo');
    window.addEventListener('popstate', addPhotoModalListener, false);

    if (addPhotoModalRef.current != null) {
      addPhotoModalRef.current.removeEventListener('shown.bs.modal', addPhotoModalShowListener);
    }
  };

  const addPhotoModalHideListener = (e: any) => {
    if (addPhotoModalRef.current != null) {
      addPhotoModalRef.current.removeEventListener('hidden.bs.modal', addPhotoModalHideListener);

      const modal = Modal.getOrCreateInstance(addPhotoModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#photo') {
      window.history.back();
    }

    setShowAddPhoto(false);
    window.removeEventListener('popstate', addPhotoModalListener);
  };

  const onClickAddPhoto = () => {
    setShowAddPhoto(true);
  };

  useEffect(() => {
    if (showAddPhoto && addPhotoModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(addPhotoModalRef.current);
      addPhotoModalRef.current.addEventListener('shown.bs.modal', addPhotoModalShowListener);
      addPhotoModalRef.current.addEventListener('hidden.bs.modal', addPhotoModalHideListener);

      modal.show();
    }
  });

  const onPageRequested = (page: number) => {
    let params = searchParams;
    params.set('page', page.toString());

    setSearchParams(params);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(fetchIncidentsAsync(currentPage));
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (selectedIncident == null && incidents.length > 0) {
      setSelectedIncident(incidents[0]);

      if (!isMobile) {
        const newElement = document.getElementById('incident-'+incidents[0].id);
        if (newElement != null) {
          newElement.classList.add('list-item-selected');
        }
      }
    }
  }, [incidents, selectedIncident, isMobile]);

  const onClickDelete = () => {
    if (deleteModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteModalRef.current);
      modal.show();
    }
  };

  const onDeleteIncident = async (result: boolean) => {
    if (deleteModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteModalRef.current);
      modal.hide();
    }

    if (result && selectedIncident != null) {
      const res = await dispatch(deleteIncidentAsync(selectedIncident.id)).unwrap();
      if (isSuccess(res)) {
        await dispatch(fetchIncidentsAsync(currentPage));
        setSelectedIncident(undefined);

        if (incidents.length > 0) {
          setSelectedIncident(incidents[0]);
          if (!isMobile) {
            const newElement = document.getElementById('incident-'+incidents[0].id);
            if (newElement != null) {
              newElement.classList.add('list-item-selected');
            }
          }
        }
      }

      if (detailModalRef.current != null) {
        const modal = Modal.getOrCreateInstance(detailModalRef.current);
        modal.hide();
      }
    }
  };

  const detailModalListener = (e: PopStateEvent) => {
    // state check prevents detail from closing when edit modal closes
    if (detailModalRef.current != null && window.history.state !== '#detail') {
      const modal = Modal.getOrCreateInstance(detailModalRef.current);
      modal.hide();
    }
  };

  const detailModalShowListener = (e: any) => {
    window.history.pushState('#detail', '', document.location+'#detail');
    window.addEventListener('popstate', detailModalListener, false);

    if (detailModalRef.current != null) {
      detailModalRef.current.removeEventListener('shown.bs.modal', detailModalShowListener);
    }
  };

  const detailModalHideListener = (e: any) => {
    if (detailModalRef.current != null) {
      detailModalRef.current.removeEventListener('hidden.bs.modal', detailModalHideListener);

      const modal = Modal.getOrCreateInstance(detailModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#detail') {
      window.history.back();
    }

    setSelectedIncident(undefined);
    window.removeEventListener('popstate', detailModalListener);
  };

  const onClickIncident = (incident: Incident) => {
    if (!isMobile) {
      const selectedElement = document.getElementById('incident-'+selectedIncident?.id);
      if (selectedElement != null) {
        selectedElement.classList.remove('list-item-selected');
      }

      const newElement = document.getElementById('incident-'+incident.id);
      if (newElement != null) {
        newElement.classList.add('list-item-selected');
      }
    }

    setSelectedIncident(incident);

    if (isMobile && detailModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(detailModalRef.current);
      detailModalRef.current.addEventListener('shown.bs.modal', detailModalShowListener);
      detailModalRef.current.addEventListener('hidden.bs.modal', detailModalHideListener);

      modal.show();
    }

    if (!isMobile) {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    document.title = 'ResponderLog - Incidents';
  }, []);

  return (
    <div>
      <Navbar name='incidents' showNav={true} />
      <Tabbar name='incidents' />
      {isMobile &&
      <div className='container-fluid m-0 p-0 standalone-list'>
        <div className='p-3 text-center btn-container-sm'>
          <button type='button' className='btn btn-outline-primary w-100' onClick={(e) => { onClickAdd(); }}>Add New Incident</button>
        </div>
        <IncidentsList incidents={incidents} onClickIncident={onClickIncident} />
        {(incidentCount / 25) > 1 &&
          <Pagination itemCount={incidentCount} currentPage={currentPage} onPageRequested={onPageRequested} />
        }
      </div>
      }
      {!isMobile &&
      <div className='container mt-4 pb-4'>
        <div className='row'>
          <div className='col-5'>
            <div className='card'>
              <div className='card-header d-flex w-100'>
                <span className='align-self-center flex-fill'>Incidents</span>
                <button type='button' className='btn btn-sm btn-outline-primary' onClick={(e) => { onClickAdd(); }}>Add Incident</button>
              </div>
              <div className='card-body m-0 p-0'>
                <IncidentsList incidents={incidents} onClickIncident={onClickIncident} />
              </div>
            </div>
            {(incidentCount / 25) > 1 &&
            <Pagination itemCount={incidentCount} currentPage={currentPage} onPageRequested={onPageRequested} />
            }
          </div>
          {selectedIncident != null &&
          <div className='col-7'>
            <div className='card'>
              <div className='card-header d-flex w-100'>
                <span className='align-self-center flex-fill'>Incident Detail</span>
                <button type='button' className='btn btn-sm btn-outline-primary' onClick={(e) => { onClickEdit(); }}>Edit</button>
                <button type='button' className='btn btn-sm btn-outline-primary ms-3' onClick={(e) => { onClickDelete(); }}>Delete</button>
              </div>
              <div className='card-body'>
                <IncidentDetailView incident={selectedIncident} />
              </div>
            </div>
            <IncidentPhotosCard incidentId={selectedIncident.id} onClickAddPhoto={onClickAddPhoto} />
          </div>
          }
        </div>
      </div>
      }
      {showAdd &&
      <AddIncidentModal modalRef={addModalRef} currentPage={currentPage} />
      }
      <IncidentDetailModal incident={selectedIncident} modalRef={detailModalRef} onClickDelete={onClickDelete} onClickEdit={onClickEdit} onClickAddPhoto={onClickAddPhoto} />
      {(showEdit && selectedIncident != null) &&
      <EditIncidentModal incident={selectedIncident} modalRef={editModalRef} currentPage={currentPage} />
      }
      {showAddPhoto && selectedIncident != null &&
      <AddIncidentPhotoModal addModalRef={addPhotoModalRef} incidentId={selectedIncident.id} />
      }
      <ConfirmationModal modalRef={deleteModalRef} message='Are you sure you want to delete the incident?' onActionTaken={onDeleteIncident} />
      <Footer />
    </div>
  );
};

export default IncidentsPage;
