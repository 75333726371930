import { FC, RefObject, useRef, useState } from 'react';
import { useAppDispatch } from '../app/hooks';
import { clearButton, clearError, showError } from '../common/alerts';
import { createLeadboardAsync } from './leaderboardSlice';
import { CreateLeaderboardRequest } from './LeaderboardService';
import { Modal } from 'bootstrap';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
}

const CreateLeaderboardModal: FC<Props> = ({modalRef}) => {
  const dispatch = useAppDispatch();
  const alertRef = useRef<HTMLDivElement>(null);

  const [name, setName] = useState<string>();
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);

  const createButtonRef = useRef<HTMLButtonElement>(null);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (createButtonRef.current != null) {
      createButtonRef.current.disabled = true;
      createButtonRef.current.innerText = 'Creating...';
    }

    if (name == null || name === '') {
      showError(alertRef, 'Name is required');
      clearButton(createButtonRef, 'Create');
      return;
    }

    const req: CreateLeaderboardRequest = { name: name };
    await dispatch(createLeadboardAsync(req));

    if (modalRef.current != null) {
      const modal = Modal.getOrCreateInstance(modalRef.current);
      modal.hide();
    }
  };

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Create New Leaderboard</h5>
              <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div className='modal-body'>
              <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
              <div>
                <label className='form-label mb-0' htmlFor='name'>Name</label>
                <input type='text' className='form-control mt-0' id='name' onChange={(e) => { handleName(e); }} />
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button ref={createButtonRef} id='create-leaderboard-btn' type='submit' className='btn btn-primary w-25'>Create</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateLeaderboardModal;
