import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';

import { DashboardSortOrder, ErrorResponse, Result } from '../common/types';

export enum DashboardDataType {
  Incidents = 0,
  Training,
  Certifications,
  Custom,
  Leaderboard,
  CME
};

export enum DashboardStyle {
  Numeric = 0,
  List,
  BarGraph,
  PieChart,
  LineGraph,
  Custom
};

export enum DashboardOperation {
  Count = 0,
  DistinctCount,
  Sum,
  Average,
  Custom
};

export enum DashboardTimePeriod {
  Daily = 0,
  Monthly,
  Quarterly,
  Yearly,
  Custom,
};

export interface DashboardTemplate {
  id: number;
  name: string;
  style: DashboardStyle;
  data_type: DashboardDataType;
  field_name: string;
  operation: DashboardOperation;
  time_period: DashboardTimePeriod;
  is_system: boolean;
}

export interface DashboardCard {
  id: number;
  user_id: number;
  template: DashboardTemplate;
  name: string;
  time_period: DashboardTimePeriod;
  start_timestamp: string;
  end_timestamp: string;
  sort_order: number;
  extra?: any;
}

export interface DashboardItem {
  card: DashboardCard;
  value: any;
}

export interface CustomDashboardCardRequest {
  name: string;
  custom_field: string;
  operation: DashboardOperation;
  time_period: DashboardTimePeriod;
  style: DashboardStyle;
}

export interface Count {
  name: string;
  count: number;
}

export class DashboardService {
  async get(): Promise<Result<DashboardItem[]>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/dashboard`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        const items = res.data as DashboardItem[];
        return items;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again.');
      }
    }
  }

  async getSystemTemplates(): Promise<Result<DashboardTemplate[]>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.get(`${process.env['REACT_APP_API_HOST']}/dashboard/templates`, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        const templates = res.data as DashboardTemplate[];
        return templates;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again.');
      }
    }
  }

  async delete(id: number): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/dashboard/cards/${id}/delete`, null, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again.');
      }
    }
  }

  async createFromTemplate(id: number): Promise<Result<DashboardCard>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/dashboard/templates/${id}/add`, null, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        const card = res.data as DashboardCard;
        return card;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async createCustomCard(req: CustomDashboardCardRequest): Promise<Result<DashboardCard>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/dashboard/cards/custom`, req, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 201) {
        const card = res.data as DashboardCard;
        return card;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }

  async updateDashboardSortOrder(items: DashboardItem[]): Promise<Result<boolean>> {
    try {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      let payload: DashboardSortOrder[] = [];
      items.forEach((item) => {
        const card: DashboardSortOrder = {
          id: item.card.id,
          sort_order: item.card.sort_order
        };

        payload.push(card);
      });

      const res = await axios.post(`${process.env['REACT_APP_API_HOST']}/dashboard/cards/order`, payload, {
        headers: {
          Authorization: token
        }
      });

      if (res.status === 200) {
        return true;
      }

      const error = res.data as ErrorResponse;
      return new Error(error.error);
    } catch (error) {
      const e = error as AxiosError;
      console.log(e.message);

      if (e.response != null) {
        return new Error((e.response.data as ErrorResponse).error);
      } else {
        return new Error('A network error was encountered, please try again');
      }
    }
  }
}
