import React, { FC, useEffect, useRef, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactGA from 'react-ga4';

import Footer from '../nav/Footer';
import Navbar from '../nav/Navbar';

import { PromoCode, SubscriptionPlan, SubscriptionRequest } from './SubscriptionService';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { createSubscriptionAsync, selectAvailableSubscriptionPlans, subscriptionPlansAsync, verifyPromoCodeAsync } from './subscriptionSlice';
import { clearError, showError } from '../common/alerts';
import { isSuccess } from '../common/types';

import './subscriptions.scss';
import subscription_header from '../svg/subscription_header.svg';

const NewSubscriptionPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const plans = useAppSelector(selectAvailableSubscriptionPlans);
  useEffect(() => {
    dispatch(subscriptionPlansAsync());
  }, [dispatch]);
  
  const alertRef = useRef<HTMLDivElement>(null);
  const btnPromoCode = useRef<HTMLButtonElement>(null);
  const btnSubmit = useRef<HTMLButtonElement>(null);

  const [promoCodeStr, setPromoCodeStr] = useState<string>('');
  const handlePromoCode = (event: React.ChangeEvent<HTMLInputElement>) => setPromoCodeStr(event.target.value);

  const [discount, setDiscount] = useState<number>(0);
  const [promoCode, setPromoCode] = useState<PromoCode>();

  const [plan, setPlan] = useState<SubscriptionPlan>();
  const handlePlan = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPlan(plans.find((p) => p.id.toString() === event.target.value));
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const clearButtons = (btnSubmit: React.RefObject<HTMLButtonElement>, btnPromoCode: React.RefObject<HTMLButtonElement>) => {
    if (btnSubmit.current != null) {
      btnSubmit.current.disabled = false;
      btnSubmit.current.innerText = 'Purchase Subscription';
    }

    if (btnPromoCode.current != null) {
      btnPromoCode.current.disabled = false;
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    clearError(alertRef);

    if (elements == null || stripe == null) {
      return;
    }

    if (btnPromoCode.current != null) {
      btnPromoCode.current.disabled = true;
    }

    if (btnSubmit.current != null) {
      btnSubmit.current.disabled = true;
      btnSubmit.current.innerText = 'Completing your purchase';
    }

    if (plan == null) {
      showError(alertRef, 'Please select a subscription plan to continue');
      clearButtons(btnSubmit, btnPromoCode);
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)!
    });

    if (error != null) {
      showError(alertRef, error.message ?? 'Error processing your payment method');
      clearButtons(btnSubmit, btnPromoCode);
      return;
    }

    if (paymentMethod == null) {
      showError(alertRef, 'Error with your payment method');
      clearButtons(btnSubmit, btnPromoCode);
      return;
    }

    if (paymentMethod.card == null) {
      showError(alertRef, 'Error with your payment method');
      clearButtons(btnSubmit, btnPromoCode);
      return;
    }

    const req: SubscriptionRequest = {
      payment_method_id: paymentMethod.id,
      card_type: paymentMethod.card.brand,
      last_four: paymentMethod.card.last4,
      exp_month: paymentMethod.card.exp_month,
      exp_year: paymentMethod.card.exp_year,
      subscription_plan: plan.id,
      promo_code_id: promoCode?.id
    };

    const result = await dispatch(createSubscriptionAsync(req)).unwrap();
    if (isSuccess(result)) {
      if (plan.is_trial) {
        ReactGA.event({
          category: 'Onboarding',
          action: 'trial-create',
          label: 'Create Trial'
        });
      } else {
        ReactGA.event({
          category: 'Onboarding',
          action: 'subscription-create',
          label: 'Create Subscription'
        });
      }

      navigate('/', { replace: true });
    } else {
      showError(alertRef, result.message);
      clearButtons(btnSubmit, btnPromoCode);
    }
  };

  const verifyPromoCode = async () => {
    clearError(alertRef);

    if (promoCode != null) {
      setPromoCode(undefined);
      setPromoCodeStr('');
      setDiscount(0);

      const element = document.getElementById('promo-code') as HTMLInputElement;
      if (element != null) {
        element.value = '';
      }

      return;
    }

    if (btnPromoCode.current != null) {
      btnPromoCode.current.disabled = true;
    }

    if (btnSubmit.current != null) {
      btnSubmit.current.disabled = true;
    }

    if (promoCodeStr === '') {
      showError(alertRef, 'Please enter a promo code');
      clearButtons(btnSubmit, btnPromoCode);
      return;
    }

    const result = await dispatch(verifyPromoCodeAsync(promoCodeStr)).unwrap();
    if (isSuccess(result)) {
      if (result.subscription_plan_id != null && result.subscription_plan_id > 0) {
        setPlan(plans.find((p) => p.id === result.subscription_plan_id));
      }

      setPromoCode(result);
      setDiscount(result.discount);
    } else {
      showError(alertRef, 'Promo code provided is invalid');
    }

    clearButtons(btnSubmit, btnPromoCode);
  };
  
  useEffect(() => {
    document.title = 'ResponderLog - Subscribe';

    plans.forEach((plan) => {
      if (plan.is_trial) {
        setPlan(plan);
      }
    });
  }, [plans]);

  return (
    <div>
      <Navbar name='new-subscription' showNav={false} />
      <form onSubmit={handleSubmit}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 p-3 order-lg-2'>
              <div className='card text-start'>
                <img src={subscription_header} className='card-img-top subscription-header' alt='ResponderLog' />
                <div className='card-body'>
                  <h4 className='benefits-header'>Subscription Features</h4>
                  <p className='mt-1 p-0 m-0 benefit'><FontAwesomeIcon className='icon-color' icon={faCaretRight} size='sm' /> Manage your certifications</p>
                  <p className='p-0 m-0'><FontAwesomeIcon className='icon-color' icon={faCaretRight} size='sm' /> Track your training hours</p>
                  <p className='p-0 m-0'><FontAwesomeIcon className='icon-color' icon={faCaretRight} size='sm' /> Keep an incident log</p>
                  <p className='p-0 m-0'><FontAwesomeIcon className='icon-color' icon={faCaretRight} size='sm' /> Build your dashboard</p>
                </div>
              </div>
            </div>
            <div className='col-lg-8 p-3 order-lg-1 text-center'>
              <div className='card text-start'>
                <div className='card-header'>Billing Information</div>
                <div className='card-body'>
                  <div>
                    <label className='form-label mb-0'>Subscription Plan</label>
                    <select className='form-select mt-0' id='subscription-plan' value={plan?.id.toString()} onChange={(e) => { handlePlan(e); }}>
                      <option value='-'>Select plan</option>
                      { plans.filter((plan) => plan.is_active === true).map((plan) =>
                      <option key={plan.id} value={plan.id}>{plan.name}</option>
                      )}
                    </select>
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0'>Credit Card</label>
                    <div className='payment-card mt-0'>
                      <CardElement />
                    </div>
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='promo-code'>Promo Code</label>
                    <div className='d-flex'>
                      <input type='text' className='form-control mt-0 mx-auto' id='promo-code' value={promoCode?.code} onChange={(e) => { handlePromoCode(e); }} />
                      <button type='button' ref={btnPromoCode} id='promo-code-btn' className='btn btn-secondary ms-3 ps-3 pe-3' onClick={(e) => { verifyPromoCode(); }}>{ (promoCode != null ) ? 'Clear' : 'Apply' }</button>
                    </div>
                  </div>
                  { discount > 0 &&
                  <div className='mt-2'>
                    <div id='alert-discount' className='alert alert-primary p-2 ps-3 text-start m-0 discount-text' role='alert'>{discount}% has been applied!</div>
                  </div>
                  }
                </div>
              </div>
              { plan != null &&
              <div className='card mt-4 text-start'>
                <div className='card-header'>Order Summary</div>
                <div className='card-body'>
                  <div>
                    <label className='form-label mb-0'>Subscription</label>
                    {!plan.is_trial &&
                    <p>Renews {moment().add(plan.duration, 'M').format('MMMM D, yyyy')}</p>
                    }
                    {plan.is_trial &&
                    <p>Trial ends {moment().add(plan.duration, 'M').format('MMMM D, yyyy')}</p>
                    }
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0'>Amount Charged Today</label>
                    <p>{formatter.format((plan.cost - (plan.cost * (discount / 100))) / 100)}</p>
                  </div>
                  {plan.is_trial &&
                  <div className='mt-2 small-text'>
                    This is a trial plan, you'll be charged the subscription amount of <b>{formatter.format((plan.trial_cost - (plan.trial_cost * (discount / 100))) / 100)}</b> when the trial expires. You can cancel the trial at any point before the expiration date to avoid being charged the full subscription. price
                  </div>
                  }
                  {!plan.is_trial &&
                  <div className='mt-2 small-text'>
                    Refunds are available within 24 hours of purchase. After 24 hours canceling the subscription will continue until the expiration date.
                  </div>
                  }
                </div>
              </div>
              }
              <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse text-start mt-4' role='alert'></div>
              <div className='mt-4'>
                <button ref={btnSubmit} id='btn-submit' className='btn btn-primary subscribe-btn'>Purchase Subscription</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default NewSubscriptionPage;
