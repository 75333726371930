import { FC, RefObject } from 'react';
import { CMECategory, CMEParticipantCredit } from '../certifications/CertificationService';
import CMEDetailView from './CMEDetailView';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
  cme?: CMEParticipantCredit;
  categories?: CMECategory[];
  onClickDelete: () => void;
}

const CMEDetailModal: FC<Props> = ({modalRef, cme, categories, onClickDelete}) => {
  return (
    <div ref={modalRef} className='modal' tabIndex={-3} data-bs-backdrop='static' data-bs-keyboard='false'>
      <div className='modal-dialog modal-lg modal-fullscreen-lg-down'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{cme?.title}</h5>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='close'></button>
          </div>
          <div className='modal-body'>
            {cme != null && categories != null &&
            <CMEDetailView cme={cme} categories={categories} />
            }
          </div>
          <div className='modal-footer modal-bottom'>
            <button id='delete-cme-btn' type='button' className='btn btn-primary w-25' onClick={(e) => { onClickDelete(); }}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CMEDetailModal;
