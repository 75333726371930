import { FC } from 'react';
import moment from 'moment';

import { Incident } from './IncidentService';

interface Props {
  incident: Incident | undefined;
}

const IncidentDetailView: FC<Props> = ({incident}: Props) => {
  const textFields = incident?.custom_fields?.filter((field) => field.field_type === 'text');
  const dateFields = incident?.custom_fields?.filter((field) => field.field_type === 'datetime');
  const checkFields = incident?.custom_fields?.filter((field) => field.field_type === 'check');

  return (
    <div>
      <div>
        <label className='form-label mb-0'>Dispatched</label>
        <p>{moment(incident?.dispatched_at).format('MMMM D, YYYY HH:mm')}</p>
      </div>
      <div className='mt-2'>
        <label className='form-label mb-0'>Incident</label>
        <p>{incident?.summary}</p>
      </div>
      <div className='mt-2'>
        <label className='form-label mb-0'>Location</label>
        <p>{incident?.location}</p>
      </div>
      {incident?.role !== '' &&
      <div className='mt-2'>
        <label className='form-label mb-0'>Role</label>
        <p>{incident?.role}</p>
      </div>
      }
      {incident != null && parseFloat(incident.mileage) > 0 &&
      <div className='mt-2'>
        <label className='form-label mb-0'>Mileage</label>
        <p>{incident?.mileage}</p>
      </div>
      }
      {textFields?.map((field) =>
      <div key={field.id} className='mt-2'>
        <label className='form-label mb-0'>{field.name}</label>
        <p>{field.value}</p>
      </div>
      )}
      {dateFields?.map((field) =>
      <div key={field.id} className='mt-2'>
        <label className='form-label mb-0'>{field.name}</label>
        <p>{moment(field.value).format('MMMM D, YYYY HH:mm')}</p>
      </div>
      )}
      {checkFields != null && checkFields?.length > 0 &&
      <div className='mt-2'>
        <div className='row'>
        {checkFields?.map((field) =>
        <div key={field.id} className='col-lg-3 margin-top-col-sm'>
          <label className='form-label mb-0'>{field.name}</label>
          <ul className='list-group list-group-horizontal'>
            <li className={field.value === 'true' ? 'list-group-item active' : 'list-group-item'}>Yes</li>
            <li className={field.value === 'false' ? 'list-group-item active' : 'list-group-item'}>No</li>
          </ul>
        </div>
        )}
        </div>
      </div>
      }
      {incident?.comments !== '' &&
       <div className='mt-2'>
        <label className='form-label mb-0'>Comments</label>
        <p>{incident?.comments}</p>
      </div>
      }
    </div>
  );
}

export default IncidentDetailView;
