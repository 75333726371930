import { FC, RefObject, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { addCMEDashboardCardAsync, selectCMEProgressReports } from '../certifications/certificationSlice';
import { clearButton, clearError, showError } from '../common/alerts';
import { isSuccess } from '../common/types';
import { Modal } from 'bootstrap';

interface Props {
  modalRef: RefObject<HTMLDivElement>;
}

const CreateCMEDashboardCardModal: FC<Props> = ({modalRef}) => {
  const dispatch = useAppDispatch();
  const alertRef = useRef<HTMLDivElement>(null);
  const createButtonRef = useRef<HTMLButtonElement>(null);

  const reports = useAppSelector(selectCMEProgressReports);

  let defaultProgram = '';
  if (reports.length === 1) {
    defaultProgram = reports[0].participant_id.toString();
  }

  const [selectedProgram, setSelectedProgram] = useState<string>(defaultProgram);
  const handleProgram = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedProgram(event.target.value);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (createButtonRef.current != null) {
      createButtonRef.current.disabled = true;
      createButtonRef.current.innerText = 'Creating...';
    }

    if (selectedProgram == null || selectedProgram === '') {
      showError(alertRef, 'Please select a CME program');
      clearButton(createButtonRef, 'Create');
      return;
    }

    const res = await dispatch(addCMEDashboardCardAsync(parseInt(selectedProgram))).unwrap();
    if (isSuccess(res)) {
      if (modalRef.current != null) {
        const modal = Modal.getOrCreateInstance(modalRef.current);
        modal.hide();
      }
    }
  };

  return (
    <form onSubmit={formHandler}>
      <div ref={modalRef} className='modal' tabIndex={-1} data-bs-backdrop='static' data-bs-keyboard='false'>
        <div className='modal-dialog modal-fullscreen-lg-down'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add CME Progress</h5>
              <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div className='modal-body'>
              <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
              <div>
                <label className='form-label mb-0' htmlFor='cme-program'>CME Program</label>
                <select className='form-select' id='cme-program' name='cme-program' value={selectedProgram} onChange={(e) => { handleProgram(e); }}>
                  <option key={-1} value=''>Select CME Program</option>
                  {reports.map((report) =>
                  <option key={report.participant_id} value={report.participant_id}>{report.program.certification_level.title}</option>
                  )}
                </select>
              </div>
            </div>
            <div className='modal-footer modal-bottom'>
              <button ref={createButtonRef} id='create-leaderboard-btn' type='submit' className='btn btn-primary w-25'>Create</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateCMEDashboardCardModal;
