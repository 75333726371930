import { configureStore } from '@reduxjs/toolkit';
import accountSlice from '../accounts/accountSlice';
import agencySlice from '../accounts/agencySlice';
import certificationSlice from '../certifications/certificationSlice';
import dashboardSlice from '../dashboard/dashboardSlice';
import incidentSlice from '../incidents/incidentsSlice';
import subscriptionSlice from '../subscriptions/subscriptionSlice';
import trainingSlice from '../training/trainingSlice';

export const store = configureStore({
  reducer: {
    account: accountSlice,
    agency: agencySlice,
    certification: certificationSlice,
    subscription: subscriptionSlice,
    incident: incidentSlice,
    training: trainingSlice,
    dashboard: dashboardSlice
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
