import { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';

import Footer from '../nav/Footer';

import './landing.scss';

import logo_inverse from '../svg/logo_inverse.svg';
import certificate from '../svg/icon_certificate.svg';
import incident_log from '../svg/icon_incident_log.svg';
import icon_training from '../svg/icon_training.svg';
import icon_dashboard from '../svg/icon_dashboard.svg';
import screenshots from '../png/screenshots.png';
import screenshot_group from '../png/screenshot_group.png';
import icon_pdf from '../svg/icon_pdf.svg';
import icon_cme_progress from '../svg/icon_cme_progress.svg';
import icon_audit from '../svg/icon_audit.svg';
import screenshot_cme from '../png/screenshot_group_cme.png';
import screenshot_leaderboard from '../png/screenshot_leaderboard.png';

import { useNavigate } from 'react-router';
import { isSuccess } from '../common/types';
import { useAppDispatch } from '../app/hooks';
import { setupDemoAccountAsync } from '../accounts/accountSlice';
import { Toast } from 'bootstrap';
import moment from 'moment';

const LandingPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const openSignUp = () => {
    navigate('/accounts/create');
  };

  const openLogin = () => {
    navigate('/login');
  };

  const activateDemo = async () => {
    ReactGA.event({
      category: 'Landing',
      action: 'demo',
      label: 'Demo Click'
    });

    const res = await dispatch(setupDemoAccountAsync()).unwrap();

    if (isSuccess(res)) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (moment().isBefore(moment('2023-04-07 23:59:59'))) {
      const toastElement = document.getElementById('promoCodeToast');
      if (toastElement != null) {
        const toast = new Toast(toastElement);
        toast.show();
      }
    }
  }, []);

  useEffect(() => {
    document.title = 'ResponderLog';
  }, []);

  return (
    <div>
      <div className='header-section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 align-self-center mt-4'>
              <div className='d-flex justify-content-center'>
                <img src={logo_inverse} className='header-logo' alt='ResponderLog' />
                <span className='header-brand align-self-center ms-2'>ResponderLog</span>
              </div>
              <div className='header-tagline mt-4 text-center'>
                Keep your own log of the incidents you respond to, training you attend, and certificates you earn. Sign up today, get a one month free trial.
              </div>
              <div className='mt-4 d-flex justify-content-center'>
                <button type='button' className='btn btn-secondary w-25' onClick={(e) => { openSignUp(); }}>Sign Up</button>
                <button type='button' className='btn btn-secondary w-25 ms-3' onClick={(e) => { openLogin(); }}>Log In</button>
                <button type='button' className='btn btn-secondary w-25 ms-3' onClick={activateDemo}>Demo</button>
              </div>
            </div>
            <div className='col-lg-4 mt-4 d-flex justify-content-center'>
              <img src={screenshots} className='screenshots' alt='ResponderLog Screenshots' />
            </div>
          </div>
        </div>
      </div>
      <div className='container mt-4 pb-4'>
        <div className='row justify-content-center'>
          <div className='col-lg-6 p-4'>
            <p className='hero-section-header'>
              <img src={certificate} alt='Certification Management' className='hero-section-icon' /> 
              <span className='align-middle ms-3'>Certification Management</span>
            </p>
            <p className='mt-4'>
              Keep all your certifications in one place, never lose a certificate again. You can track the expiration of certifications and get a reminder when it is almost time to renew. You can view your uploaded certificates in the browser as well as download them at any time.
            </p>
          </div>
          <div className='col-lg-6 p-4'>
            <p className='hero-section-header'>
              <img src={incident_log} alt='Incident Log' className='hero-section-icon' />
              <span className='align-middle ms-3'>Incident Log</span>
            </p>
            <p className='mt-4'>
              Keep track of the incidents you respond to. Custom fields let you track any piece of data you'd like. We provide some standard fields, but we know every department and first responder is different. You can track mileage for your end of year tax paperwork.
            </p>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-lg-6 p-4'>
            <p className='hero-section-header'>
              <img src={icon_training} alt='Training Log' className='hero-section-icon' />
              <span className='align-middle ms-3'>Training Log</span>
            </p>
            <p className='mt-3'>
              Keep track of all the training you participate in throughout the year. Add everything from in-house drills to state courses. You can see your total training hours for the year on the dashboard.
            </p>
          </div>
          <div className='col-lg-6 p-4'>
            <p className='hero-section-header'>
              <img src={icon_dashboard} alt='Personal Dashboard' className='hero-section-icon' />
              <span className='align-middle ms-3'>Personal Dashboard</span>
            </p>
            <p className='mt-3'>
              The dashboard gives you a unique view into all your data. You can see counts for specific time periods as well as charts and graphs that show you the types of incidents or your role at incidents. The dashboard is fully customizable, you can add or remove cards and change the order they appear in.
            </p>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-9 mb-4 text-center'>
            <img src={screenshot_group} className='screenshot-group' alt='ResponderLog Dashboard' />
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-9 mb-4 text-center'>
            <h3 className='section-header-large'>Manage Your CMEs</h3>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-lg-6 p-4'>
            <p className='hero-section-header'>
              <img src={icon_cme_progress} alt='CME Progress' className='hero-section-icon' /> 
              <span className='align-middle ms-3'>CME Progress</span>
            </p>
            <p className='mt-4'>
              Keeping track of all the hours required to recertify can be challenging, especially over the course of 2 or 3 years. ResponderLog provides an overview of each category so you know exactly what hours you have and what you still need to complete.
            </p>
          </div>
          <div className='col-lg-6 p-4'>
            <p className='hero-section-header'>
              <img src={icon_audit} alt='Be Prepared for Audits' className='hero-section-icon' />
              <span className='align-middle ms-3'>Be Prepared for Audits</span>
            </p>
            <p className='mt-4'>
              If your state participates in the National Registry CME program you can be audited at any point requiring you to pull your records of training. National Registry recommends keeping records for 5 years. With ResponderLog you can keep all your recertification records in one place. There are no limits to the number of certifications you can save on ResponderLog.
            </p>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-lg-6 p-4'>
            <p className='hero-section-header'>
              <img src={icon_pdf} alt='Export to PDF' className='hero-section-icon' />
              <span className='align-middle ms-3'>Export to PDF</span>
            </p>
            <p className='mt-3'>
              When you are ready to submit for recertification you can download a single PDF with a cover sheet containing all your CMEs and a copy of each CME completion certificate. Print, email or submit the PDF online as part of your application process.
            </p>
          </div>
          <div className='col-lg-6 p-4'>
            <p className='hero-section-header'>
              <img src={icon_dashboard} alt='Personal Dashboard' className='hero-section-icon' />
              <span className='align-middle ms-3'>Monitor Progress on the Dashboard</span>
            </p>
            <p className='mt-3'>
              When you add a CME based certification on ResponderLog, a card is added to the Dashboard giving you a quick view into the number of hours required, hours completed and hours remaining. It even has an add button and detail button to quickly add a new CME or see your detailed progress.
            </p>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-9 mb-4 text-center'>
            <img src={screenshot_cme} className='screenshot-group' alt='ResponderLog Dashboard' />
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-9 mb-4 text-center'>
            <h3 className='section-header-large'>Leaderboards</h3>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-9 mb-4 text-center'>
            <img src={screenshot_leaderboard} className='screenshots' alt='ResponderLog Dashboard' />
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-9 mb-4 text-center'>
            <h3 className='section-header-large'>Keep up on some friendly competition</h3>
            <p className='mt-3 text-center'>Invite other fellow first responders to a leaderboard and see total incident counts for the group. ResponderLog supports having multiple Leaderboards on your Dashboard.</p>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-9 mb-4 text-center'>
            <h3 className='section-header-large'>Pricing</h3>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
        <div className='col-lg-6 mb-4'>
            <div className='price-card rounded text-center pt-4 pb-4'>
              <p className='mb-0 pb-0'>
                <span className='landing-price'>$2</span><span className='landing-price-label'> /monthly</span>
              </p>
              <p className='mt-0 pt-0 landing-price-tagline'>&nbsp;</p>
            </div>
          </div>
          <div className='col-lg-6 mb-4'>
            <div className='price-card rounded text-center pt-4 pb-4'>
              <p className='mb-0 pb-0'>
                <span className='landing-price'>$15</span><span className='landing-price-label'> /yearly</span>
              </p>
              <p className='mt-0 pt-0 landing-price-tagline'>Save $9 a year by subscribing for a year.</p>
            </div>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-9 mb-4 text-center'>
            <h3 className='section-header-large'>Get Started Today</h3>
            <p className='mt-3 text-start'>If you would like to see what ResponderLog is all about, try out the demo. You can browse the software using our demo account to see it in action. You can cancel your subscription at any time, no hoops to jump through, just a simple button on the settings page.</p>
          </div>
        </div>
        <div className='row justify-content-center mt-4'>
          <div className='col-md-6 mb-4 text-center'>
            <button type='button' className='btn btn-lg btn-secondary w-75 pt-4 pb-4' onClick={(e) => { openSignUp(); }}>Create an account</button>
          </div>
          <div className='col-md-6 mb-4 text-center'>
            <button type='button' className='btn btn-lg btn-secondary w-75 pt-4 pb-4' onClick={(e) => { activateDemo(); }}>Try out the demo</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
