import { RefObject } from "react";

export const showError = (ref: RefObject<HTMLDivElement>, message: string) => {
  const node = ref.current;
  if (node != null) {
    node.innerHTML = message;
    node.style.setProperty('display', 'block');
  }
};

export const clearError = (ref: RefObject<HTMLDivElement>) => {
  const node = ref.current;
  if (node != null) {
    node.style.setProperty('display', 'none');
  }
};

export const clearButton = (ref: RefObject<HTMLButtonElement>, label: string) => {
  const button = ref.current;
  if (button != null) {
    button.disabled = false;
    button.innerText = label;
  }
}
