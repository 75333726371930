import { FC } from 'react';
import { useAppSelector } from '../app/hooks';

import { selectUser } from '../accounts/accountSlice';

const AccountInfoCard: FC = () => {
  const user = useAppSelector(selectUser);

  return (
    <div className='card top-row-card'>
      <div className='card-header'>
        <span>Account Information</span>
      </div>
      <div className='card-body pt-2'>
        {user != null &&
        <p>
          <p className='mt-0 mb-1'>
            <span className='account-info-name'>{user.first_name} {user.last_name}</span>
            <br />
            <span className='account-info-email'>{user.email}</span>
          </p>
          <p className='mt-2'>
            <span className='address-title'>Address</span>
            <br />
            <span>{user.address} {user.address_two}</span>
            <br />
            <span>{user.city}, {user.state} {user.postal_code}</span>
          </p>
        </p>
        }
      </div>
    </div>
  );
};

export default AccountInfoCard;
