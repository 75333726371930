import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { isSuccess } from "../common/types";
import { CustomDashboardCardRequest, DashboardItem, DashboardService, DashboardTemplate } from "./DashboardService";

export interface DashboardState {
  items: DashboardItem[];
  templates: DashboardTemplate[];
}

export const fetchDashboardAsync = createAsyncThunk(
  'dashboard/fetch',
  async (_, { dispatch }) => {
    const service = new DashboardService();
    const res = await service.get();

    if (isSuccess(res)) {
      dispatch(loadDashboard(res));
    }

    return res;
  }
);

export const fetchDashboardTemplatesAsync = createAsyncThunk(
  'dashboard/templates',
  async (_, { dispatch }) => {
    const service = new DashboardService();
    const res = await service.getSystemTemplates();

    if (isSuccess(res)) {
      dispatch(loadDashboardTemplates(res));
    }

    return res;
  }
)

export const deleteDashboardCardAsync = createAsyncThunk(
  'dashboard/delete',
  async (id: number, { dispatch }) => {
    const service = new DashboardService();
    const res = await service.delete(id);

    if (isSuccess(res)) {
      await dispatch(fetchDashboardAsync());
    }

    return res;
  }
);

export const createDashboardCardFromTemplateAsync = createAsyncThunk(
  'dashboard/cards/create',
  async (id: number,  { dispatch }) => {
    const service = new DashboardService();
    const res = await service.createFromTemplate(id);

    if (isSuccess(res)) {
      await dispatch(fetchDashboardAsync());
    }

    return res;
  }
);

export const createCustomDashboardCardAsync = createAsyncThunk(
  'dashboard/cards/custom/create',
  async (req: CustomDashboardCardRequest, { dispatch }) => {
    const service = new DashboardService();
    const res = await service.createCustomCard(req);

    if (isSuccess(res)) {
      await dispatch(fetchDashboardAsync());
    }

    return res;
  }
);

export const updateDashboardCardsSortAsync = createAsyncThunk(
  'dashboard/cards/sort',
  async (items: DashboardItem[], { dispatch }) => {
    dispatch(loadDashboard(items));

    const service = new DashboardService();
    const res = await service.updateDashboardSortOrder(items);

    if (isSuccess(res)) {
      await dispatch(fetchDashboardAsync());
    }

    return res;
  }
);

let blankState: DashboardState = {
  items: [],
  templates: []
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: blankState,
  reducers: {
    loadDashboard: (state, action: PayloadAction<DashboardItem[]>) => {
      state = {
        ...state,
        items: action.payload
      };

      return state;
    },
    loadDashboardTemplates: (state, action: PayloadAction<DashboardTemplate[]>) => {
      state = {
        ...state,
        templates: action.payload
      };

      return state;
    }
  }
});

export const { loadDashboard, loadDashboardTemplates } = dashboardSlice.actions;

export const selectDashboard = (state: RootState) => {
  return state.dashboard.items;
};

export const selectDashboardTemplates = (state: RootState) => {
  return state.dashboard.templates;
};

export default dashboardSlice.reducer;
