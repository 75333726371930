import { FC, useEffect, useRef, useState } from 'react';

import { showError, clearError } from '../common/alerts';

import Footer from '../nav/Footer';
import Navbar from '../nav/Navbar';

import { AuthenticationRequest } from './AccountService';
import { authenticateAsync } from './accountSlice';
import { useAppDispatch } from '../app/hooks';
import { isSuccess } from '../common/types';
import { useNavigate, useSearchParams } from 'react-router-dom';

const LoginPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect');

  const alertRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);

  const [email, setEmail] = useState<string>();
  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

  const [password, setPassword] = useState<string>();
  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    clearError(alertRef);

    if (email === undefined || email === '') {
      showError(alertRef, 'Email is required');
      return;
    }

    if (password === undefined || password === '') {
      showError(alertRef, 'Password is required');
      return;
    }

    const req: AuthenticationRequest = {
      email: email,
      password: password
    };

    const result = await dispatch(authenticateAsync(req)).unwrap();
    if (isSuccess(result)) {
      if (redirectUrl != null) {
        navigate(redirectUrl, { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    } else {
      showError(alertRef, result.message);
    }
  };

  useEffect(() => {
    document.title = 'ResponderLog - Login';

    if (redirectUrl != null) {
      showError(infoRef, 'Please sign in before continuing');
    }
  }, [redirectUrl]);

  return (
    <div>
      <Navbar name='login' showNav={false} />
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <form onSubmit={formHandler}>
              <div className='card mt-4 text-start'>
                <div className='card-header'>Account Login</div>
                <div className='card-body'>
                  <div ref={alertRef} className='alert alert-danger pt-2 pb-2 collapse'></div>
                  <div ref={infoRef} className='alert alert-info pt-2 pb-2 collapse'></div>
                  <div>
                    <label className='form-label mb-0' htmlFor='email'>Email</label>
                    <input type='email' className='form-control mt-0' id='email' onChange={(e) => { handleEmail(e); }} />
                  </div>
                  <div className='mt-2'>
                    <label className='form-label mb-0' htmlFor='password'>Password</label>
                    <input type='password' className='form-control mt-0' id='password' onChange={(e) => { handlePassword(e); }} />
                  </div>
                  <div className='mt-4 justify-content-center text-center'>
                    <button type='submit' id='btn-submit' className='btn btn-primary w-50'>Log In</button>
                  </div>
                  <div className='mt-2 justifycontent-center text-center'>
                    <a className='text-small link' href='/password/reset'>Forgot your password? Reset it here.</a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
};

export default LoginPage;
