import { Modal } from 'bootstrap';
import { FC, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import ConfirmationModal from '../common/ConfirmationModal';
import { isSuccess } from '../common/types';

import Footer from '../nav/Footer';
import Navbar from '../nav/Navbar';
import Pagination from '../nav/Pagination';
import Tabbar from '../nav/Tabbar';
import AddCertificationModal from './AddCertificationModal';
import CertificationDetailModal from './CertificationDetailModal';
import CertificationDetailView from './CertificationDetailView';
import { Certification } from './CertificationService';

import { deleteCertificationAsync, fetchCertificationsAsync, fetchCMEProgramsAsync, selectCertificationCount, selectCertifications } from './certificationSlice';
import CertificationsList from './CertificationsList';

const CertificationsPage: FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  
  const modalRef = useRef<HTMLDivElement>(null);
  const detailModalRef = useRef<HTMLDivElement>(null);
  const deleteModalRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const certifications = useAppSelector(selectCertifications);
  const certificationCount = useAppSelector(selectCertificationCount);

  let [searchParams, setSearchParams] = useSearchParams();

  let currentPage = 1;
  if (searchParams.get('page') != null) {
    currentPage = parseInt(searchParams.get('page')!);
  }

  const [selectedCertification, setSelectedCertification] = useState<Certification>();

  useEffect(() => {
    dispatch(fetchCertificationsAsync(currentPage));
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (selectedCertification == null && certifications.length > 0) {
      setSelectedCertification(certifications[0]);

      if (!isMobile) {
        const newElement = document.getElementById('certification-'+certifications[0].id);
        if (newElement != null) {
          newElement.classList.add('list-item-selected');
        }
      }
    }
  }, [certifications, selectedCertification, isMobile]);

  const addModalListener = (e: PopStateEvent) => {
    if (modalRef.current != null) {
      const modal = Modal.getOrCreateInstance(modalRef.current);
      modal.hide();
    }
  };

  const addModalShowListener = (e: any) => {
    window.history.pushState('#add', '', document.location+'#add');
    window.addEventListener('popstate', addModalListener, false);

    if (modalRef.current != null) {
      modalRef.current.removeEventListener('shown.bs.modal', addModalShowListener);
    }
  };

  const addModalHideListener = (e: any) => {
    if (modalRef.current != null) {
      modalRef.current.removeEventListener('hidden.bs.modal', addModalHideListener);

      const modal = Modal.getOrCreateInstance(modalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#add') {
      window.history.back();
    }

    window.removeEventListener('popstate', addModalListener);
  }

  const onClickAdd = () => {
    if (modalRef.current != null) {
      const modal = Modal.getOrCreateInstance(modalRef.current);
      modalRef.current.addEventListener('shown.bs.modal', addModalShowListener);
      modalRef.current.addEventListener('hidden.bs.modal', addModalHideListener);

      modal.show();
    }
  };

  const onClickDelete = () => {
    if (deleteModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteModalRef.current);
      modal.show();
    }
  };

  const onDeleteCertification = async (result: boolean) => {
    if (deleteModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(deleteModalRef.current);
      modal.hide();
    }

    if (result && selectedCertification != null) {
      const res = await dispatch(deleteCertificationAsync(selectedCertification.id)).unwrap();
      if (isSuccess(res)) {
        await dispatch(fetchCertificationsAsync(currentPage));
        setSelectedCertification(undefined);

        if (certifications.length > 1) {
          setSelectedCertification(certifications[0]);
          if (!isMobile) {
            const newElement = document.getElementById('certification-'+certifications[0].id);
            if (newElement != null) {
              newElement.classList.add('list-item-selected');
            }
          }
        }

        if (detailModalRef.current != null) {
          const modal = Modal.getOrCreateInstance(detailModalRef.current);
          modal.hide();
        }
      }
    }
  };

  const detailModalListener = (e: PopStateEvent) => {
    if (detailModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(detailModalRef.current);
      modal.hide();
    }
  };

  const detailModalShowListener = (e: any) => {
    window.history.pushState('#detail', '', document.location+'#detail');
    window.addEventListener('popstate', detailModalListener, false);

    if (detailModalRef.current != null) {
      detailModalRef.current.removeEventListener('shown.bs.modal', detailModalShowListener);
    }
  };

  const detailModalHideListener = (e: any) => {
    if (detailModalRef.current != null) {
      detailModalRef.current.removeEventListener('hidden.bs.modal', detailModalHideListener);

      const modal = Modal.getOrCreateInstance(detailModalRef.current);
      modal.dispose();
    }

    if (window.history.state === '#detail') {
      window.history.back();
    }

    setSelectedCertification(undefined);
    window.removeEventListener('popstate', detailModalListener);
  };

  const onClickCertification = (certification: Certification) => {
    if (!isMobile) {
      const selectedElement = document.getElementById('certification-'+selectedCertification?.id);
      if (selectedElement != null) {
        selectedElement.classList.remove('list-item-selected');
      }

      const newElement = document.getElementById('certification-'+certification.id);
      if (newElement != null) {
        newElement.classList.add('list-item-selected');
      }
    }

    setSelectedCertification(certification);

    if (isMobile && detailModalRef.current != null) {
      const modal = Modal.getOrCreateInstance(detailModalRef.current);
      detailModalRef.current.addEventListener('shown.bs.modal', detailModalShowListener);
      detailModalRef.current.addEventListener('hidden.bs.modal', detailModalHideListener);

      modal.show();
    }

    if (!isMobile) {
      window.scrollTo(0, 0);
    }
  };

  const onPageRequested = (page: number) => {
    let params = searchParams;
    params.set('page', page.toString());

    setSearchParams(params);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    document.title = 'ResponderLog - Certifications';
    dispatch(fetchCMEProgramsAsync());
  }, [dispatch]);

  return (
    <div>
      <Navbar name='certifications' showNav={true} />
      <Tabbar name='certifications' />
      {isMobile &&
      <div className='container-fluid m-0 p-0 standalone-list'>
        <div className='p-3 text-center btn-container-sm'>
          <button type='button' className='btn btn-outline-primary w-100' onClick={(e) => { onClickAdd(); }}>Add New Certification</button>
        </div>
        <CertificationsList certifications={certifications} onClickCertification={onClickCertification} />
        {(certificationCount / 25) > 1 &&
          <Pagination itemCount={certificationCount} currentPage={currentPage} onPageRequested={onPageRequested} />
        }
      </div>
      }
      {!isMobile &&
      <div className='container mt-4 pb-4'>
        <div className='row'>
          <div className='col-5'>
            <div className='card'>
              <div className='card-header d-flex w-100'>
                <span className='align-self-center flex-fill'>Certifications</span>
                <button type='button' className='btn btn-sm btn-outline-primary' onClick={(e) => { onClickAdd(); }}>Add Certification</button>
              </div>
              <div className='card-body m-0 p-0'>
                <CertificationsList certifications={certifications} onClickCertification={onClickCertification} />
              </div>
            </div>
          </div>
          {selectedCertification != null &&
          <div className='col-7'>
            <div className='card'>
              <div className='card-header d-flex w-100'>
                <span className='align-self-center flex-fill'>{selectedCertification?.name}</span>
                <a href={selectedCertification?.url} className='btn btn-sm btn-outline-primary' download>Download</a>
                <button type='button' className='btn btn-sm btn-outline-primary ms-3' onClick={(e) => { onClickDelete(); }}>Delete</button>
              </div>
              <div className='card-body'>
                <CertificationDetailView certification={selectedCertification} />
              </div>
            </div>
            {(certificationCount / 25) > 1 &&
            <Pagination itemCount={certificationCount} currentPage={currentPage} onPageRequested={onPageRequested} />
            }
          </div>
          }
        </div>
      </div>
      }
      <AddCertificationModal modalRef={modalRef} currentPage={currentPage} />
      <CertificationDetailModal certification={selectedCertification} showFooter={true} modalRef={detailModalRef} onClickDelete={onClickDelete} />
      <ConfirmationModal modalRef={deleteModalRef} message='Are you sure you want to delete the cerification?' onActionTaken={onDeleteCertification} />
      <Footer />
    </div>
  );
};

export default CertificationsPage;
